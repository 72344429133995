// import package
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector } from "react-redux";

// import lib
import { dateHours, dateHoursclose, dayOnly } from "../../lib/dateFilter";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { getGCHistory } from "../../actions/gridbot";
import isEmpty from "../../lib/isEmpty";
import SocketContext from "../Context/SocketContext";
const Performance = (props) => {
  // props
  const { actives, markPrice, markResult, FeeandDecimal } = props;

  const socketContext = useContext(SocketContext);
  //   state
  const [details, setDetails] = useState({
    buyInOrder: 0,
    sellInOrder: 0,
    totalInOrder: 0,
  });
  const [pnlDet, setPnl] = useState({
    totalpnl: 0,
    totalPnlPct: 0,
    totalWFpnl: 0,
    totalWFPnlPct: 0,
  });
  const [bAProfit, setBAProfit] = useState({
    totBProfit: 0,
    totBPnl: 0,
    totAvgProfit: 0,
    totAvgPnl: 0,
  });
  const [uRealized, setUR] = useState({});
  const [bProfit, setBProfit] = useState({});

  let { totalpnl, totalPnlPct, totalWFpnl, totalWFPnlPct } = pnlDet;
  let { totBProfit, totBPnl, totAvgProfit, totAvgPnl } = bAProfit;
  // redux
  const botData = useSelector((state) => state?.curGridBot);

  const calcPnl_Old = () => {
    let totalpnl = 0,
      totalPnlPct = 0;
    try {
      if (botData?.status == "open") {
        if (botData.actives && botData.actives.length > 0) {
          for (let el of botData.actives) {
            if (el.side == "sell") {
              let prevOrd = el.prevOrd;
              let pnl = 0;
              if (!isEmpty(uRealized)) {
                pnl =
                  uRealized.price * el.quantity -
                  prevOrd.price * prevOrd.quantity;
              }
              if (!isEmpty(botData.markPrice) && botData.markPrice > 0) {
                pnl =
                  botData.markPrice * el.quantity -
                  prevOrd.price * prevOrd.quantity;
              }
              totalpnl = totalpnl + pnl;
              totalPnlPct =
                totalPnlPct + (pnl / (prevOrd.price * prevOrd.quantity)) * 100;
            }
          }
        }
      } else if (botData?.status == "closed") {
        if (botData.filled && botData.filled.length > 0) {
          let sell = botData.filled.filter((el) => el.side == "sell");
          let buy = botData.filled.filter((el) => el.side == "buy");
          for (let i = 0; i < buy.length; i++) {
            let q =
              buy[i].quantity > sell[i].quantity
                ? sell[i].quantity
                : buy[i].quantity;
            totalpnl = totalpnl + q * (sell[i].price - buy[i].price);
            totalPnlPct = totalPnlPct + totalpnl / 100;
          }
        }
      }
    } catch (err) {}
    return (
      <Fragment>
        <h6>{toFixed(totalpnl, FeeandDecimal?.priceDecimal)}</h6>
        <span>{botData?.secondCurrency}</span>
        <p className={totalPnlPct < 0 ? "redText mb-0" : "greenTxt mb-0"}>
          {toFixed(totalPnlPct, FeeandDecimal?.priceDecimal)}%
        </p>
      </Fragment>
    );
  };
  const calcPnl = (type) => {
    let totalpnl = 0,
      totalPnlPct = 0;
    try {
      let noofdays = dayOnly(botData.createdAt, botData.currentDate);
      console.log(noofdays, "-----91");
      let investment = calInv(botData.initial);
      if (type == "open") {
        totalpnl = bProfit.pnl;
        totalPnlPct = (bProfit.pnl / investment) * 100;
      }
      if (type == "avg") {
        totalpnl = parseFloat(bProfit.pnl) / noofdays;
        totalPnlPct = toFixed((totalpnl / investment) * 100, 2);
      }
    } catch (err) {}
    return (
      <Fragment>
        <h6>{toFixed(totalpnl, 2)}</h6>
        <span>{botData?.secondCurrency}</span>
        <p className={totalPnlPct < 0 ? "redText mb-0" : "greenTxt mb-0"}>
          {toFixed(totalPnlPct, 2)}%
        </p>
      </Fragment>
    );
  };
  let fetchBProfit = () => {
    let totBProfit = 0,
      totBPnl = 0,
      totAvgProfit = 0,
      totAvgPnl = 0;
    try {
      let noofdays = dayOnly(botData.createdAt, botData.updatedAt);
      console.log(noofdays, "-----123", bProfit);
      let investment = calInvClos(botData.initial);
      console.log(investment, "------127");
      if (!isEmpty(bProfit)) {
        totBProfit = bProfit.pnl;
        totBPnl = (bProfit.pnl / investment) * 100;
        totAvgProfit = parseFloat(bProfit.pnl) / noofdays;
        totAvgPnl = toFixed((totAvgProfit / investment) * 100, 2);
      }
      setBAProfit({
        totBProfit,
        totBPnl,
        totAvgProfit,
        totAvgPnl,
      });
    } catch (err) {}
  };
  useEffect(() => {
    // socket
    // let socketTicker = "socketTicker" + botData.exchange;
    // socketContext[socketTicker].on("marketPrice", (result) => {
    if (markResult && markResult.pairId == botData.pairId) {
      let mPrice = markResult.data.markPrice;
      let curVal = calcCV(botData.initial, botData.actives, mPrice);
      let investment = calInv(botData.actives);
      if (botData?.status == "open") {
        let pnl = calPnl(botData.actives, curVal, investment, mPrice);
        setPnl({
          totalpnl: pnl.totalpnl,
          totalPnlPct: pnl.totalPnlPct,
          totalWFpnl: pnl.totalWFpnl,
          totalWFPnlPct: pnl.totalWFPnlPct,
        });
      } else if (botData?.status == "closed") {
        let curVal = calcUCV(botData.initial, botData.actives);
        let investClosed = calInvClos(botData.initial);
        let pnl = calUPnl(botData.actives, curVal, investClosed);
        setPnl({ totalpnl: pnl.totalpnl, totalPnlPct: pnl.totalPnlPct });
      }
    }
    // });
    // return () => {
    //   socketContext[socketTicker].off("marketPrice");
    // };
  }, [markResult, botData.pairId, botData.exchange]);
  // useEffect(() => {
  //   calunReal();
  // }, [markPrice]);
  useEffect(() => {
    fetchBProfit();
  }, [bProfit]);
  useEffect(() => {
    socketContext.socket.on("openGridSocket", (result) => {
      console.log(result, "---------161", botData);
      if (
        botData.pairName == result.pairName &&
        botData.exchange == result.exchange
      ) {
        for (let item of result.data) {
          if (item.pairName == botData.pairName) {
            // fetchBProfit();
            fetchHistory();
          }
        }
      }
    });
  }, []);
  const calunReal = () => {
    try {
      let mPrice = markPrice;
      console.log(mPrice, "-------197", botData);
      if (!isEmpty(mPrice)) {
        let curVal = calcCV(botData.initial, botData.actives, mPrice);
        let investment = calInv(botData.actives);
        if (botData?.status == "open") {
          let pnl = calPnl(botData.actives, curVal, investment, mPrice);
          setPnl({
            totalpnl: pnl.totalpnl,
            totalPnlPct: pnl.totalPnlPct,
            totalWFpnl: pnl.totalWFpnl,
            totalWFPnlPct: pnl.totalWFPnlPct,
          });
        } else if (botData?.status == "closed") {
          let curVal = calcUCV(botData.initial, botData.actives);
          let investClosed = calInvClos(botData.initial);
          let pnl = calUPnl(botData.actives, curVal, investClosed);
          setPnl({ totalpnl: pnl.totalpnl, totalPnlPct: pnl.totalPnlPct });
        }
      }
    } catch (err) {}
  };
  const calPnl = (actives, curVal, investment, mPrice) => {
    try {
      let totalpnl = 0,
        totalPnlPct = 0,
        totalWFpnl = 0,
        totalWFPnlPct = 0;
      if (actives && actives.length > 0) {
        console.log(actives, "-------208", botData);
        console.log(mPrice, "--------209");
        for (let el of actives) {
          if (el.side == "sell") {
            console.log(el, "-------229");
            let prevOrd = el.prevOrd,
              pnl,
              wfPNL;
            if (botData.exchange == "BitMart") {
              pnl =
                mPrice * prevOrd.quantity - prevOrd.price * prevOrd.quantity;
              wfPNL =
                mPrice * prevOrd.quantity -
                (prevOrd.price * prevOrd.quantity + prevOrd.fee);
            } else if (botData.exchange == "Binance") {
              console.log(prevOrd, "--------prevOrd");
              console.log(el, "--------el");
              console.log(mPrice, "--------mPrice");
              if (prevOrd.quantity == el.quantity) {
                let fee = !isEmpty(prevOrd.fee) ? prevOrd.fee : 0;
                wfPNL =
                  mPrice * el.quantity - fee - prevOrd.price * prevOrd.quantity;
              } else {
                wfPNL =
                  mPrice * prevOrd.execQty - prevOrd.price * prevOrd.quantity;
              }
              pnl =
                mPrice * prevOrd.quantity - prevOrd.price * prevOrd.quantity;
            } else {
              pnl =
                mPrice * prevOrd.quantity - prevOrd.price * prevOrd.quantity;
              if (prevOrd.quantity == el.quantity) {
                let fee = !isEmpty(prevOrd.fee) ? prevOrd.fee : 0;
                wfPNL =
                  mPrice * el.quantity - fee - prevOrd.price * prevOrd.quantity;
              } else {
                wfPNL =
                  mPrice * prevOrd.execQty - prevOrd.price * prevOrd.quantity;
              }
            }
            console.log(pnl, "---178");
            console.log(wfPNL, "---179");
            console.log(pnl, "---178");
            totalpnl += pnl;
            totalWFpnl += wfPNL;
            // totalPnlPct = (curVal / investment) * 100 - 100;
          }
        }
      }
      console.log(investment, "-------264");
      totalPnlPct = totalpnl ? (totalpnl / investment) * 100 : 0;
      totalWFPnlPct = totalWFpnl ? (totalWFpnl / investment) * 100 : 0;
      console.log(totalpnl, "----184");
      console.log(totalPnlPct, "----185");
      console.log(totalWFpnl, "----186");
      console.log(totalWFPnlPct, "----187");
      return { totalpnl, totalPnlPct, totalWFpnl, totalWFPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const calUPnl = (actives, curVal, investment) => {
    try {
      let totalpnl = 0,
        totalPnlPct = 0;
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl = 0;
            if (!isEmpty(uRealized)) {
              pnl =
                uRealized.price * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            if (!isEmpty(botData.markPrice) && botData.markPrice > 0) {
              pnl =
                botData.markPrice * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            totalpnl = totalpnl + pnl;
            totalPnlPct = (curVal / investment) * 100 - 100;
          }
        }
      }
      return { totalpnl, totalPnlPct };
    } catch (err) {
      return 0;
    }
  };
  const fetchHistory = async () => {
    console.log(botData, "-------273");
    const { result } = await getGCHistory({
      botId: botData._id,
    });
    setUR(result.spotHistory);
    setBProfit(result.botHistory[0]);
  };
  useEffect(() => {
    fetchHistory();
  }, [botData?._id]);
  const calcCV = (initial, actives, mPrice) => {
    try {
      let totalpnl = 0,
        totalInv = 0;

      if (initial && initial.length > 0) {
        for (let el of initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl = mPrice * el.quantity - prevOrd.price * prevOrd.quantity;
            totalpnl = totalpnl + pnl;
          }
        }
      }
      return totalInv + totalpnl;
    } catch (err) {
      return 0;
    }
  };
  const calcUCV = (initial, actives) => {
    try {
      let totalpnl = 0,
        totalInv = 0;

      if (initial && initial.length > 0) {
        for (let el of initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            // let pnl =
            //   markPrice * el.quantity - prevOrd.price * prevOrd.quantity;
            let pnl = 0;
            if (!isEmpty(uRealized)) {
              pnl =
                uRealized.price * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            if (!isEmpty(botData.markPrice) && botData.markPrice > 0) {
              pnl =
                botData.markPrice * el.quantity -
                prevOrd.price * prevOrd.quantity;
            }
            totalpnl = totalpnl + pnl;
          }
        }
      }
      return totalInv + totalpnl;
    } catch (err) {
      return 0;
    }
  };

  const calInv = (actives) => {
    try {
      let totalInv = 0;
      if (actives && actives.length > 0) {
        for (let el of actives) {
          if (el.side == "sell") {
            console.log(el, "-------387");
            let prevOrd = el.prevOrd;
            totalInv = totalInv + prevOrd.price * prevOrd.quantity;
          }
        }
      }
      // if (filled && filled.length > 0) {
      //   for (let el of filled) {
      //     totalInv = totalInv + el.price * el.quantity;
      //   }
      // }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };
  const calInvClos = (filled) => {
    try {
      let totalInv = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      return totalInv;
    } catch (err) {
      return 0;
    }
  };

  useEffect(() => {
    if (actives && actives.length > 0) {
      let buyInOrder = 0,
        sellInOrder = 0,
        totalInOrder = 0;
      actives.map((item) => {
        let orderValue = item.price * item.quantity;
        if (item.buyorsell === "buy") {
          if (botData.exchange == "BitMart") {
            let oFee =
              parseFloat(FeeandDecimal?.tFee?.buyMaker) * item.quantity;
            oFee = oFee * item.price;
            orderValue = orderValue + oFee;
          }
          buyInOrder += orderValue;
        } else if (item.buyorsell === "sell") {
          sellInOrder += item.quantity;
        }
        totalInOrder += orderValue;
      });
      setDetails((prev) => {
        return {
          ...prev,
          buyInOrder,
          sellInOrder,
          totalInOrder,
        };
      });
    }
  }, [actives]);
  console.log(FeeandDecimal, "-------319");
  return (
    <>
      {botData.status && botData.status === "open" ? (
        <p className="greenTxt">
          <img
            src={require("../../assets/images/tic.png")}
            alt="Icon"
            width="16"
            height="16"
            className="img-fluid me-2"
          />
          <span>Active</span>
        </p>
      ) : (
        <p className="text-muted">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.00004 1.33301C4.32004 1.33301 1.33337 4.31967 1.33337 7.99967C1.33337 11.6797 4.32004 14.6663 8.00004 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8.00004 1.33301ZM2.66671 7.99967C2.66671 5.05301 5.05337 2.66634 8.00004 2.66634C9.23337 2.66634 10.3667 3.08634 11.2667 3.79301L3.79337 11.2663C3.08671 10.3663 2.66671 9.23301 2.66671 7.99967ZM4.73337 12.2063C5.63337 12.913 6.76671 13.333 8.00004 13.333C10.9467 13.333 13.3334 10.9463 13.3334 7.99967C13.3334 6.76634 12.9134 5.63301 12.2067 4.73301L4.73337 12.2063Z"
            ></path>
          </svg>
          <span className="mx-2">Open orders closed</span>
        </p>
      )}

      <div className="pair_flx">
        <img
          src={require("../../assets/images/" +
            botData.exchange.toLowerCase() +
            ".png")}
          alt="Icon"
          width="20"
          className="img-fluid me-2"
        />
        <span>{botData.firstCurrency && botData.firstCurrency}</span>
        <span className="greyTxt ms-1">
          {botData.secondCurrency && botData.secondCurrency}
        </span>
        <span className="dark_bage ms-1">GRID</span>
      </div>

      <span className="light_bage">Sideways</span>

      <div className="tab_btn right_tab  mb-3">
        <button
          className={"tab1 active"}
          style={{ width: "100%", padding: "6px 12px" }}
        >
          USDT
        </button>
      </div>

      <div>
        {botData.status == "open" && (
          <>
            <div className="flx_box mt-3 mb-2">
              <div className="box">
                <div className="flx">
                  <span>Bot profit (PNL with Fee)</span>
                  <i
                    class="bi bi-info-circle-fill ms-12"
                    data-tooltip-id="bot_daily"
                  ></i>
                  <ReactTooltip
                    id="bot_daily"
                    place="bottom"
                    content={
                      <div>
                        <span className="mb-4 d-block">
                          Bot profit (PNL with Fee)
                        </span>
                        <span className="mb-4">
                          Realized PNL of&nbsp;the&nbsp;bot. The current profit
                          that the bot has made from its work.
                        </span>
                      </div>
                    }
                  />
                </div>

                <p className={totalWFPnlPct > 0 ? "greenTxt" : "redText"}>
                  {" "}
                  {toFixedDown(totalWFPnlPct, 2)}%
                </p>
                <h6>{toFixedDown(totalWFpnl, 2)}</h6>
                <span>{botData?.secondCurrency}</span>
                {/* {calcPnl("open")} */}
              </div>

              <div className="box">
                <div className="flx">
                  <span>Bot profit (PNL without Fee )</span>
                  <i
                    class="bi bi-info-circle-fill ms-12"
                    data-tooltip-id="bot_daily"
                  ></i>
                  <ReactTooltip
                    id="bot_daily"
                    place="bottom"
                    content={
                      <div>
                        <span className="mb-4 d-block">
                          Bot profit (PNL without Fee)
                        </span>
                        <span className="mb-4">
                          Realized PNL of&nbsp;the&nbsp;bot. The current profit
                          that the bot has made from its work.
                        </span>
                      </div>
                    }
                  />
                </div>

                <p className={totalPnlPct > 0 ? "greenTxt" : "redText"}>
                  {" "}
                  {toFixedDown(totalPnlPct, 2)}%
                </p>
                <h6>{toFixedDown(totalpnl, 2)}</h6>
                <span>{botData?.secondCurrency}</span>
                {/* {calcPnl("avg")} */}
              </div>
            </div>

            <div className="mb-3">
              <div className="bot_preview_box">
                <div>
                  <span className="d-block">
                    <i
                      class="bi bi-info-circle-fill me-2"
                      data-tooltip-id="realized"
                    ></i>
                    <ReactTooltip
                      id="realized"
                      place="bottom"
                      content={
                        <div>
                          <span className="mb-2 d-block">Unrealized</span>
                          <span className="mb-2">
                            Unrealized PNL of the bot. The cost of funds used by
                            the bot,
                            <br /> given the current prices and fees from the
                            Base and Averaging orders.
                          </span>
                        </div>
                      }
                    />
                    Unrealized
                  </span>

                  <span className="border_line"></span>
                  <span>
                    {toFixedDown(totalWFpnl, 2)}{" "}
                    <span className="greyTxt me-1">
                      {botData?.secondCurrency}
                    </span>
                    /{" "}
                    <span
                      className={
                        totalWFPnlPct < 0 ? "redText mb-0" : "greenTxt mb-0"
                      }
                    >
                      {toFixedDown(totalWFPnlPct, 2)}%
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {botData.status == "closed" && (
          <>
            <div className="flx_box mt-3 mb-2">
              <div className="box">
                <div className="flx">
                  <span>Bot profit</span>
                  <i
                    class="bi bi-info-circle-fill ms-2"
                    data-tooltip-id="bot_daily"
                  ></i>
                  <ReactTooltip
                    id="bot_daily"
                    place="bottom"
                    content={
                      <div>
                        <span className="mb-2 d-block">Bot profit</span>
                        <span className="mb-2">
                          Realized PNL of the bot. the current profit that the
                          bot has made from its work.
                        </span>
                      </div>
                    }
                  />
                </div>
                <Fragment>
                  <h6>{toFixed(totBProfit, 2)}</h6>
                  <span>{botData?.secondCurrency}</span>
                  <p className={totBPnl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
                    {toFixed(totBPnl, 2)}%
                  </p>
                </Fragment>
                {/* {calcPnl("open")} */}
              </div>

              <div className="box">
                <div className="flx">
                  <span>Avg. daily profit</span>
                  <i
                    class="bi bi-info-circle-fill ms-2"
                    data-tooltip-id="avg_daily"
                  ></i>
                  <ReactTooltip
                    id="avg_daily"
                    place="bottom"
                    content={
                      <div>
                        <span className="mb-2 d-block">Avg. daily profit</span>
                        <span className="mb-2">
                          Average daily profit of&nbsp;the bot for the entire
                          time of&nbsp;its work.
                        </span>
                      </div>
                    }
                  />
                </div>
                <Fragment>
                  <h6>{toFixed(totAvgProfit, 2)}</h6>
                  <span>{botData?.secondCurrency}</span>
                  <p
                    className={totAvgPnl < 0 ? "redText mb-0" : "greenTxt mb-0"}
                  >
                    {toFixed(totAvgPnl, 2)}%
                  </p>
                </Fragment>
                {/* {calcPnl("avg")} */}
              </div>
            </div>
            <div className="mb-3">
              <div className="bot_preview_box">
                <div>
                  <span className="d-block">
                    <i
                      class="bi bi-info-circle-fill me-2"
                      data-tooltip-id="realized"
                    ></i>
                    <ReactTooltip
                      id="realized"
                      place="bottom"
                      content={
                        <div>
                          <span className="mb-2 d-block">Unrealized</span>
                          <span className="mb-2">
                            Unrealized PNL of the bot. The cost of funds used by
                            the bot,
                            <br /> given the current prices and fees from the
                            Base and Averaging orders.
                          </span>
                        </div>
                      }
                    />
                    Unrealized
                  </span>

                  <span className="border_line"></span>
                  <span>
                    {toFixed(totalpnl, 2)}{" "}
                    <span className="greyTxt me-1">
                      {botData?.secondCurrency}
                    </span>
                    /{" "}
                    <span
                      className={
                        totalPnlPct < 0 ? "redText mb-0" : "greenTxt mb-0"
                      }
                    >
                      {toFixed(totalPnlPct, 2)}%
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="mb-2">
          <h3 className="bot_preview_title">In Orders</h3>
          <div className="bot_preview_box">
            <div>
              <span>Base currency</span>
              <span className="border_line"></span>
              <span>
                {toFixed(details?.sellInOrder, 8)}{" "}
                <span className="greyTxt">{botData?.firstCurrency}</span>
              </span>
            </div>
            <div>
              <span>Quote currency</span>
              <span className="border_line"></span>
              <span>
                {toFixed(details?.buyInOrder, 8)}{" "}
                <span className="greyTxt">{botData?.secondCurrency}</span>
              </span>
            </div>
            <div>
              <span>Value</span>
              <span className="border_line"></span>
              <span>
                {toFixed(details?.totalInOrder, 8)}{" "}
                <span className="greyTxt">{botData?.secondCurrency}</span>
              </span>
            </div>
          </div>
        </div>

        <div className="mb-2">
          <h3 className="bot_preview_title">Invested funds</h3>
          <div className="bot_preview_box">
            <div>
              <span>Base currency</span>
              <span className="border_line"></span>
              <span>
                {toFixed(botData?.sellInvt, FeeandDecimal?.quantityDecimal)}{" "}
                <span className="greyTxt">{botData?.firstCurrency}</span>
              </span>
            </div>
            <div>
              <span>Quote currency</span>
              <span className="border_line"></span>
              <span>
                {toFixed(botData?.buyInvt, FeeandDecimal?.priceDecimal)}{" "}
                <span className="greyTxt">{botData?.secondCurrency}</span>
              </span>
            </div>
            <div>
              <span>Value</span>
              <span className="border_line"></span>
              <span>
                {/* {
                                    invt
                                    && invt
                                    &&
                                    toFixedDown(openOrderData.invt, openOrderData?.pairId?.secondFloatDigit)
                                } */}
                {botData?.invt}{" "}
                <span className="greyTxt">{botData.secondCurrency}</span>
              </span>
            </div>
          </div>
        </div>

        <div className="mb-2">
          <h3 className="bot_preview_title">General info</h3>
          <div className="bot_preview_box">
            <div>
              <span>Transactions</span>
              <span className="border_line"></span>
              <span>{botData?.txCnt}</span>
            </div>
            <div>
              <span>Trading time</span>
              <span className="border_line"></span>
              <span>
                {botData?.status == "open"
                  ? dateHours(botData?.createdAt)
                  : dateHoursclose(botData?.createdAt, botData?.updatedAt)}
              </span>
            </div>
            <div>
              <span>Bot ID</span>
              <span className="border_line"></span>
              <span className="d-flex align-items-center">
                <span className="half_view">
                  {" "}
                  5fd28f5402b04f35860ec..
                  {/* {openOrderData && openOrderData._id ? openOrderData._id : ""} */}
                </span>
                <img
                  src={require("../../assets/images/copy-icon.png")}
                  alt="Icon"
                  className="img-fluid cursor"
                  width="12"
                  // onClick={() => {
                  //   navigator.clipboard.writeText(openOrderData._id);
                  //   toastAlert("success", "Bot ID Copied");
                  // }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Performance;
