import React, { useState, useEffect } from "react";

import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector } from "react-redux";

// import context
import CreateBot from "./CreateBot";

// import action
import { getpairs } from "../../actions/Bottrade";

// import lib
import TradePairProfit from "../../components/BotTrade/TradePairProfit.js";

// import components
import GridBotDetails from "./GridBotDetails";

const buySell = {
  buyAmount: 0,
  sellAmount: 0,
  orgPairName: "",
};

export default function GenieSettings(props) {
  const {
    params,
    changeGrid,
    setIsGridTrade,
    fetchTrade,
    markPrice,
    markResult,
    FeeandDecimal,
    updatePair,
    balancefetch,
    balancefetchAll,
    visible,
    updateParams,
    updateId,
  } = props;
  console.log(balancefetch, "balancefetch42");
  //pairlist get
  const [PairList, setPairList] = useState();
  const [pairListTrd, setPairListTrd] = useState();

  // redux
  const curBot = useSelector((state) => state.curGridBot);
  const botData = useSelector((state) => state.botStatus);

  const fetchpairs = async (currency) => {
    const { result } = await getpairs({
      exchange: params.exchange,
      bot: "Grid",
      pairName: splitData(params.pair),
      currency: currency,
    });
    var resp1 = [...result];

    var resp1 = [...result];
    var resp2 = [...result];

    var alphaSort = resp2?.sort((a, b) =>
      a.firstCurrencySymbol < b.firstCurrencySymbol
        ? -1
        : a.firstCurrencySymbol > b.firstCurrencySymbol
        ? 1
        : 0
    );
    if (params.bot == "grid") {
      var numbersort = resp1?.sort(
        (a, b) =>
          parseFloat(b.gridProfit?.thirtyDays) -
          parseFloat(a.gridProfit?.thirtyDays) // desc
      );
      setPairList([...numbersort]);
    }
    setPairListTrd([...alphaSort]);
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };

  useEffect(() => {
    fetchpairs("USDT");
  }, [params.pair, params.exchange, params.bot]);
  console.log(markResult, "--------markResult");
  return (
    <div className="trade_main_right trade_main_right_createbot">
      <div className="trade_main_settings cardheadbg">
        {/* GRID Dot Details - Performance & Settings */}
        {curBot.action == "detail" && botData.openStatus && (
          <GridBotDetails
            params={params}
            markPrice={markPrice}
            markResult={markResult}
            FeeandDecimal={FeeandDecimal}
            updateParams={updateParams}
          />
        )}

        {/*Create GRID Bot*/}
        {curBot.action == "create" && botData.openStatus && (
          <CreateBot
            data={params}
            onChange={changeGrid}
            setIsGridTrade={setIsGridTrade}
            fetchTrade={fetchTrade}
            pairsAll={pairListTrd}
            updatePair={updatePair}
            balancefetch={balancefetch}
            FeeandDecimal={FeeandDecimal}
            markPrice={markPrice}
            markResult={markResult}
          />
        )}
      </div>

      <TradePairProfit
        params={params}
        pairsAll={PairList}
        updatePair={updatePair}
        balancefetchAll={balancefetchAll}
        visible={visible}
        updateId={updateId}
      />
    </div>
  );
}
