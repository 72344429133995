import React, { useContext, useEffect, useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";

// import component
import ExchangeBalance from "../BotTrade/ExchangeBalance.js";
import TradePairs from "../BotTrade/TradePairs.js";

// import action
import {
  getMarketPriceexchange,
  getfetchrunBotdca,
  getlockedAmt,
} from "../../actions/Bottrade.js";
import { GridOrderPlace, curBotDetail } from "../../actions/gridbot";

// import lib
import { toFixed, toFixedDown, toPercentage } from "../../lib/roundOf.js";
import isEmpty from "../../lib/isEmpty.js";
import { toastAlert } from "../../lib/toastAlert.js";
import PreviewCreateBot from "./PrevCreate";
import Slider from "@mui/material/Slider";
import { gridBacktest } from "../../actions/Bottrade";
// import component
import BacktestModal from "./BacktestModal.js";
import SocketContext from "../Context/SocketContext.js";
import { endDate, startDate } from "../../lib/dateFilter.js";

let curBotS = {};
const gridData = {
  low: 0,
  high: 0,
  maxHigh: 0,
  minLow: 0,
  gridpercent: 0,
  gShowP: 0,
  gridlvl: 0,
  investamount: "",
  initialQuote: "",
  initialBase: "",
  totalBuyInvst: 0,
  totalSellInvst: 0,
  sl: 1,
  isSL: false,
  isTP: false,
  tpRatio: 5,
  slvalue: 100,
};

const gridStatus = {
  levelStatus: false,
  stepStatus: false,
};
export default function GridBot(props) {
  console.log("-------createBot");
  let {
    data,
    setIsGridTrade,
    fetchTrade,
    pairsAll,
    updatePair,
    balancefetch,
    FeeandDecimal,
    markPrice,
    markResult,
  } = props;
  let { pair, exchange } = data;
  const dispatch = useDispatch();
  const socketContext = useContext(SocketContext);
  // redux
  const curBot = useSelector((state) => state.curGridBot);
  // redux
  const botData = useSelector((state) => state.botStatus);

  const [GridElements, setGridElements] = useState(gridData);
  const [gStatus, setGStatus] = useState(gridStatus);
  const [PairData, setPairData] = useState();
  const [ExchangeIn, setExchangeIn] = useState();
  const [tFee, setTFee] = useState();
  const [btLoader, setBtLoader] = useState(false);
  const [isPreview, setIsPreview] = useState("off");
  const [loader, setLoader] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [closeicon, setClose] = useState("off");
  const [validErr, setValidErr] = useState({});
  const [pData, setProfit] = useState({});
  const [backstbtn, setbackstbtn] = useState(false);
  const [iniStatus, setIniStatus] = useState("initial");
  const [multiplebot, setmultiplebot] = useState("");
  const usdprice = 0.9999;
  let { levelStatus, stepStatus } = gStatus;
  let {
    gridlvl,
    gridpercent,
    gShowP,
    high,
    maxHigh,
    minLow,
    low,
    investamount,
    initialQuote,
    initialBase,
    totalBuyInvst,
    totalSellInvst,
    sl,
    isSL,
    isTP,
    tpRatio,
    slvalue,
  } = GridElements;
  const [bTModal, setBTModal] = useState(false);
  const [btData, setBtData] = useState({});
  const [ActBtData, setActBtData] = useState({});

  // Toggle backtest modal
  const toggleBtModal = () => {
    setBTModal(!bTModal);
    setbackstbtn(!backstbtn);
  };
  const handleContinue = async () => {
    let formValue = GridElements;
    let msg = await validateErr(formValue);
    if (isEmpty(msg)) {
      setIsPreview("on");
    } else {
      toastAlert("error", msg, "step");
    }
  };

  const handleBack = () => {
    setIsPreview("off");
  };

  const handleClose = () => {
    setClose("off");
    setIsPreview("off");
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };

  const splitOne = (data, type) => {
    let check = data.split("-");
    if (type == "first") {
      return check[0];
    }
    if (type == "second") {
      return check[1];
    }
  };

  //Grid purpose
  const getCurMarketPrice = async () => {
    try {
      let reqData = { exchange: exchange, pair: splitData(pair) };
      const { status, result } = await getMarketPriceexchange(reqData);
      if (status == "success") {
        let Exchangeinfo = result.Exchangeinfo;
        let priceDecimal = Exchangeinfo?.priceDecimal;
        setPairData(result.pairData);
        setExchangeIn(Exchangeinfo);
        setTFee(result.tFee);
        if (result && result.currentPrice) {
          let pairData = result.pairData;

          // let firstFloatDigit = pairData?.firstFloatDigit
          //   ? pairData.firstFloatDigit
          //   : 6;
          // let secondFloatDigit = pairData?.secondFloatDigit
          //   ? pairData.secondFloatDigit
          //   : 6;
          // let l = toFixedDown(
          //     toPercentage(result.currentPrice, 50, "-"),
          //     secondFloatDigit
          //   ),
          //   h = toFixedDown(
          //     toPercentage(result.currentPrice, 50, "+"),
          //     secondFloatDigit
          //   ),

          // let l = toFixedDown(result.lowestPrice, priceDecimal),
          //   h = toFixedDown(result.highestPrice, priceDecimal),
          //   investment = toFixedDown(balancefetch[0]?.USDT, 2);
          // let avgPrice = (h + l) / 2;
          // let gridStepval = 0.31 / 100;
          // let gridStep = 0.31;
          // let gridlvl = Math.floor((h - l) / (gridStepval * avgPrice)) + 1;
          // if (gridlvl > pairData.maxGirdLevel) {
          //   gridlvl = pairData.maxGirdLevel;
          //   let ratio = Math.pow(h / l, 1 / gridlvl);
          //   gridStep = (ratio - 1) * 100;
          //   gridStep = toFixedDown(gridStep, 2);
          // }
          // const gridData = {
          //   low: l,
          //   high: h,
          //   maxHigh: h * 2,
          //   minLow: l - (l * 25) / 100,
          //   gridpercent: gridStep,
          //   gShowP: gridStep,
          //   investamount: investment,
          //   gridlvl: gridlvl,
          // };
          let gPercent, level;
          if (exchange == "Bybit") {
            gPercent = pairData.markPrice >= 10 ? 0.59 : 0.14;
            level = 100;
          } else {
            gPercent = pairData.markPrice <= 0.01 ? 0.14 : 0.33;
            level = 180;
          }
          let lowHigh = gLowHigh(pairData.markPrice, gPercent / 100, level);
          console.log(lowHigh, "---------187");
          let l = toFixedDown(lowHigh.lp, priceDecimal),
            h = toFixedDown(lowHigh.hp, priceDecimal),
            investment = balancefetch[0]?.USDT;
          investment = parseFloat(investment) - parseFloat(result?.lockBAmt);
          let investBase =
            parseFloat(balancefetch[1]?.[pairData.firstCurrencySymbol]) -
            parseFloat(result?.lockSAmt);
          console.log(investment, "-------225", investBase);

          let avgPrice = (parseFloat(h) + parseFloat(l)) / 2;
          let gridStepval = gPercent / 100;
          let gridlvl = Math.floor((h - l) / (gridStepval * avgPrice)) + 1;
          console.log(gridlvl, "------228");
          console.log(pairData.maxGirdLevel, "------229");
          if (gridlvl > pairData.maxGirdLevel) {
            gridlvl = pairData.maxGirdLevel;
            let ratio = Math.pow(h / l, 1 / gridlvl);
            gPercent = (ratio - 1) * 100;
          }
          console.log(gridlvl, "------235");
          const gridData = {
            low: l,
            high: h,
            maxHigh: h * 2,
            minLow: l - (l * 25) / 100,
            gridpercent: gPercent,
            gShowP: toFixedDown(gPercent, 2),
            investamount: toFixedDown(investment, priceDecimal),
            initialQuote: toFixedDown(investment, 8),
            initialBase: toFixedDown(investBase, 8),
            gridlvl: gridlvl,
          };

          setGridElements((prev) => {
            return { ...prev, ...gridData };
          });
          gSChange(true, true);
        }
      }
    } catch (err) {}
  };
  const gLowHigh = (price, step, level) => {
    try {
      console.log(price, "------207");
      let gLevel = level / 2;
      let lowP = price * Math.pow(1 - step, gLevel);
      let lp = lowP + (lowP * 0.2) / 100;
      let highP = price * Math.pow(1 + step, gLevel);
      let hp = highP + (highP * 0.2) / 100;
      return { hp, lp, level };
    } catch (err) {
      console.log(err, "--------215");
      return 0;
    }
  };

  const fetchrunBot = async () => {
    let reqData = {
      exchange: exchange,
      pairName: pair,
      botCategory: "dca",
    };
    const { status, message } = await getfetchrunBotdca(reqData);
    if (!status) {
      setmultiplebot(message);
    } else {
      setmultiplebot("");
    }
  };

  useEffect(() => {
    getCurMarketPrice();
  }, [balancefetch]);

  useEffect(() => {
    setClose("off");
  }, [curBot]);

  useEffect(() => {
    fetchrunBot();
  }, [pair, exchange]);

  const valCheck = (value, name, formValue) => {
    value = parseFloat(value);
    let errors = {},
      lowPrice = name == "low" ? value : parseFloat(formValue.low),
      gridLvl = name == "gridlvl" ? value : parseFloat(formValue.gridlvl),
      gridPer =
        name == "gridpercent" ? value : parseFloat(formValue.gridpercent),
      highPrice = name == "high" ? value : parseFloat(formValue.high);
    if (lowPrice < minLow) {
      errors.low = `Price cannot be less than ${minLow}`;
    }
    if (lowPrice > highPrice) {
      errors.low = `Low Price must be lesser than High Price`;
    }
    if (highPrice > maxHigh) {
      errors.high = `High Price cannot be greater than ${maxHigh}`;
    }
    if (highPrice < minLow) {
      // is Bitsgap Flow
      errors.high = `Price cannot be less than ${minLow}`;
    }
    if (highPrice < lowPrice) {
      errors.high = `High Price must be greater than Low Price`;
    }
    if (
      lowPrice < highPrice &&
      highPrice < maxHigh &&
      highPrice > minLow &&
      highPrice > lowPrice &&
      parseFloat(gridPer) < 0.1
      // name == "gridpercent"
    ) {
      errors.gridpercent = `Step is too low Minimum: 0.1`;
    }
    console.log(gridLvl, "------gridLvl");
    // if (name == "gridlvl") {
    if (gridLvl < PairData.minGirdLevel) {
      errors.gridlvl = `Grid level too low`;
    }
    if (gridLvl > PairData.maxGirdLevel) {
      errors.gridlvl = `Grid level too high`;
    }
    // }
    if (name == "investamount") {
      console.log(formValue.initialQuote, "-------333", formValue);
      console.log(value, "-------334", totalBuyInvst);
      console.log(totalSellInvst, "------totalSellInvst");
      if (parseFloat(totalSellInvst) > parseFloat(formValue.initialBase)) {
        console.log("---------336");
        let bInvest =
          parseFloat(formValue.initialQuote) - parseFloat(totalBuyInvst); // 500 - 200 = 300
        let sInvest =
          parseFloat(formValue.initialBase) - parseFloat(totalSellInvst); // 1 - 1 = 0
        sInvest = Math.abs(sInvest);
        sInvest = sInvest * PairData.markPrice; // 2 * markprice(3800) = 7400
        console.log(bInvest, "---------bInvest");
        console.log(sInvest, "---------sInvest");
        if (parseFloat(bInvest) < parseFloat(sInvest)) {
          errors.invt = "Insufficient Balance";
        }
      }
      let sInvest = formValue.initialBase * PairData.markPrice;
      let totInvest = sInvest + parseFloat(formValue.initialQuote);
      console.log(totInvest, "--------351");
      if (
        totInvest < parseFloat(value) ||
        parseFloat(formValue.initialQuote) < parseFloat(totalBuyInvst)
      ) {
        console.log("-------366");
        errors.invt = "Insufficient Balance";
      }
    }
    console.log(errors, "-------errors2");
    return errors;
  };
  const handleModify = (e) => {
    e.preventDefault();

    let { name, value } = e.target;

    if (name == "gridpercent" || name == "investamount") {
      let str = value.toString();
      let convertStr = str.split(".");
      if (convertStr[1] && convertStr[1].length > 2 && name == "gridpercent") {
        return;
      }
      if (
        convertStr[1] &&
        convertStr[1].length > FeeandDecimal?.priceDecimal &&
        name == "investamount"
      ) {
        return;
      }
    }
    if (/[^0-9.]/.test(value)) {
      return;
    }
    let formValue = GridElements;
    formValue = { ...formValue, ...{ [name]: value } };
    console.log("-------------396", formValue);
    let formErr = valCheck(value, name, formValue);
    console.log(formErr, "-------------398", formValue);
    let initial = iniStatus;
    if (initial == "initial") {
      setIniStatus("editable");
    }
    if (!isEmpty(formErr)) {
      setErrMsg(formErr);
      if (name == "gridpercent") {
        formValue = { ...formValue, ...{ gShowP: value } };
      }
      console.log(formValue, "-------408");
      setGridElements(formValue);
      return;
    }
    if (name == "investamount") {
      // if (parseFloat(formValue.initialQuote) < parseFloat(value)) {
      //   toastAlert("error", "Insufficient Balance", "step");
      // }
      let slvalue = toFixedDown(
        (parseFloat(value) / parseFloat(formValue.initialQuote)) * 100,
        2
      );

      formValue = {
        ...formValue,
        ...{ slvalue: slvalue },
      };
    }

    if (name == "slvalue") {
      formValue = {
        ...formValue,
        ...{
          investamount:
            (parseFloat(formValue.initialQuote) * parseFloat(value)) / 100,
        },
      };
    }
    if (name != "gridpercent" && !stepStatus) {
      let h = name == "high" ? value : formValue.high;
      let l = name == "low" ? value : formValue.low;
      let lvl = name == "gridlvl" ? value : formValue.gridlvl;
      let ratio = Math.pow(h / l, 1 / lvl);
      let gPercent = (ratio - 1) * 100;
      if ((name == "high" || name == "low") && value == "") {
        formValue = {
          ...formValue,
          ...{ gridpercent: 0, gShowP: 0 },
        };
      } else {
        formValue = {
          ...formValue,
          ...{ gridpercent: gPercent, gShowP: toFixedDown(gPercent, 2) },
        };
      }
    }
    if (name == "gridpercent") {
      formValue = {
        ...formValue,
        ...{ gShowP: value },
      };
    }
    setGridElements(formValue);
    if (name == "gridpercent") {
      gSChange(false, true);
    }
    if (name == "sl" && value > 0) {
      curBotDetail(
        {
          stopLoss: parseFloat(value),
        },
        dispatch
      );
    }
    setErrMsg({});
  };

  const handleModifygridlevel = (e) => {
    e.preventDefault();

    let { name, value } = e.target;
    let formValue = GridElements;
    if (/[^0-9.]/.test(value)) {
      return;
    }
    let formErr = valCheck(value, name, formValue);
    setIniStatus("not-editable");
    let ratio = Math.pow(formValue.high / formValue.low, 1 / parseFloat(value));
    let gridpercent = (ratio - 1) * 100;
    let namegp = "gridpercent";
    if (!isEmpty(formErr)) {
      formValue = {
        ...formValue,
        ...{ [name]: value },
      };
      if (!isEmpty(formErr?.gridpercent)) {
        formValue = {
          ...formValue,
          ...{
            [name]: value,
            [namegp]: gridpercent,
            gShowP: toFixedDown(gridpercent, 2),
          },
        };
      }
      setErrMsg(formErr);
      setGridElements(formValue);
      return;
    }

    formValue = {
      ...formValue,
      ...{
        [name]: value,
        [namegp]: gridpercent,
        gShowP: toFixedDown(gridpercent, 2),
      },
    };
    setGridElements(formValue);
    gSChange(true, false);
    setErrMsg({});
  };
  const gSChange = (levelStatus, stepStatus) => {
    let formStatus = {
      ...gStatus,
      ...{ levelStatus, stepStatus },
    };
    setGStatus(formStatus);
  };
  const invtFeeCal = (invt, pDec, qDec, gp, totalBuyInvest) => {
    try {
      let mPrice = PairData.markPrice;
      let totBInvt = 0;
      for (let p of gp) {
        p = toFixedDown(p, pDec);
        let invtPOrder = toFixedDown(invt, pDec) / gp.length;
        let q = toFixedDown(invtPOrder / mPrice, qDec),
          S = mPrice > p ? "buy" : "sell";
        if (S == "buy") {
          let buyFee = q * parseFloat(tFee?.buyTaker);
          buyFee = buyFee * mPrice;
          totBInvt = totBInvt + parseFloat(invtPOrder - buyFee);
        }
      }
      return totBInvt;
    } catch (err) {
      return toFixedDown(totalBuyInvest, pDec);
    }
  };
  useEffect(() => {
    // socket
    // let socketTicker = "socketTicker" + exchange;
    // socketContext[socketTicker].on("marketPrice", (result) => {
    let tikerName = markResult && markResult.pairName.replace("-", "");
    if (tikerName == PairData?.tikerRoot) {
      console.log(markResult.pairName, "---------419");
      setGridElements((item) => {
        let gElem = {};
        const mPrice = markResult.data.markPrice;
        console.log(curBotS, "---------curBotS");
        console.log(mPrice, "---------mPrice");
        if (
          !isEmpty(curBotS) &&
          (curBotS.buy > mPrice || curBotS.sell < mPrice) &&
          item.gridpercent != "" &&
          parseFloat(item.gridpercent) > 0 &&
          item.low != "" &&
          parseFloat(item.low) > 0 &&
          item.high != "" &&
          parseFloat(item.high) > 0
        ) {
          let formValue = item;

          let priceDecimal = ExchangeIn?.priceDecimal
            ? ExchangeIn.priceDecimal
            : 8;
          let quantityDecimal = ExchangeIn?.quantityDecimal
            ? ExchangeIn.quantityDecimal
            : 6;
          let l = item.low,
            h = item.high,
            gp = [];

          let low = l,
            high = h,
            gridperc = formValue?.gridpercent;
          l = parseFloat(l);

          while (l < h) {
            l = parseFloat(l);
            gp.push(l);
            l = l + l * (gridperc / 100);
            l = toFixedDown(l, priceDecimal);
            l = parseFloat(l);
          }

          let investment = toFixedDown(formValue.investamount, priceDecimal);
          let investPerOrder = investment / gp.length;
          let totalBuyInvest = 0,
            totalSellInvest = 0;

          let chartArr = [
            {
              price: low,
            },
          ];
          let lastBS = {};
          for (let p of gp) {
            p = toFixedDown(p, priceDecimal);
            let q = toFixedDown(investPerOrder / p, quantityDecimal),
              S = mPrice > p ? "buy" : "sell",
              O = p * q;
            console.log(S, "--------578");
            console.log(p, "--------579");
            console.log(q, "--------580");
            if (S == "buy") {
              totalBuyInvest = totalBuyInvest + parseFloat(O);
              lastBS.buy = p;
            } else {
              totalSellInvest = totalSellInvest + parseFloat(q);
              if (!lastBS.sell || p > lastBS.sell) {
                lastBS.sell = p;
              }
            }

            chartArr.push({
              price: p,
              quantity: q,
              side: S,
            });
          }

          chartArr.push({
            price: high,
          });
          curBotS = lastBS;
          console.log(chartArr, "-------496");
          curBotDetail(
            {
              pairName: markResult.pairName,
              actives: chartArr,
              lastBS,
              breakingEvent: 0,
              filled: [],
            },
            dispatch
          );
          console.log(totalBuyInvest, "----totalBuyInvest");
          console.log(totalSellInvest, "----totalSellInvest");
          let formdata = {
            totalBuyInvst: toFixedDown(totalBuyInvest, priceDecimal),
            totalSellInvst: toFixedDown(totalSellInvest, quantityDecimal),
          };
          formValue = { ...formValue, ...formdata };
          gElem = formValue;
          // setGridElements(formValue);
          // validateErr(formValue);
        } else {
          gElem = item;
        }
        return gElem;
      });
    }
    // });
    // return () => {
    //   socketContext[socketTicker].off("marketPrice");
    // };
  }, [PairData, markResult]);
  useEffect(() => {
    if (
      !isEmpty(PairData) &&
      !isEmpty(GridElements) &&
      !isEmpty(GridElements.investamount) &&
      GridElements.gridpercent != "" &&
      parseFloat(GridElements.gridpercent) > 0 &&
      GridElements.low != "" &&
      parseFloat(GridElements.low) > 0 &&
      GridElements.high != "" &&
      parseFloat(GridElements.high) > 0 &&
      GridElements.minLow != "" &&
      parseFloat(GridElements.minLow) > 0 &&
      GridElements.maxHigh != "" &&
      parseFloat(GridElements.maxHigh) > 0 &&
      // isEmpty(errMsg) &&
      isEmpty(errMsg?.invt)
    ) {
      let formValue = GridElements;
      let priceDecimal = ExchangeIn.priceDecimal;
      let quantityDecimal = ExchangeIn.quantityDecimal;

      let l = GridElements.low,
        h = GridElements.high,
        gp = [];
      console.log(l, "----------677");
      console.log(h, "----------678");
      if (l > minLow && h < maxHigh) {
        let low = l,
          high = h,
          gridperc = formValue?.gridpercent;
        l = parseFloat(l);
        h = parseFloat(h);
        while (l < h) {
          l = parseFloat(l);
          gp.push(l);
          let lastL = l;
          l = l + l * (gridperc / 100);
          l = toFixed(l, priceDecimal ? priceDecimal : 8);
          l = parseFloat(l);
          if (lastL == l) {
            return;
          }
        }
        console.log("---------695");
        let investment = toFixedDown(formValue.investamount, priceDecimal);
        let investPerOrder = investment / gp.length;
        let totalBuyInvest = 0,
          totalSellInvest = 0;
        let chartArr = [
          {
            price: low,
          },
        ];
        let lastBS = { buy: null, sell: null };
        for (let p of gp) {
          console.log(PairData.markPrice, "-------574", p);
          p = toFixedDown(p, priceDecimal);
          let q = toFixedDown(investPerOrder / p, quantityDecimal),
            S = PairData.markPrice > p ? "buy" : "sell",
            O = p * q;
          console.log(S, "---------681");
          console.log(p, "---------682");
          console.log(q, "---------683");
          if (S == "buy") {
            totalBuyInvest = totalBuyInvest + parseFloat(O);
            if (lastBS.buy == null || p > lastBS.buy) {
              lastBS.buy = p;
            }
          } else {
            totalSellInvest = totalSellInvest + parseFloat(q);
            if (lastBS.sell == null || p < lastBS.sell) {
              lastBS.sell = p;
            }
          }
          console.log(totalBuyInvest, "------696");
          console.log(totalSellInvest, "------695");
          chartArr.push({
            price: p,
            quantity: q,
            side: S,
          });
        }

        chartArr.push({
          price: high,
        });
        curBotS = lastBS;
        console.log(PairData, "----------581", lastBS);
        curBotDetail(
          {
            pairName: PairData.tikerRoot,
            actives: chartArr,
            lastBS,
            breakingEvent: 0,
            filled: [],
          },
          dispatch
        );

        let avgPrice = (parseFloat(high) + parseFloat(low)) / 2;
        let gridStepval = formValue?.gridpercent / 100;
        let gridlvl = Math.floor((high - low) / (gridStepval * avgPrice)) + 1;
        let sl = parseFloat(low) - (parseFloat(low) * 3) / 100;
        if (exchange == "BitMart") {
          let totBSInvt = invtFeeCal(
            formValue.investamount,
            priceDecimal,
            quantityDecimal,
            gp,
            totalBuyInvest
          );
          console.log(totBSInvt, "--------621");
          totalBuyInvest = totBSInvt;
        }

        let formdata = {
          // low: low,
          // high: high,
          totalBuyInvst: toFixedDown(totalBuyInvest, priceDecimal),
          totalSellInvst: toFixedDown(totalSellInvest, quantityDecimal),
          sl: toFixedDown(sl, quantityDecimal),
        };
        let initial = iniStatus;
        console.log(levelStatus, "------levelStatus");
        console.log(stepStatus, "------stepStatus");
        if (!levelStatus || initial == "editable") {
          formdata.gridlvl = gridlvl;
        }
        if (!stepStatus) {
          formdata.gridpercent = formValue?.gridpercent;
          formdata.gShowP = formValue?.gShowP;
        }
        formValue = { ...formValue, ...formdata };
        setGridElements(formValue);
        // validateErr(formValue);
      }
    }
  }, [PairData, gridpercent, minLow, maxHigh, investamount, low, high]);
  useEffect(() => {
    if (!isEmpty(PairData)) {
      let formValue = GridElements;
      let formErr = valCheck("", "", formValue);
      let errValue = errMsg;
      if (!isEmpty(errValue.invt)) {
        errValue = { ...errValue, ...formErr };
        setErrMsg(errValue);
      } else {
        setErrMsg(formErr);
      }
    }
  }, [low, high, gridpercent, gridlvl, PairData]);
  const validateErr = async (formValue) => {
    let msg = "";
    if (formValue?.gridpercent < PairData.minGirdPrc) {
      msg = "Grid step too low";
    } else if (formValue?.investamount <= 0) {
      msg = "Investment Amount must be greater than 0";
    } else if (formValue?.gridlvl < PairData.minGirdLevel) {
      msg = "Grid level too low";
    } else if (formValue?.gridlvl > PairData.maxGirdLevel) {
      msg = "Grid level too high";
    } else if (formValue?.isSL && formValue.sl <= 0) {
      msg = "Please set Stop Loss greater than 0";
    } else if (formValue?.high < formValue.low) {
      msg = "Low Price Must be Lower than High Price";
    } else if (formValue?.gridlvl > PairData?.maxGirdLevel) {
      msg = `Grid Level Must be Greater than ${PairData?.maxGirdLevel}`;
    } else if (formValue?.isSL && formValue.stopLoss >= formValue.high) {
      msg = `Stop Loss Must be Less than ${formValue.high}`;
    }
    return msg;
  };
  const handleModifyPos = (e) => {
    let formValue = GridElements;
    const { name, checked } = e.target;
    setGridElements({
      ...formValue,
      ...{ [name]: checked },
    });
    // onChange({
    //   ...formValue,
    //   customAmountSetup: inputFields,
    // });
  };
  const handleStartBot = async () => {
    try {
      setLoader(true);
      let pairName = pair.replace(/\-/g, "");

      let reqData = {
        pairName: pairName,
        exchange: exchange,
        BotType: "GENIE",
        low: GridElements.low,
        high: GridElements.high,
        prc: GridElements.gridpercent,
        invt: GridElements.investamount,
        buyInvt: totalBuyInvst,
        sellInvt: totalSellInvst,
        isSL: isSL,
        sl: sl,
        isTP: isTP,
        tpRatio: tpRatio,
      };

      //      setLoader(false);
      const { status, message, error } = await GridOrderPlace(reqData);
      if (status == "success") {
        setLoader(false);
        setIsGridTrade("on");
        // window.location.reload();
        // setistrade("on");
        // setOpenorder(1);
        //fetchStatusTrade();
        fetchTrade();

        toastAlert("success", message, "save");
      } else {
        setLoader(false);
        if (error) {
          setErrMsg(error);
          setIsPreview("off");
        }
        toastAlert("error", message, "error");
        if (error.order) {
          toastAlert("error", error.order, "error");
        }
      }
    } catch (err) {}
  };
  const [ValueOne, setValueOne] = React.useState(25);

  const handleChangeOne = (event, newValue) => {
    setValueOne(newValue);
  };
  const valueLabelFormat = (value) => {
    return `${value}%`;
  };

  const handleCloseicon = (type) => {
    if (type == "create") {
      setClose("on");
    }
  };

  const startBacktest = async () => {
    try {
      setBtLoader(true);

      let formValue = GridElements;
      let msg = await validateErr(formValue);
      if (!isEmpty(msg)) {
        toastAlert("error", msg, "step");
        return;
      }
      const reqData = {
        pa: pair.replace(/\-/g, ""),
        market: "Binance",
        sett: GridElements,
        startDate: startDate(
          new Date(new Date().setDate(new Date().getDate() - 30))
        ),
        endDate: endDate(new Date()),
        tFee: 0.1,
        editable: false,
        exchange: exchange,
      };

      const { status, loading, message, error, result } = await gridBacktest(
        reqData
      );
      if (status == "success") {
        setBtLoader(false);
        setbackstbtn(true);
        setBtData(result?.completedOrders);
        setActBtData(result?.pendingOrders);
        setProfit(result.pData);
        toggleBtModal();
      } else {
        setBtLoader(false);
        setErrMsg(error);
        // toastAlert("error", message, "error");
        // if (error) {
        //   toastAlert("error", error, "error");
        // }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  const updateBTest = async (data) => {
    try {
      const reqData = {
        pa: pair.replace(/\-/g, ""),
        market: "Binance",
        sett: GridElements,
        startDate: startDate(data.startDate),
        endDate: endDate(data.endDate),
        tFee: data.tFee,
        editable: data.editable,
      };

      const { status, message, error, result } = await gridBacktest(reqData);
      if (status == "success") {
        setBtLoader(false);
        setBtData(result?.completedOrders);
        setActBtData(result?.pendingOrders);
        setProfit(result.pData);
        // toggleBtModal();
      } else {
        setBtLoader(false);
        setErrMsg(error);
        //toastAlert("error", message, "error");
        // if (error) {
        //   toastAlert("error", error, "error");
        // }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  return (
    <div className="card trade_card">
      {isPreview == "off" &&
        botData?.crtBot &&
        curBot.action == "create" &&
        closeicon == "off" && (
          <>
            <div className="card-header">
              <h2>
                Create GRID Bot{" "}
                <i
                  class="bi bi-info-circle-fill ms-2"
                  data-tooltip-id="create_grid"
                ></i>{" "}
              </h2>
              <ReactTooltip
                id="create_grid"
                place="bottom"
                content={
                  <div>
                    <p className="mb-2 white">GRID Bot</p>
                    <p className="mb-2">
                      The GRID bot creates multi-level stacks of Sell Limit
                      orders in the Base currency and Buy Limit orders in the
                      Quote currency to profit from the slightest price
                      fluctuations. Better to use it in the sideways market.
                    </p>
                  </div>
                }
              />
              <img
                src={require("../../assets/images/close_icon.png")}
                alt="Icon"
                className="img-fluid cursor"
                onClick={() => handleCloseicon("create")}
              />
            </div>
            <div className="card-body card-body-padding scroll createbot_card">
              {!isEmpty(multiplebot) && (
                <span className="text-danger">
                  Configuration Error : <div> {multiplebot}</div>
                </span>
              )}
              {errMsg && errMsg.invt && (
                <span className="text-danger">
                  Configuration Error : <div> Investment is Not Sufficient</div>
                </span>
              )}
              <ExchangeBalance params={data} updatePair={updatePair} />
              <TradePairs
                params={data}
                updatePair={updatePair}
                pairsAllTrade={pairsAll}
              />

              <div className="createbot_box">
                <div className="inputgrp_box">
                  <InputGroup
                    className={
                      errMsg && errMsg.invt ? "w-100 red_border" : "w-100"
                    }
                  >
                    {" "}
                    {/* className="mb-0 w-100" */}
                    <label className="grey">
                      Investment, {splitOne(pair, "second")}
                    </label>
                    {/* <Form.Control
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            /> */}
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="investamount"
                      autocomplete="off"
                      value={investamount}
                      onChange={handleModify}
                    />
                    <InputGroup.Text id="basic-addon1" className="grey">
                      ~{" "}
                      {toFixedDown(
                        parseFloat(investamount) * parseFloat(usdprice),
                        2
                      )}{" "}
                      USD{" "}
                    </InputGroup.Text>
                  </InputGroup>
                  <span
                    className={
                      errMsg && errMsg.invt
                        ? "text-danger error_msg"
                        : "text-danger"
                    }
                  >
                    {errMsg && errMsg.invt}
                  </span>
                </div>
                <p className="grey text-end mb-4 mt-1">
                  {totalBuyInvst} {PairData?.secondCurrencySymbol} +{" "}
                  {totalSellInvst} {PairData?.firstCurrencySymbol}
                </p>

                <div className="slider_box my-3">
                  <Slider
                    value={slvalue}
                    onChange={handleModify}
                    valueLabelDisplay="on"
                    valueLabelFormat={slvalue + "%"}
                    aria-labelledby="range-slider"
                    name="slvalue"
                  />
                </div>

                <div className="accr_box">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="center">
                        <div className="blue"> Manual adjustment</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex align-items-center gap-2">
                          <div className="w-50">
                            <div className="inputgrp_box">
                              <InputGroup
                                className={
                                  errMsg && errMsg.low
                                    ? "w-100 red_border"
                                    : "w-100"
                                }
                              >
                                <label className="grey">Low price</label>
                                <Form.Control
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  name="low"
                                  autocomplete="off"
                                  value={low}
                                  onChange={handleModify}
                                />
                              </InputGroup>
                              <span
                                className={
                                  errMsg && errMsg.low
                                    ? "text-danger error_msg"
                                    : "text-danger"
                                }
                              >
                                {errMsg && errMsg.low}
                              </span>
                            </div>
                          </div>
                          <div className="w-50">
                            <div className="inputgrp_box">
                              <InputGroup
                                className={
                                  errMsg && errMsg.high
                                    ? "w-100 red_border"
                                    : "w-100"
                                }
                              >
                                <label className="grey">
                                  High price{" "}
                                  {/* <span className="blue_bage">Trailing</span> */}
                                </label>
                                <Form.Control
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  name="high"
                                  autocomplete="off"
                                  value={high}
                                  onChange={handleModify}
                                />
                              </InputGroup>
                              <span
                                className={
                                  errMsg && errMsg.high
                                    ? "text-danger error_msg"
                                    : "text-danger"
                                }
                              >
                                {errMsg && errMsg.high}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 mb-2 gap-2">
                          <div className="w-50">
                            <div className="inputgrp_box">
                              <InputGroup
                                className={
                                  errMsg && errMsg.gridpercent
                                    ? "w-100 red_border"
                                    : "w-100"
                                }
                              >
                                <label className="grey">Grid step, % </label>
                                {/* <Form.Control
                                                        aria-label="Username"
                                                        value={GridElements.gridpercent}
                                                        aria-describedby="basic-addon1"
                                                    /> */}

                                <Form.Control
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  name="gridpercent"
                                  value={gShowP}
                                  autocomplete="off"
                                  onChange={handleModify}
                                />
                              </InputGroup>
                              <span
                                className={
                                  errMsg && errMsg.gridpercent
                                    ? "text-danger error_msg_bottom"
                                    : "text-danger"
                                }
                              >
                                {errMsg && errMsg.gridpercent}
                              </span>
                            </div>
                          </div>
                          <div className="w-50">
                            <div className="inputgrp_box">
                              <InputGroup
                                className={
                                  errMsg && errMsg.gridlvl
                                    ? "w-100 red_border"
                                    : "w-100"
                                }
                              >
                                <label className="grey">
                                  Grid levels ({PairData?.minGirdLevel}-
                                  {PairData?.maxGirdLevel})
                                </label>
                                <Form.Control
                                  aria-label="Username"
                                  name="gridlvl"
                                  value={gridlvl}
                                  autocomplete="off"
                                  aria-describedby="basic-addon1"
                                  onChange={handleModifygridlevel}
                                />
                              </InputGroup>
                              <span
                                className={
                                  errMsg && errMsg.gridlvl
                                    ? "text-danger error_msg_bottom"
                                    : "text-danger"
                                }
                              >
                                {errMsg && errMsg.gridlvl}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="chk_box">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={(e) => handleModifyPos(e)}
                                checked={isSL}
                                value={isSL}
                                name="isSL"
                              />
                              <label
                                class="form-check-label"
                                for="flexSwitchCheckDefault"
                              >
                                <i
                                  class="bi bi-info-circle-fill me-2"
                                  data-tooltip-id="mTool"
                                ></i>
                                Stop Loss
                              </label>
                              <ReactTooltip
                                id="mTool"
                                place="bottom"
                                content={
                                  <div>
                                    <p
                                      className="mb-2"
                                      style={{ color: "white" }}
                                    >
                                      Stop Loss
                                    </p>
                                    <p className="mb-2">
                                      Configure stoploss options to close the
                                      bot when the <br /> price moves against
                                      the chosed direction. If the price
                                      <br /> reaches a specified Stop Loss
                                      level, the bot sells all the <br /> Base
                                      currency used and stops further
                                      operations.
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          {isSL && (
                            <div className="inputgrp_box">
                              <InputGroup className="w-100">
                                <label className="grey">Price </label>
                                <Form.Control
                                  aria-label="Username"
                                  value={sl}
                                  autocomplete="off"
                                  name="sl"
                                  onChange={handleModify}
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                            </div>
                          )}
                        </div>
                        <div className="box">
                          <div className="chk_box">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={(e) => handleModifyPos(e)}
                                checked={isTP}
                                value={isTP}
                                name="isTP"
                              />
                              <label
                                class="form-check-label"
                                for="flexSwitchCheckDefault"
                              >
                                <i
                                  class="bi bi-info-circle-fill me-2"
                                  data-tooltip-id="my-tooltip-4"
                                ></i>
                                Take Profit
                              </label>
                              <ReactTooltip
                                id="my-tooltip-4"
                                place="bottom"
                                content={
                                  <div>
                                    <p
                                      className="mb-2"
                                      style={{ color: "white" }}
                                    >
                                      Take Profit
                                    </p>
                                    <p className="mb-2">
                                      Configure the Take Profit percentage of
                                      the total PNL <br /> to close the bot when
                                      getting a profit. If a specified Take
                                      <br /> Profit level is reached or
                                      exceeded, the bot sells all the <br />{" "}
                                      Base currency used and stops further
                                      operations.
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          {isTP && (
                            <div className="inputgrp_box">
                              <InputGroup className="w-100">
                                <label className="grey">Total PNL, % </label>
                                <Form.Control
                                  aria-label="Username"
                                  value={tpRatio}
                                  autocomplete="off"
                                  name="tpRatio"
                                  onChange={handleModify}
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <p className="bot_preview_title mb-0 mt-3">
                    Available for bot use{" "}
                    <i
                      class="bi bi-info-circle-fill ms-2"
                      data-tooltip-id="my-tooltip-6"
                    ></i>
                  </p>
                  <ReactTooltip
                    id="my-tooltip-6"
                    place="bottom"
                    content={
                      <div>
                        <p class="mb-2">Available for bot use</p>
                        Available balance to&nbsp;run the bot, apart from funds
                        reserved for possible use in&nbsp;other automations.
                      </div>
                    }
                  />
                  <div className="box mt-3">
                    <div class="value">
                      <div>Quote currency</div>
                      <div class="bdr"></div>
                      <div>{initialQuote} USDT</div>
                    </div>
                    <div class="value">
                      <div>Base currency</div>
                      <div class="bdr"></div>
                      <div>
                        {initialBase} {splitOne(pair, "first")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bgcolor">
                <button
                  className="backtest mb-2"
                  onClick={() => startBacktest()}
                  disabled={btLoader}
                >
                  {btLoader && <span>Loading...</span>}
                  {!btLoader && <span>Backtest</span>}
                </button>

                <button
                  disabled={
                    !isEmpty(multiplebot)
                      ? true
                      : errMsg && errMsg.invt
                      ? // investamount &&
                        // parseFloat(initialQuote) < parseFloat(investamount)
                        true
                      : false
                  }
                  onClick={() => handleContinue()}
                  className="continue"
                >
                  Continue
                </button>
              </div>
            </div>
          </>
        )}

      {isPreview == "on" && (
        <PreviewCreateBot
          params={data}
          handleBack={handleBack}
          data={GridElements}
          handleStartBot={handleStartBot}
          loader={loader}
          handleClose={handleClose}
        />
      )}

      {backstbtn && (
        <BacktestModal
          bTModal={bTModal}
          toggleBtModal={toggleBtModal}
          params={data}
          data={btData}
          ActBtData={ActBtData}
          tradedata={GridElements}
          updateBTest={updateBTest}
          pData={pData}
          FeeandDecimal={FeeandDecimal}
        />
      )}
    </div>
  );
}
