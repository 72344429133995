import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header.js";
import Chart from "../components/Chart/Chart";
// import Chart from "../components/Chart-v1/Chart";
import { useParams, useNavigate } from "react-router-dom";
import isEmpty from "../lib/isEmpty.js";
import BotSettings from "../components/BotTrade/BotSettings";
import GridBotSettings from "../components/Grid/BotSettings";
import TradeHistory from "../components/BotTrade/TradeHistory.js";
import {
  getOpenOrdersChart,
  getViewChart,
  getpairssingle,
  setBotStatus,
} from "../actions/Bottrade";
import { setBalanceDataAll } from "../actions/users";
import GridTradeHistory from "../components/Grid/Trade.js";
import { useContext } from "react";
import SocketContext from "../components/Context/SocketContext";
import {
  getFeeAndDecimal,
  //getPriceConversion,
} from "../actions/commonAction.js";
import { toFixedDown } from "../lib/roundOf.js";
import { calPrice, takeStopFee } from "../components/BotTrade/calculation.js";
import GridChart from "../components/GridChart/Chart.js";
import { curBotDetail, openBotApi } from "../actions/gridbot.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const intitalChartdata = {
  stoploss: {},
  takeprofit: {},
  avgData: [],
  avgPrice: {},
  flagData: [],
};

const intitalGridChartdata = {
  gridLevels: [],
  markPrice: "",
  low: 0,
  high: 0,
};

export default function Trade() {
  const { pair, exchange, bot } = useParams();
  const socketContext = useContext(SocketContext);
  const navigate = useNavigate();
  const paramsData = { pair: pair, exchange: exchange, bot: bot };
  const [params, setParamsData] = useState(paramsData);
  const [posId, setPosId] = useState({ id: "" });
  const [chartData, setChartData] = useState(intitalChartdata);
  const [gridChartData, setGridChartData] = useState(intitalGridChartdata);
  const [markPrice, setMarkPrice] = useState("");
  const [markResult, setMarkResult] = useState("");
  const [FeeandDecimal, setFeeandDecimal] = useState({});
  const [balancefetch, setbalancearr] = useState("");
  const [isGridtrade, setIsGridTrade] = useState("");
  const [isfetchTrade, setIsfetchTrade] = useState("");
  const [balancefetchAll, setbalancefetchAll] = useState("");
  const [TotBal, setTotBal] = useState(0);
  const [TotLocked, setTotLocked] = useState(0);
  const [Botstloader, setBotstloader] = useState(false);
  const [BotsettingsInput, setBotsettingsInput] = useState("");
  const [Botclose, setBotclose] = useState("off");
  const [visible, setVisible] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const ctab = queryParams.get("tb");
  // const priceCnvList = useSelector((state) => state.priceConversion);
  const dispatch = useDispatch();

  const symbol = pair.split("-");

  // redux
  const botData = useSelector((state) => state.botStatus);
  const botEdit = useSelector((state) => state.botEdit);
  const botId = useSelector((state) => state.botId);

  useEffect(() => {
    const { exchange, bot, pair } = params;
    const reqData = {
      pair: pair.replace(/\-/g, ""),
      exchange,
      botType: "GENIE",
    };
    if (bot == "dca") {
      // fetchChartData(reqData);
    }
  }, []);

  useEffect(() => {
    if (botData.openStatus) {
      setBotclose("off");
    }
  }, [botData]);

  const fetchChartData = async (reqData) => {
    try {
      const { status, data } = await getOpenOrdersChart(reqData);
      if (status == "success") {
        // console.log("kkkkkk1", data);
        setChartData(data);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const fetchViewChart = async (reqData) => {
    try {
      const { status, data, pId } = await getViewChart(reqData);
      if (status == "success") {
        data.socketrun = "no";
        // console.log("kkkkkk2", data);

        setChartData(data);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    // console.log(botId, "------143");
    socketContext.socket.on("openOrder", (result) => {
      // console.log(result, "-----145");
      // console.log(result._id, "-----146");
      // console.log(botId.positionId, "-----147");
      //ApiBalanceAll("edit");

      if (botId && !isEmpty(botId.positionId) && !isEmpty(result._id)) {
        if (botId.positionId == result._id && result.status == "open") {
          console.log("----150---open");

          dispatch(
            setBotStatus({
              status: true,
              type: "open",
              id: result._id,
              manBuySell: "yes",
            })
          );
          fetchViewChart({
            pair: result.pairName,
            exchange: result.exchange,
            botType: "GENIE",
            orderId: result._id,
            type: "open",
            s: "openOrde",
          });
        } else if (
          botId.positionId == result._id &&
          result.status == "closed"
        ) {
          console.log("----166--closed");
          dispatch(setBotStatus({ status: false, crtBot: false }));
          setBotclose("on");
          setChartData(result);
        }
      }
    });

    return () => {
      socketContext.socket.off("openOrder");
    };
  }, [botId]);

  useEffect(() => {
    // console.log(botId, "------143");
    socketContext.socket.on("BalanceSocketAll", (Socresult) => {
      console.log(Socresult, "----------Socresult");
      let balancearr = [];
      let fcSymbol = symbol[0];
      let type = "socket";
      if (Socresult.exchange == exchange) {
        if (Socresult.message == "Success") {
          let result = Socresult.result;

          if (result.length > 0) {
            setbalancefetchAll(result);
            // console.log(total, "----602");
            setTotBal(Socresult.total);
            setTotLocked(Socresult.totalLocked);

            let sctfilter = result.filter((o) => o.asset === "USDT");
            balancearr.push({ USDT: sctfilter[0]?.free });
            let fcfilter = result.filter((o) => o.asset === fcSymbol);
            balancearr.push({ [fcSymbol]: fcfilter[0]?.free });
            balancearr.push({ type: type });
            setbalancearr(balancearr);
          } else {
            let fcSymbol = symbol[0];
            balancearr.push({ USDT: 0 });
            balancearr.push({ [fcSymbol]: 0 });
            balancearr.push({ type: type });
            setbalancearr(balancearr);
          }
        } else {
          balancearr.push({ USDT: 0 });
          balancearr.push({ [fcSymbol]: 0 });
          balancearr.push({ type: type });
          setbalancearr(balancearr);
        }
      }
    });

    return () => {
      socketContext.socket.off("BalanceSocketAll");
    };
  }, [botId]);

  const updateChart = (newChartData) => {
    if (!isEmpty(newChartData)) {
      // console.log(newChartData, "-----newChartData");
      const reqData = {
        pair: newChartData.pair.replace(/\-/g, ""),
        exchange: newChartData.exchange,
        botType: "GENIE",
        orderId: newChartData.orderId,
        type: newChartData.type,
        botId: newChartData.botId,
        s: "updateChart",
      };
      fetchViewChart(reqData);
      setParamsData(newChartData);
    }
  };
  const socketFetch = (newChartData) => {
    // console.log("----169");
    if (!isEmpty(newChartData)) {
      if (newChartData.type === "open") {
        // console.log(newChartData, "-----newChartData");
        const reqData = {
          pair: newChartData.pair,
          exchange: newChartData.exchange,
          botType: "GENIE",
          orderId: newChartData.orderId,
          type: newChartData.type,
          s: "socketFetch",
        };
        fetchViewChart(reqData);
      } else if (newChartData.type === "history") {
        setChartData({});
      }
    }
  };
  const updatePair = async (data) => {
    if (!isEmpty(data)) {
      let pairName = data.pair.replace(/\-/g, "");
      let pData = {
        pair: data.pair,
        exchange: data.exchange,
        bot: data.bot,
      };
      if (data.bot == "dca") {
        const reqData = {
          pair: pairName,
          exchange: data.exchange,
          botType: "GENIE",
        };
        fetchChartData(reqData);
      } else if (data.bot == "grid") {
        // function
        openBot(data, pairName);
      }
      setParamsData(pData);
    }
  };
  const updateParams = (data) => {
    let pData = {
      pair: data.pair,
      exchange: data.exchange,
      bot: data.bot,
    };
    console.log("--------233");
    setVisible(true);
    setParamsData(pData);
  };
  const updateId = (data) => {
    const reqData = {
      id: data.id,
    };
    setPosId(reqData);
  };
  const openBot = async (data, pairName) => {
    try {
      let reqData = {
        exchange: data.exchange,
      };
      const { status, result, pairData } = await openBotApi(reqData);
      console.log(status, "-----status");
      console.log(pairName, "-----pairName");
      if (status == "success") {
        if (result && result.length > 0) {
          let curBot = result.find((el) => el.pairName === pairName);
          if (curBot) {
            curBotDetail(
              {
                action: "detail",
                ...curBot,
              },
              dispatch
            );
          } else {
            curBotDetail(
              {
                action: "create",
                pairName,
                filled: [],
              },
              dispatch
            );
          }
        } else {
          curBotDetail(
            {
              action: "create",
              pairName,
              filled: [],
            },
            dispatch
          );
        }
      }
    } catch (err) {}
  };
  // Event handler for changes in BotSettings component
  const botSettingHandler = (changes) => {
    let socketrun = "no";
    setBotsettingsInput(changes);
    // console.log(changes, "changeschanges");
    if (botData?.crtBot && ctab != "Dopen" && ctab != "Dhistory") {
      generateLineData(changes, socketrun);
    }
  };

  // Creates a data object for the order line based on input parameters
  async function generateLineData(data, socketrun) {
    let result;
    if (isEmpty(data.investmentAmount) || isEmpty(data.firstbuyAmount)) {
      result = {
        avgCall: [],
        avgPrice: {
          avgPrice: 0,
          amount: 0,
        },
        takeprofit: {
          price: 0,
          amount: 0,
          takeprofitRatio: data.takeprofitRatio,
        },

        stoploss: {
          percentage: 0,
          price: 0,
          amount: 0,
        },
        isstoploss: false,
        flagData: [],
      };
    } else {
      const customSetup = data.customAmountSetup,
        takeProfit = parseFloat(data.takeprofitRatio),
        stopLoss = parseFloat(data.stopLoss),
        isstoploss = data.isstoploss,
        avgData = [],
        filledData = [];

      // const priceCnv = priceCnvList.find(
      //   (item) => item.baseSymbol == symbol[0] && item.convertSymbol == symbol[1]
      // );
      console.log(markPrice, "317----");
      let lastPrice = markPrice;

      filledData.push({
        price: lastPrice,
        quantity: toFixedDown(
          data.firstbuyAmount / lastPrice,
          FeeandDecimal.quantityDecimal
        ),
        orgquantity: toFixedDown(
          data.firstbuyAmount / lastPrice,
          FeeandDecimal.quantityDecimal
        ),
      });

      for (let i = 0; i < customSetup?.length; i++) {
        const item = customSetup[i];
        const storeData =
          (parseFloat(lastPrice) * parseFloat(item.Ratio)) / 100;
        const priceVal = parseFloat(lastPrice) - parseFloat(storeData);
        lastPrice = priceVal;
        avgData.push({
          price: toFixedDown(priceVal, FeeandDecimal.priceDecimal),
          quantity: toFixedDown(item.Buyamount),
          currency: "USDT",
        });
        filledData.push({
          price: toFixedDown(priceVal, FeeandDecimal.priceDecimal),
          quantity: toFixedDown(
            item.Buyamount / priceVal,
            FeeandDecimal.quantityDecimal
          ),
          orgquantity: toFixedDown(
            item.Buyamount / priceVal,
            FeeandDecimal.quantityDecimal
          ),
        });
      }
      // console.log(filledData, "fff1");
      let positionItem = {
        pairName: symbol[0] + symbol[1],
        takeProfit: takeProfit,
        stopLoss: stopLoss,
        filled: filledData,
        exchange,
      };
      const takeData = await takeStopFee(
        positionItem,
        "takeProfit",
        FeeandDecimal
      );
      // console.log(takeData, "fff11");
      const stopData = await takeStopFee(
        positionItem,
        "stopLoss",
        FeeandDecimal
      );
      let avgPrice = calPrice(filledData, "avgorgqty");
      const tpData = calPrice(filledData, "quantity") * markPrice;
      let finalQty = calPrice(filledData, "quantity");
      finalQty = toFixedDown(finalQty, stopData.quantityDecimal);
      let takerCommission = takeData.takerCommission;

      let entryOvalue;
      if (exchange == "BitMart") {
        entryOvalue = calPrice(
          filledData,
          "TotalpriceBitMart",
          takerCommission
        );
      } else {
        entryOvalue = calPrice(filledData, "Totalprice");
      }
      console.log(takeData.price, "1111");

      let exitOvalue = takeData.price * finalQty;
      exitOvalue = exitOvalue - exitOvalue * takerCommission;
      let takeprofitAmt = exitOvalue - entryOvalue;
      let stopLossAmt = stopData.price * finalQty - entryOvalue;
      result = {
        priceDecimal: stopData.priceDecimal,
        avgCall: avgData,
        avgPrice: {
          avgPrice: avgPrice,
          amount: toFixedDown(finalQty, stopData.quantityDecimal),
        },
        takeprofit: {
          price: takeData.status ? takeData.price : 0,
          amount: toFixedDown(takeprofitAmt, stopData.priceDecimal),
          takeprofitRatio: data.takeprofitRatio,
        },

        stoploss: {
          percentage: stopLoss,
          price: data.isstoploss && stopData.status ? stopData.price : 0,
          amount: data.isstoploss
            ? toFixedDown(Math.abs(stopLossAmt), stopData.priceDecimal)
            : 0,
        },
        isstoploss: isstoploss,
        flagData: [],
        socketrun: socketrun,
      };

      // console.log(result, "-------167");
    }

    setChartData(result);
  }

  async function generateLineEditData(chartdata, tp, sl) {
    let positionItem = {
      pairName: symbol[0] + symbol[1],
      takeProfit: parseFloat(tp),
      stopLoss: parseFloat(sl),
      filled: chartdata.flagData,
      exchange,
    };

    const takeData = await takeStopFee(
      positionItem,
      "takeProfit",
      FeeandDecimal
    );
    const stopData = await takeStopFee(positionItem, "stopLoss", FeeandDecimal);

    let finalQty = calPrice(chartdata.flagData, "quantity");
    let entryOvalue = calPrice(chartdata.flagData, "Totalprice");

    let takerCommission = takeData.takerCommission;
    let exitOvalue = takeData.price * finalQty;
    exitOvalue = exitOvalue - exitOvalue * takerCommission;

    let takeprofitAmt = exitOvalue - entryOvalue;

    let stopLossAmt = stopData.price * finalQty - entryOvalue;

    let result;

    result = {
      avgCall: chartdata.avgCall,
      avgPrice: {
        avgPrice: chartdata.avgPrice.avgPrice,
        amount: chartdata.avgPrice.amount,
      },
      takeprofit: {
        price: parseFloat(tp) > 0 ? takeData.price : 0,
        amount: toFixedDown(takeprofitAmt, stopData.priceDecimal),
      },

      stoploss: {
        percentage: parseFloat(sl),
        price: parseFloat(sl) > 0 ? stopData.price : 0,
        amount:
          parseFloat(sl) > 0
            ? toFixedDown(stopLossAmt, stopData.priceDecimal)
            : 0,
      },
      isstoploss: parseFloat(sl) > 0 ? true : false,
      flagData: chartdata.flagData,
    };

    setChartData(result);
  }

  const fetchpairs = async () => {
    const { result, actPair } = await getpairssingle(params);
    if (!result || result[0]?.status != "active") {
      window.location.href = `/trade/${actPair.firstCurrencySymbol}-${actPair.secondCurrencySymbol}/${params.exchange}/${params.bot}`;
    }

    // console.log(result, "redsult single");
    setMarkPrice(result[0]?.markPrice);
  };

  const fetchFeeandDecimal = async () => {
    // console.log(exchange, "exchange", pair, "pair");
    const pairName = pair.replace("-", "");

    let { status, result } = await getFeeAndDecimal(exchange, pairName);
    if (status == "success") {
      // console.log(result, "result098");
      setFeeandDecimal(result);
    }
  };
  const gridChangeHandler = (changes) => {
    // console.log(changes, "-------------changes111");
    if (changes && changes.gridLevels && changes.gridLevels.length > 0) {
      setGridChartData({
        gridLevels: changes.gridLevels,
        markPrice,
        high: changes.high,
        low: changes.low,
      });
    } else {
      setGridChartData(intitalGridChartdata);
    }
  };
  useEffect(() => {
    fetchFeeandDecimal();
  }, [bot, exchange, pair]);
  useEffect(() => {
    // console.log("11111");
    // getPriceConversion(dispatch);
    // if (isEmpty(markPrice)) {
    //   fetchpairs();
    // }

    // socket
    let socketTicker = "socketTicker" + exchange;

    if (ctab == "history" || ctab == "Dhistory") {
      socketContext[socketTicker].off("marketPrice");
    } else {
      socketContext[socketTicker].on("marketPrice", (result) => {
        let pairName = params.pair;
        if (exchange != "BitMart") {
          pairName = params.pair.replace("-", "");
        }
        if (result && result.pairName == pairName) {
          // console.log("422222", result.data.markPrice);
          const latestPrice = result.data.markPrice;
          setMarkPrice(latestPrice);
          setMarkResult(result);
        }
      });
    }
  }, [bot, exchange, pair]);
  useEffect(() => {
    // if (bot && bot == "grid") {
    let socketTicker = "socketTicker" + exchange;
    return () => {
      socketContext[socketTicker].off("marketPrice");
    };
    // }
  }, [bot, exchange, pair]);
  useEffect(() => {
    if (
      botData?.crtBot &&
      bot == "dca" &&
      ctab != "Dopen" &&
      ctab != "Dhistory"
    ) {
      // console.log(BotsettingsInput, "BotsettingsInput");
      let socketrun = "yes";
      generateLineData(BotsettingsInput, socketrun);
    }
  }, [markPrice, botData]);

  useEffect(() => {
    // getPriceConversion(dispatch);
    fetchpairs();
  }, [botData]);

  const updateGridChart = (newChartData) => {
    if (!isEmpty(newChartData)) {
      const reqData = {
        pair: newChartData.pair.replace(/\-/g, ""),
        exchange: newChartData.exchange,
        botType: "GENIE",
        orderId: newChartData.orderId,
        type: newChartData.type,
      };
      // fetchViewChart(reqData);
      // setParamsData(newChartData);
    }
  };

  const fetchTrade = () => {
    setIsfetchTrade(true);
  };

  useEffect(() => {
    fetchTrade();
  }, []);

  useEffect(() => {
    ApiBalanceAll("load");
  }, [pair, exchange, bot]);

  // useEffect(() => {
  //   if (botData.openStatus) {
  //     ApiBalanceAll("pair");
  //   }
  // }, [botData]);

  useEffect(() => {
    if (!isEmpty(botData.manBuySell)) {
      ApiBalanceAll("edit");
    }
  }, [botData]);

  // useEffect(() => {
  //   socketContext.socket.on("openGridSocket", (result) => {
  //     console.log(exchange, "---------610");
  //     if (exchange == result.exchange) {
  //       ApiBalanceAll("edit");
  //     }
  //   });
  // }, [exchange]);
  useEffect(() => {
    socketContext.socket.on("openPositionSocket", (result) => {
      console.log(result, "-------608");
      if (exchange == result.exchange) {
        ApiBalanceAll("edit");
      }
    });
  }, [exchange]);
  const ApiBalanceAll = async (type) => {
    if (type == "load") {
      setBotstloader(true);
    }
    let reqData = {
      exchange: exchange,
    };
    const { status, result, total, totalLocked } = await setBalanceDataAll(
      reqData
    );
    let balancearr = [];

    let fcSymbol = symbol[0];
    console.log(status, "-------695");
    if (status == "success") {
      console.log("-----------696", result);
      if (result.length > 0) {
        setbalancefetchAll(result);
        // console.log(total, "----602");
        setTotBal(total);
        setTotLocked(totalLocked);

        let sctfilter = result.filter((o) => o.asset === "USDT");
        balancearr.push({ USDT: sctfilter[0]?.free });
        let fcfilter = result.filter((o) => o.asset === fcSymbol);
        balancearr.push({ [fcSymbol]: fcfilter[0]?.free });
        balancearr.push({ type: type });
        setbalancearr(balancearr);
      } else {
        console.log("--------710");
        let fcSymbol = symbol[0];
        balancearr.push({ USDT: 0 });
        balancearr.push({ [fcSymbol]: 0 });
        balancearr.push({ type: type });
        setbalancearr(balancearr);
      }
    } else {
      console.log("----719");
      balancearr.push({ USDT: 0 });
      balancearr.push({ [fcSymbol]: 0 });
      balancearr.push({ type: type });
      setbalancearr(balancearr);
    }
    setBotstloader(false);
  };

  useEffect(() => {
    if (
      botEdit.editBot &&
      (botEdit.editBot.eTpRatio > 0 || botEdit.editBot.eStploss > 0)
    ) {
      generateLineEditData(
        chartData,
        botEdit.editBot.eTpRatio,
        botEdit.editBot.eStploss
      );
    }
  }, [botEdit]);
  useEffect(() => {
    if (ctab == "Dhistory" || ctab == "Dopen") {
      setChartData({});
    }
  }, [ctab]);
  console.log(pair, "--------668");
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="trade_wrapper">
        <div className="container-fluid">
          <div className="trade_main">
            <div className="trade_main_wrapper">
              <div className="trade_main_left_top">
                <div className="trade_chart">
                  {/* <div className="chart_mobile_head">
                    <h5>Chart</h5>
                    <i className="bi bi-x chart_close"></i>
                  </div> */}
                  <div className="chart_custom_data">
                    <span className="seperatorf"></span>
                    <p>{exchange}</p>
                    <span className="seperator"></span>
                    <p>{pair.replace(/\-/g, " / ")}</p>
                  </div>
                  {!isEmpty(bot) && bot == "dca" && !Botstloader ? (
                    <Chart
                      params={params}
                      data={chartData}
                      Botstloader={Botstloader}
                      className="img-fluid"
                    />
                  ) : (
                    <GridChart
                      params={params}
                      markPrice={markPrice}
                      posId={posId}
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
              {!isEmpty(bot) && bot == "dca" && (
                <TradeHistory
                  botType={bot}
                  pair={pair}
                  exchange={exchange}
                  updateChartData={updateChart}
                  socketFetch={socketFetch}
                />
              )}

              {!isEmpty(bot) && bot == "grid" && (
                <GridTradeHistory
                  botType={bot}
                  pair={pair}
                  exchange={exchange}
                  updateChartData={updateGridChart}
                  setIsGridTrade={setIsGridTrade}
                  isfetchTrade={isfetchTrade}
                  setIsfetchTrade={setIsfetchTrade}
                  changeGrid={gridChangeHandler}
                  markPrice={markPrice}
                  markResult={markResult}
                  FeeandDecimal={FeeandDecimal}
                  updatePair={updatePair}
                  updateParams={updateParams}
                  updateId={updateId}
                />
              )}

              {!isEmpty(bot) && bot == "grid" && (
                <GridBotSettings
                  params={params}
                  onChange={botSettingHandler}
                  changeGrid={gridChangeHandler}
                  setIsGridTrade={setIsGridTrade}
                  isGridtrade={isGridtrade}
                  fetchTrade={fetchTrade}
                  markPrice={markPrice}
                  markResult={markResult}
                  FeeandDecimal={FeeandDecimal}
                  updatePair={updatePair}
                  balancefetch={balancefetch}
                  balancefetchAll={balancefetchAll}
                  visible={visible}
                  updateParams={updateParams}
                  updateId={updateId}
                />
              )}

              {!isEmpty(bot) && bot == "dca" && (
                <BotSettings
                  params={params}
                  onChange={botSettingHandler}
                  // changeGrid={gridChangeHandler}
                  // setIsGridTrade={setIsGridTrade}
                  // isGridtrade={isGridtrade}
                  // fetchTrade={fetchTrade}
                  updatePair={updatePair}
                  updateChartData={updateChart}
                  balancefetch={balancefetch}
                  balancefetchAll={balancefetchAll}
                  TotBal={TotBal}
                  TotLocked={TotLocked}
                  FeeandDecimal={FeeandDecimal}
                  Botstloader={Botstloader}
                  Botclose={Botclose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
