import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from "react";
import {
  Modal,
  InputGroup,
  Form,
  Accordion,
  Dropdown,
  Button,
} from "react-bootstrap";

import ExchangeBalance from "./ExchangeBalance.js";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Slider from "@mui/material/Slider";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import context
import SocketContext from "../Context/SocketContext";
import GridBot from "../Grid/CreateBot.js";

// import action
import {
  getOpenOrders,
  getBotHistory,
  changeTradeStatus,
  comManBuyOrder,
  getTotQuant,
  getGenTradeSetUser,
  GenieOrderPlace,
  ModifyBot,
  getMarketPrice,
  comManSellOrder,
  getpairs,
  setBotStatus,
  setBotEdit,
  dcaBacktest,
  dcabotValidation,
  getexchangeinfo,
  getlockedAmt,
  setBotId,
  getfetchrunBot,
} from "../../actions/Bottrade";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed, toFixedDown, toPercentage } from "../../lib/roundOf";
import TradePairs from "../../components/BotTrade/TradePairs.js";
import TradePairProfit from "../../components/BotTrade/TradePairProfit.js";

import validation from "./validation";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  dateHours,
  dateTimeFormat,
  dayOnly,
  daysBetweenDates,
  endDate,
  startDate,
  dateHoursclose,
} from "../../lib/dateFilter.js";
import { firstCapital, firstOnCap } from "../../lib/String.js";
import {
  getGridBotHistory,
  // getGridBotOpenOrders,
  setGridBotStatus,
} from "../../actions/gridbot.js";

// import component
import BacktestModal from "./BacktestModal.js";
import { SET_DASHBOARD_EXCHANGE } from "../../constant/index.js";

const Datas = {
  firstbuyAmount: "",
  margincallLimit: 2,
  takeprofitRatio: 2,
  takeprofitCallback: "",
  buyinCallback: "",
  marginCall: "",
  Ratio: "",
  AverageCallDrop: "",
  investmentAmount: "",
  IniinvestmentAmount: "",
  AmountMultiplier: 1.3,
  stepMultiplier: 1.3,
  AvgorderStep: 0.5,
  stopLoss: 1,
  isstoploss: false,
  slvalue: 100,
};

const buySell = {
  buyAmount: 0,
  sellAmount: 0,
  orgPairName: "",
  slvaluemanual: 100,
  lockedAmt: 0,
  buyAmountIn: 0,
};

const columns = [
  {
    name: "Date & Time",
    selector: (row) => dateTimeFormat(row.updatedAt),
    width: "165px",
  },
  {
    name: "Side",
    width: "80px",
    selector: (row) => (
      <span className={row.buyorsell === "buy" ? "greenTxt" : "redText"}>
        {firstCapital(row.buyorsell)}
      </span>
    ),
  },
  {
    name: "Action",
    selector: (row) => row.orderVia,
  },
  {
    name: "Price",
    selector: (row) => toFixedDown(row.price, row.priceDecimal),
    width: "120px",
  },

  {
    name: "Amount",
    width: "170px",
    selector: (row) => (
      <>
        <span>
          {row.orgquantity} {row?.pairName.split("USDT").join("")}
        </span>
      </>
    ),
  },
  {
    name: "Total (USDT)",
    width: "120px",
    selector: (row) => `${toFixed(row.price * row.orgquantity, 4)}`,
  },

  {
    name: "Fee",
    width: "180px",
    selector: (row) => (
      <>
        {row.commissionAsset != "USDT" && (
          <span>
            {row.commission} {row.commissionAsset}
          </span>
        )}
        <div>
          {" "}
          {toFixedDown(row.commissionUSDT, 8)} {"USDT"}
        </div>
      </>
    ),
  },

  {
    name: "Profit (USDT)",
    selector: (row) => (
      <>
        <div className={row.profitpercentage >= 0 ? "greenTxt" : "redText"}>
          {row.buyorsell === "sell"
            ? toFixedDown(row.profitpercentage, 2) + "%"
            : ""}
        </div>
        <span>
          {row.buyorsell === "sell"
            ? toFixedDown(row.profitall, 2) + " USDT"
            : "-"}
        </span>
      </>
    ),
  },
];

export default function GenieSettings(props) {
  const {
    params,
    onChange,
    changeGrid,
    setIsGridTrade,
    isGridtrade,
    fetchTrade,
    updatePair,
    balancefetch,
    balancefetchAll,
    TotBal,
    TotLocked,
    updateChartData,
    Botstloader,
    Botclose,
  } = props;
  console.log(Botclose, "-----Botclose");

  const usdprice = 0.9999;
  const [tradedata, setTradeData] = useState(Datas);
  const [tradedataModify, setTradeDataModify] = useState({});
  const [Istrade, setistrade] = useState();
  const [IsContinue, setiscontinue] = useState("off");
  const [botHistory, setBotData] = useState([]);
  const [Isclosepreivew, setclosepreview] = useState("off");
  const [pData, setProfit] = useState({});
  const [data, setData] = useState("");
  const [BSForm, setBSForm] = useState(buySell);
  const [validateError, setValidateError] = useState({});
  const [buyLoader, setBuyLoader] = useState(false);
  const [modifybutton, setmodifybutton] = useState(false);
  const modModalRef = useRef(null);
  const [botMod, setBotMod] = useState(false);
  const [posPerc, setPosPerc] = useState(0);
  const [totalQuant, setTotQuant] = useState(0);
  const [RunStatus, setRunStatus] = useState();
  const { buyAmount, slvaluemanual, sellAmount, lockedAmt } = BSForm;
  const [Openorder, setOpenorder] = useState(0);
  const [validErr, setValidErr] = useState({});
  const [loader, setLoader] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const socketContext = useContext(SocketContext);
  const [Modifystoploss, setModifystoploss] = useState(false);
  const [botAct, setbotAct] = useState(true);
  const [sellLoader, setSellLoader] = useState(false);
  const [peformanceDetails, setPerformance] = useState({});
  const [btLoader, setBtLoader] = useState(false);
  const [backstbtn, setbackstbtn] = useState(false);
  const [Stopbot, setStopbot] = useState(false);
  const [modifystate, setmodifystate] = useState(false);
  const [modifypreview, setmodifypreview] = useState(false);
  const [multiplebot, setmultiplebot] = useState("");

  const [SellAmount, setSellAmount] = useState();
  // backtest
  const [bTModal, setBTModal] = useState(false);
  const [btData, setBtData] = useState({});

  //pairlist get
  const [PairList, setPairList] = useState({});
  const [PairListTrd, setPairListTrd] = useState({});
  const [notifybotstatus, setnotifybotstatus] = useState("");
  const [modnotifybotstatus, setmodnotifybotstatus] = useState("");

  //gridbot
  const [gridbotMod, setGridBotMod] = useState(false);
  const [GridData, setGridData] = useState([]);

  const [gridOpenOrderData, setGridOpenOrderData] = useState({});
  const [Closecreate, setClosecreate] = useState(false);

  const [ExchangeIn, setExchangeIn] = useState();
  const [EditbotForm, setEditbotForm] = useState(false);

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  let ctab;
  ctab = queryParams.get("tb");
  if (ctab == null) {
    ctab = "open";
  }
  const [selectedValue, setSelectedValue] = useState("Immediately"); // Initialize with a default value
  const handleSelect = (eventKey, event) => {
    setSelectedValue(eventKey);
  };

  const [selectedValue1, setSelectedValue1] = useState("Average Price"); // Initialize with a default value

  let {
    firstbuyAmount,
    margincallLimit,
    takeprofitRatio,
    takeprofitCallback,
    buyinCallback,
    stopLoss,
    // AverageCallDrop,
    investmentAmount,
    AveragingorderAmount,
    AvgorderStep,
    AmountMultiplier,
    stepMultiplier,
    isstoploss,
    slvalue,
    IniinvestmentAmount,
  } = tradedata;

  const handleSelect1 = (eventKey, event) => {
    setSelectedValue1(eventKey);
  };

  // redux
  const botData = useSelector((state) => state.botStatus);
  const botId = useSelector((state) => state.botId);

  const onModHide = async () => {
    setBotMod(false);
  };

  // Toggle backtest modal
  const toggleBtModal = () => {
    setBTModal(!bTModal);
    setbackstbtn(!backstbtn);
  };

  const startBacktest = async () => {
    try {
      setBtLoader(true);
      let cusamt = [];
      let avgordst = "",
        amtmul = "",
        stmul = "";

      if (parseFloat(tradedata.margincallLimit) > 0) {
        avgordst = tradedata.AvgorderStep;
        amtmul = tradedata.AmountMultiplier;
        stmul = tradedata.stepMultiplier;

        let prev_xvalue = 1;
        let xvalue = 1;
        for (let x = 1; x < tradedata.margincallLimit; x++) {
          prev_xvalue = prev_xvalue * tradedata.AmountMultiplier;
          xvalue = xvalue + prev_xvalue;
        }
        let Buyamount = tradedata.AveragingorderAmount / xvalue;
        let Ratio = tradedata.AvgorderStep;
        cusamt.push({
          marcall: 1,
          r: Ratio,
          bamt: Buyamount,
        });

        let prev_amount = Buyamount;
        let prev_ratio = tradedata.AvgorderStep;

        for (let i = 1; i < tradedata.margincallLimit; i++) {
          prev_amount = prev_amount * tradedata.AmountMultiplier;
          prev_ratio = prev_ratio * tradedata.stepMultiplier;

          cusamt.push({
            marcall: 1,
            r: prev_ratio,
            bamt: prev_amount,
          });
        }
      }

      const sett = {
        amt: parseFloat(tradedata.investmentAmount),
        fbuyamt: parseFloat(tradedata.firstbuyAmount),
        avgordamt: parseFloat(tradedata.AveragingorderAmount),
        marcall: tradedata.margincallLimit,
        tprat: tradedata.takeprofitRatio,
        avgcalldrop: tradedata.AverageCallDrop,
        cusamt,
        tfee: 0.1,
      };
      const reqData = {
        tb: 0,
        te: 0,
        pa: params.pair.replace(/\-/g, ""),
        exchange: params.exchange,
        sett,
        issl: tradedata.isstoploss,
        sl: tradedata.isstoploss ? tradedata.stopLoss : 0,
        tp: tradedata.takeprofitRatio,
        avgordst,
        amtmul,
        stmul,
        startDate: startDate(
          new Date(new Date().setDate(new Date().getDate() - 30))
        ),
        endDate: endDate(new Date()),
        editable: false,
      };
      // console.log(reqData, "reqDatabacktest");

      const { status, loading, message, error, result } = await dcaBacktest(
        reqData
      );
      if (status == "success") {
        setBtLoader(false);
        setbackstbtn(true);
        setBtData(result?.orders);
        setProfit(result.pData);
        toggleBtModal();
      } else {
        setBtLoader(false);
        setValidErr(error);
        toastAlert("error", message, "error");
        if (error) {
          toastAlert("error", error, "error");
        }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  const updateBTest = async (data) => {
    try {
      setBtLoader(true);
      let cusamt = [];
      let avgordst = "",
        amtmul = "",
        stmul = "";

      if (parseFloat(tradedata.margincallLimit) > 0) {
        avgordst = tradedata.AvgorderStep;
        amtmul = tradedata.AmountMultiplier;
        stmul = tradedata.stepMultiplier;

        let prev_xvalue = 1;
        let xvalue = 1;
        for (let x = 1; x < tradedata.margincallLimit; x++) {
          prev_xvalue = prev_xvalue * tradedata.AmountMultiplier;
          xvalue = xvalue + prev_xvalue;
        }
        let Buyamount = tradedata.AveragingorderAmount / xvalue;
        let Ratio = tradedata.AvgorderStep;
        cusamt.push({
          marcall: 1,
          r: Ratio,
          bamt: Buyamount,
        });

        let prev_amount = Buyamount;
        let prev_ratio = tradedata.AvgorderStep;

        for (let i = 1; i < tradedata.margincallLimit; i++) {
          prev_amount = prev_amount * tradedata.AmountMultiplier;
          prev_ratio = prev_ratio * tradedata.stepMultiplier;

          cusamt.push({
            marcall: 1,
            r: prev_ratio,
            bamt: prev_amount,
          });
        }
      }

      const sett = {
        amt: parseFloat(tradedata.investmentAmount),
        fbuyamt: parseFloat(tradedata.firstbuyAmount),
        avgordamt: parseFloat(tradedata.AveragingorderAmount),
        marcall: tradedata.margincallLimit,
        tprat: tradedata.takeprofitRatio,
        avgcalldrop: tradedata.AverageCallDrop,
        cusamt,
        tfee: data.tFee,
      };

      const reqData = {
        tb: 0,
        te: 0,
        pa: params.pair.replace(/\-/g, ""),
        exchange: params.exchange,
        sett,
        issl: tradedata.isstoploss,
        sl: tradedata.isstoploss ? tradedata.stopLoss : 0,
        tp: tradedata.takeprofitRatio,
        avgordst,
        amtmul,
        stmul,
        startDate: startDate(data.startDate),
        endDate: endDate(data.endDate),
        editable: data.editable,
      };
      // console.log(reqData, "reqDatabacktest");

      const { status, loading, message, error, result } = await dcaBacktest(
        reqData
      );
      if (status == "success") {
        setBtLoader(false);
        setBtData(result?.orders);
        setProfit(result.pData);
        // toggleBtModal();
      } else {
        setBtLoader(false);
        setValidErr(error);
        toastAlert("error", message, "error");
        if (error) {
          toastAlert("error", error, "error");
        }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };

  const handleStatusChange = async (Runstatus) => {
    try {
      let pairData = params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        BotType: "GENIE",
        exchange: params.exchange,
        Runstatus: Runstatus,
      };
      const { status, message, result } = await changeTradeStatus(reqData);
      if (status == "success") {
        setRunStatus(result.RunStatus);
        toastAlert("success", message, "save");
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {}
  };

  const handleDownload = async () => {
    // Example CSV data
    const csvData = [
      [
        "Date & Time",
        "Pair",
        "Side",
        "Action",
        "Price",
        "Fee",
        "Amount",
        "Total (USDT)",
        "Profit (USDT)",
        "Profit %",
        "Exchange",
      ],
    ];

    let entrypricetot = 0,
      exitpricetot = 0,
      exitpricetot_wof = 0,
      profit = "",
      finalQty = 0,
      totalOrgQty = 0,
      totalPrice = 0,
      investmentPrice = 0,
      totalPrice_wofee = 0,
      combuybnbUsdt = 0,
      comsellbnbusdt = 0,
      profitall = "";
    let index = 0,
      commission,
      commissionAsset,
      finalProfit = "";
    let result = botHistory;
    if (!isEmpty(result) && botHistory.length > 0) {
      for (let item of result) {
        let commission = 0,
          commissionUSDT = 0,
          feeUsdt = 0,
          commissionAsset = "";
        if (item.buyorsell == "buy") {
          if (item.exchange == "BitMart") {
            feeUsdt = parseFloat(item.comissionFee);
          }
          entrypricetot += item.price * item.orgquantity + feeUsdt;

          finalQty += item.quantity;
          totalOrgQty += parseFloat(item.orgquantity);

          investmentPrice += item.orgquantity * item.price + feeUsdt;
          for (let buyfeeItem of item?.feeFilled) {
            if (
              buyfeeItem?.commissionAsset == "BNB" &&
              item.exchange == "Binance"
            ) {
              combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
            }
          }

          totalPrice_wofee += item.quantity * item.price;
          result[index].quantity = item.orgquantity;
        }
        if (item.buyorsell == "sell") {
          // if (combuybnbUsdt > 0) {
          //   exitpricetot += item.price * item.orgquantity;
          // } else {
          //   exitpricetot += item.price * item.quantity;
          // }
          exitpricetot += item.price * item.quantity;
          exitpricetot_wof += item.price * item.orgquantity;
          for (let sellfeeItem of item?.feeFilled) {
            if (
              sellfeeItem?.commissionAsset == "BNB" &&
              item.exchange == "Binance"
            ) {
              comsellbnbusdt += parseFloat(sellfeeItem?.commissionUSDT);
            }
          }
          result[index].quantity = item.quantity;
        }
        if (!isEmpty(item?.feeFilled)) {
          for (let feeitem of item?.feeFilled) {
            commissionAsset = feeitem?.commissionAsset;
            commission += parseFloat(feeitem?.commission);
            commissionUSDT += parseFloat(feeitem?.commissionUSDT);
          }
          result[index].commissionAsset = commissionAsset;
          result[index].commission = commission;
          result[index].commissionUSDT = commissionUSDT;
        } else {
          result[index].commissionAsset = commissionAsset;
          result[index].commission = commission;
          result[index].commissionUSDT = commissionUSDT;
        }

        result[index].comissionFee = parseFloat(item.comissionFee);
        index++;
      }
      let quantityDecimal = result[0]?.quantityDecimal;

      finalQty = toFixedDown(finalQty, quantityDecimal);
      let priceDecimal = result[0].priceDecimal;
      //let priceDecimal = 6;

      if (exitpricetot > 0) {
        let exitovaluepop = 0;
        if (comsellbnbusdt == 0) {
          exitovaluepop =
            exitpricetot - exitpricetot * result[0].takerCommission;
        } else {
          exitovaluepop = exitpricetot - comsellbnbusdt;
        }
        let profitall = exitovaluepop - combuybnbUsdt - investmentPrice;
        let profitpercentage = (profitall / investmentPrice) * 100;
        profitall = toFixedDown(profitall, priceDecimal);

        result[0].profitall = profitall;
        // result[0].profit = profit;
        result[0].profitpercentage = profitpercentage;
      }

      let noofdays = dayOnly(result[0].createdAt, result[0].currentDate);
      let exovaluewfee = 0;
      exovaluewfee = exitpricetot - combuybnbUsdt;
      //with fee
      let finalProfit = exovaluewfee - investmentPrice;
      finalProfit = toFixedDown(finalProfit, priceDecimal);
      let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

      //without fee
      let finalProfit_wofee = exitpricetot_wof - investmentPrice;
      finalProfit_wofee = toFixedDown(finalProfit_wofee, priceDecimal);
      let finalProfitPercentageWo = (finalProfit_wofee / investmentPrice) * 100;
    }

    for (let itemcsv of result) {
      let arrData = [
        dateTimeFormat(itemcsv.updatedAt),
        params.pair,
        firstCapital(itemcsv.buyorsell),
        itemcsv.orderVia,
        itemcsv.price,
        itemcsv.commissionAsset != "USDT"
          ? itemcsv.commission + itemcsv.commissionAsset
          : toFixedDown(itemcsv.commissionUSDT, 8) + "USDT",
        itemcsv.quantity,
        toFixed(itemcsv.price * itemcsv.orgquantity, 4),
        toFixedDown(itemcsv.profitall, 2),
        toFixedDown(itemcsv.profitpercentage, 2),
        params.exchange,
      ];
      csvData.push(arrData);
    }

    // Convert the data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  const fetchTradeSettingUser = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        BotType: "GENIE",
        positionId: botId.positionId ? botId.positionId : botData.positionId,
      };
      // if (!isEmpty(botData.positionId)) {
      //   reqdata.positionId = botData.positionId;
      // }
      const { status, type, result, tradeSettings, botrun } =
        await getGenTradeSetUser(reqdata);

      if (status == "success") {
        let tData = {};
        if (!isEmpty(result)) {
          if (result?.status == "closed") {
            setistrade(botrun);
            if (!isEmpty(result?.Tradesetings?.Tradestrategy[0])) {
              let isstoploss = false;

              if (result.Tradesetings.Tradestrategy[0].stopLoss > 0) {
                isstoploss = true;
              }
              let tradeForm = tradedata;
              tData = result.Tradesetings.Tradestrategy[0];
              tData.isstoploss = isstoploss;
              setTradeData({ ...tradeForm, ...tData });
            }
            return { data: tData, message: "datahistory" };
          } else {
            setistrade("on");
          }
          setData(result);
          setRunStatus(tradeSettings?.RunStatus);
          if (!isEmpty(result?.Tradesetings?.Tradestrategy[0])) {
            let isstoploss = false;

            if (result.Tradesetings.Tradestrategy[0].stopLoss > 0) {
              isstoploss = true;
            }
            let tradeForm = tradedata;
            let oResult = result.Tradesetings.Tradestrategy[0];
            tData = result.Tradesetings.Tradestrategy[0];
            tData.isstoploss = isstoploss;

            setTradeData({ ...tradeForm, ...tData });
            setTradeDataModify(result.Tradesetings.Tradestrategy[0]);
            setModifystoploss(
              result.Tradesetings.Tradestrategy[0].stopLoss > 0 ? true : false
            );
          } else {
            setTradeData({ tData });
          }
        } else {
          setistrade("off");
          return { data: {}, message: "nodata" };
        }
        return { data: tData, message: "data" };
      } else {
        return "nodata";
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleBSChange = async (e) => {
    setEditbotForm(true);
    const { name, value } = e.target;
    let formData = { ...BSForm, ...{ [name]: value } };

    let slvaluemanual = toFixedDown(
      (parseFloat(value) / parseFloat(balancefetch[0]?.USDT)) * 100,
      2
    );

    formData = {
      ...formData,
      ...{ slvaluemanual: slvaluemanual },
    };

    setBSForm(formData);
    setValidateError({});
  };

  const handlemanualBuy = async () => {
    try {
      setbotAct(false);
      setBuyLoader(true);
      let reqData = {
        pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
        amount: buyAmount,
        exchange: params.exchange,
        BotType: "GENIE",
        formType: "buyGenie",
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setBuyLoader(false);
        setbotAct(true);
        toastAlert("error", validateError.buyAmount, "error");

        return;
      }
      setmodnotifybotstatus("inprogress");
      if (modModalRef.current) {
        window.$(modModalRef.current).modal("hide");
      }
      const { status, loading, message, error, result } = await comManBuyOrder(
        reqData
      );
      setBuyLoader(false);
      if (status == "success") {
        setmodnotifybotstatus("success");
        if (botData.status) {
          setnotifybotstatus("");
          setmodnotifybotstatus("");
          dispatch(
            setBotStatus({
              status: true,
              type: "open",
              id: result._id,
              manBuySell: "yes",
            })
          );
        } else {
          setnotifybotstatus("");
          setmodnotifybotstatus("");
          dispatch(setBotStatus({ status: false }));
        }
        updateChartData({
          pair: params.pair,
          exchange: params.exchange,
          botType: "GENIE",
          orderId: result._id,
          type: "open",
          bot: "dca",
        });
        toastAlert("success", message, "save");
        // setBSForm(buySell);
        setistrade("on");
        setbotAct(true);

        let formValuemanualbuy = {
          ...BSForm,
          ...{
            slvaluemanual: 100,
          },
        };

        // console.log(formValuemanualbuy, "formValuemanualbuy");
        setBSForm(formValuemanualbuy);
      } else {
        toastAlert("error", message, "error");
        setmodnotifybotstatus("");
        setBSForm(BSForm);
        setistrade("on");
        setbotAct(true);
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };

  const splitOne = (data, type) => {
    let check = data.split("-");
    if (type == "first") {
      return check[0];
    }
    if (type == "second") {
      return check[1];
    }
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };

  useEffect(() => {
    fetchTradeSetting();
    setmodifystate(false);
    setnotifybotstatus("");
    setmodnotifybotstatus("");
    setmodifybutton(false);
    setClosecreate(false);
    setiscontinue("off");
    fetchrunBot();
  }, [botData, params.exchange, params.bot, params.pair, balancefetch]);

  useEffect(() => {
    setStopbot(false);
  }, [botId]);

  useEffect(() => {
    let DataExchg = {
      firstbuyAmount: "",
      margincallLimit: 2,
      takeprofitRatio: 2,
      takeprofitCallback: "",
      buyinCallback: "",
      marginCall: "",
      Ratio: "",
      AverageCallDrop: "",
      investmentAmount: "",
      IniinvestmentAmount: "",
      AmountMultiplier: 1.3,
      stepMultiplier: 1.3,
      AvgorderStep: 0.5,
      stopLoss: 1,
      isstoploss: false,
      slvalue: 100,
    };
    setTradeData(DataExchg);
  }, [params.exchange, params.pair]);

  const fetchTradeSetting = async () => {
    let res = await fetchTradeSettingUser();
    // console.log(balancefetch, res, "res---560");
    if (!isEmpty(balancefetch) && res) {
      await Balancesetup(res);
    }
    // getexchangeInfo();
  };

  const fetchrunBot = async () => {
    let reqData = {
      exchange: params.exchange,
      pairName: params.pair,
      botCategory: "dca",
    };
    const { status, message } = await getfetchrunBot(reqData);
    if (!status) {
      setmultiplebot(message);
    } else {
      setmultiplebot("");
    }
  };

  useEffect(() => {
    if (botData.status) {
      getBot();
    }

    // socket Marketprice
  }, [botData, params.exchange, params.bot, params.pair]);

  useEffect(() => {
    getexchangeInfo();
  }, [params.exchange, params.pair]);

  const BotorderMoal = async () => {
    setBotMod(true);
    if (botData.status) {
      getBot();
    }
  };

  const getBot = async () => {
    try {
      let reqData = {
        botId: botData.id,
      };
      getBotHistoryCall(reqData);
    } catch (err) {
      console.log(err, "00087");
    }
  };

  const handlemanpos = async () => {
    try {
      let formValuemanualbuy = {
        ...BSForm,
        ...{ buyAmount: BSForm.buyAmountIn, slvaluemanual: 100 },
      };

      setBSForm(formValuemanualbuy);
    } catch (err) {
      console.log(err, "938--");
    }
  };

  const getBotHistoryCall = async (reqData) => {
    try {
      const { status, result, pairDetails } = await getBotHistory(reqData);
      if (status == "success") {
        let entrypricetot = 0,
          exitpricetot = 0,
          exitpricetot_wof = 0,
          profit = "",
          finalQty = 0,
          totalOrgQty = 0,
          totalPrice = 0,
          investmentPrice = 0,
          investmentPricewofee = 0,
          totalPrice_wofee = 0,
          combuybnbUsdt = 0,
          comsellbnbusdt = 0,
          profitall = "";
        let index = 0;
        if (!isEmpty(result) && result.length > 0) {
          for (let item of result) {
            let commission = 0,
              commissionUSDT = 0,
              feeUsdt = 0,
              commissionAsset = "";
            if (item.buyorsell == "buy") {
              if (item.exchange == "BitMart") {
                feeUsdt = parseFloat(item.comissionFee);
              }
              entrypricetot += item.price * item.orgquantity + feeUsdt;

              finalQty += item.quantity;
              totalOrgQty += parseFloat(item.orgquantity);

              investmentPrice += item.orgquantity * item.price + feeUsdt;
              investmentPricewofee += item.orgquantity * item.price;

              for (let buyfeeItem of item?.feeFilled) {
                if (
                  buyfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                }
              }

              totalPrice_wofee += item.quantity * item.price;
            }
            if (item.buyorsell == "sell") {
              // if (combuybnbUsdt > 0) {
              //   exitpricetot += item.price * item.orgquantity;
              // } else {
              //   exitpricetot += item.price * item.quantity;
              // }
              exitpricetot += item.price * item.quantity;
              let totalOrgQtysell = 0;
              for (let item of result) {
                if (item.buyorsell == "buy") {
                  totalOrgQtysell += parseFloat(item.orgquantity);
                }
              }
              exitpricetot_wof += item.price * totalOrgQtysell;
              for (let sellfeeItem of item?.feeFilled) {
                if (
                  sellfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  comsellbnbusdt += parseFloat(sellfeeItem?.commissionUSDT);
                }
              }
            }
            if (!isEmpty(item?.feeFilled)) {
              for (let feeitem of item?.feeFilled) {
                commissionAsset = feeitem?.commissionAsset;
                commission += parseFloat(feeitem?.commission);
                commissionUSDT += parseFloat(feeitem?.commissionUSDT);
              }
              result[index].commissionAsset = commissionAsset;
              result[index].commission = commission;
              result[index].commissionUSDT = commissionUSDT;
            } else {
              result[index].commissionAsset = commissionAsset;
              result[index].commission = commission;
              result[index].commissionUSDT = commissionUSDT;
            }

            result[index].comissionFee = parseFloat(item.comissionFee);
            index++;
          }
          let quantityDecimal = result[0]?.quantityDecimal;
          finalQty = toFixedDown(finalQty, quantityDecimal);
          let priceDecimal = result[0].priceDecimal;
          //let priceDecimal = 6;
          console.log(exitpricetot, "---exitpricetot");
          console.log(investmentPrice, "---investmentPrice");
          console.log(combuybnbUsdt, "---comsellbnbusdt");
          console.log(comsellbnbusdt, "---comsellbnbusdt");
          if (exitpricetot > 0) {
            let exitovaluepop = 0;
            if (comsellbnbusdt == 0) {
              exitovaluepop =
                exitpricetot - exitpricetot * result[0].takerCommission;
            } else {
              exitovaluepop = exitpricetot - comsellbnbusdt;
            }
            let profitall = exitovaluepop - combuybnbUsdt - investmentPrice;
            let profitpercentage = (profitall / investmentPrice) * 100;
            profitall = toFixedDown(profitall, priceDecimal);

            result[0].profitall = profitall;
            // result[0].profit = profit;
            result[0].profitpercentage = profitpercentage;
          }

          let noofdays = dayOnly(result[0].createdAt, result[0].currentDate);
          let exovaluewfee = 0;
          if (botData.type == "history") {
            exovaluewfee = exitpricetot - combuybnbUsdt;
            //with fee
            let finalProfit = exovaluewfee - investmentPrice;
            let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
            finalProfit = toFixedDown(finalProfit, priceDecimal);

            //without fee
            let finalProfit_wofee = exitpricetot_wof - investmentPricewofee;
            let finalProfitPercentageWo =
              (finalProfit_wofee / investmentPricewofee) * 100;
            finalProfit_wofee = toFixedDown(finalProfit_wofee, priceDecimal);

            let avgdailyProfit = toFixed(parseFloat(profit) / noofdays, 2);

            setPerformance({
              pnl: finalProfit,
              pnlval: finalProfitPercentage,
              pnl_wofee: finalProfit_wofee,
              pnlval_wofee: finalProfitPercentageWo,
              orderBc: 0,
              orderQc: 0,
              orderVal: 0,
              invBc: 0,
              invQc: result[0].investmentAmount,
              invVal: result[0].investmentAmount,
              txn: result?.length,
              pairId: result[0].pairId,
              botHistory: result,
              // createdAt: result[0].createdAt,
              createdAt: dateHoursclose(
                result[0].createdAt,
                result[0].updatedAt
              ),
              _id: result[0]._id,
              dcaPrice: toFixedDown(totalPrice_wofee / finalQty, priceDecimal),
              avgdailyProfit: avgdailyProfit,
              avgdailyProfitval: toFixed(
                (avgdailyProfit / result[0].investmentAmount) * 100,
                2
              ),
              priceDecimal: priceDecimal,
            });
          } else if (botData.type == "open") {
            let pairData = pairDetails.find(
              (el) => el._id.toString() == result[0].pairId.toString()
            );
            // if (combuybnbUsdt > 0) {
            //   exovaluewfee = pairData.markPrice * totalOrgQty;
            // } else {
            //   exovaluewfee = pairData.markPrice * finalQty;
            // }
            exovaluewfee = pairData.markPrice * finalQty;
            //with fee
            exovaluewfee = exovaluewfee - combuybnbUsdt;
            let finalProfit = exovaluewfee - investmentPrice;
            let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
            finalProfit = toFixedDown(finalProfit, priceDecimal);

            //witout fee
            let exovaluewofee = pairData.markPrice * totalOrgQty;
            let finalProfit_wofee = exovaluewofee - investmentPricewofee;
            let finalProfitPercentageWo =
              (finalProfit_wofee / investmentPricewofee) * 100;
            finalProfit_wofee = toFixedDown(finalProfit_wofee, priceDecimal);

            let avgdailyProfit = toFixed(parseFloat(finalProfit) / noofdays, 2);

            console.log(exovaluewofee, "sett11");
            console.log(totalOrgQty, "sett22");
            console.log(finalProfit, "sett33");
            console.log(finalProfit_wofee, "sett44");
            console.log(totalOrgQty, "sett55");
            console.log(pairData.markPrice, "sett66");
            console.log(investmentPrice, "sett77");

            // console.log(combuybnbUsdt, "sett13");
            // console.log(investmentPrice, "sett14");
            // console.log(exovaluewfee, "sett15");
            // console.log(finalProfit, "sett16");

            // buy and sell fee
            let TakeprofitPriceOrdervaluebseFee =
              exovaluewfee - exovaluewfee * result[0].takerCommission;

            let finalProfit_bsfee =
              TakeprofitPriceOrdervaluebseFee - investmentPrice;

            let finalProfitPercentage_bs =
              (finalProfit_bsfee / investmentPrice) * 100;

            finalProfit_bsfee = toFixedDown(finalProfit_bsfee, priceDecimal);

            //End

            setPerformance({
              pnl_bs: finalProfit_bsfee,
              pnlval_bs: finalProfitPercentage_bs,
              pnl: finalProfit,
              pnlval: finalProfitPercentage,
              pnl_wofee: finalProfit_wofee,
              pnlval_wofee: finalProfitPercentageWo,
              orderBc: finalQty,
              orderQc: toFixedDown(result[0].investmentAmount - totalPrice, 4),
              orderVal: toFixedDown(entrypricetot, 4),
              invBc: 0,
              invQc: result[0].investmentAmount,
              invVal: result[0].investmentAmount,
              txn: result?.length,
              pairId: result[0].pairId,
              botHistory: result,
              //createdAt: result[0].createdAt,
              createdAt: dateHours(result[0].createdAt),
              _id: result[0]._id,
              dcaPrice: toFixedDown(totalPrice_wofee / finalQty, priceDecimal),
              avgdailyProfit: avgdailyProfit,
              avgdailyProfitval: toFixed(
                (avgdailyProfit / result[0].investmentAmount) * 100,
                2
              ),
              priceDecimal: priceDecimal,
              quantityDecimal: quantityDecimal,
            });
          }
          // console.log(result, "result866----");
          setBotData(result);
        }

        // let index = 0;
        // for (let item of result) {
        //   result[index].firstCurrency = splitOne(params.pair, "first");
        //   result[index].secondCurrency = splitOne(params.pair, "second");

        //   setBotData(result);
        //   index++;
        // }
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };

  const getexchangeInfo = async () => {
    try {
      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pair: pairName,
        exchange: params.exchange,
      };
      const { status, result } = await getexchangeinfo(reqData);
      if (status == "success") {
        setExchangeIn(result?.Exchangeinfo);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };

  const [value, setValue] = React.useState(100);
  const [ValueOne, setValueOne] = React.useState(25);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeOne = (event, newValue) => {
    setValueOne(newValue);
  };

  const valueLabelFormat = (value) => {
    return `${value}%`;
  };

  const handleContinue = () => {
    setiscontinue("on");
    setmodifystate(false);

    // dca-continue-orderplace
  };

  const handleBack = () => {
    setiscontinue("off");
  };

  const handleBackmodify = () => {
    setmodifystate(true);
    setiscontinue("off");
  };

  const handleslideremanual = async (e) => {
    let { name, value } = e.target;
    if (name == "slvaluemanual") {
      let formData = { ...BSForm, ...{ [name]: value } };
      let balanceinEx = parseFloat(balancefetch[0]?.USDT);

      formData = {
        ...formData,
        ...{
          buyAmount: toFixedDown(
            (parseFloat(balanceinEx) * parseFloat(value)) / 100
          ),
        },
      };

      setBSForm(formData);
    }
  };

  const handletradeChange = (e) => {
    setEditbotForm(true);
    let { name, value } = e.target;
    let nameu, valueu, nameus, nameum;
    let formValue = tradedata;

    if (
      name == "firstbuyAmount" ||
      name == "takeprofitRatio" ||
      name == "takeprofitCallback" ||
      name == "buyinCallback" ||
      name == "stopLoss" ||
      name == "investmentAmount" ||
      name == "AveragingorderAmount" ||
      name == "AvgorderStep" ||
      name == "AmountMultiplier" ||
      name == "stepMultiplier" ||
      name == "margincallLimit" ||
      name == "slvalue"
    ) {
      if (/[^0-9.]/.test(value)) return;
      //check dot . length condition

      let valchk = value.toString();
      let matches = valchk.match(/\./g);
      if (matches != null && matches.length >= 2) {
        return;
      }
    }

    if (name == "margincallLimit") {
      // if (!isEmpty(value) && !/^(10|[0-9])$/.test(value)) {
      //   return;
      // }

      if (value == 0 || isEmpty(value)) {
        nameu = "AmountMultiplier";
        formValue = { ...formValue, ...{ [nameu]: 1 } };
        nameus = "stepMultiplier";
        formValue = { ...formValue, ...{ [nameus]: 1 } };
        formValue = { ...formValue, ...{ AveragingorderAmount: 0 } };
        formValue = {
          ...formValue,
          ...{ investmentAmount: formValue.firstbuyAmount },
        };

        let slvalue = toFixedDown(
          (parseFloat(formValue.firstbuyAmount) /
            parseFloat(formValue.IniinvestmentAmount)) *
            100,
          2
        );

        formValue = {
          ...formValue,
          ...{ slvalue: slvalue },
        };

        customAmountSetup(formValue);
      }
    }
    if (name == "slvalue") {
      let inv =
        (parseFloat(formValue.IniinvestmentAmount) * parseFloat(value)) / 100;

      let fbuy = toFixed((inv * 40) / 100, ExchangeIn?.priceDecimal);
      let avgbuy = toFixed((inv * 60) / 100, ExchangeIn?.priceDecimal);

      let nameavgb = "AveragingorderAmount";
      formValue = { ...formValue, ...{ [nameavgb]: avgbuy } };

      let namefb = "firstbuyAmount";
      formValue = { ...formValue, ...{ [namefb]: fbuy } };

      formValue = {
        ...formValue,
        ...{
          investmentAmount: toFixedDown(
            (parseFloat(formValue.IniinvestmentAmount) * parseFloat(value)) /
              100
          ),
        },
      };

      let tppnl =
        (parseFloat(value) * parseFloat(formValue.takeprofitRatio)) / 100;
      formValue = {
        ...formValue,
        ...{ tppnl: toFixedDown(tppnl, 2) },
      };

      let slpnl = (parseFloat(value) * parseFloat(formValue.stopLoss)) / 100;

      formValue = {
        ...formValue,
        ...{ slpnl: toFixedDown(slpnl, 2) },
      };

      setValue(value);
    }
    if (name == "investmentAmount") {
      // valueu = parseFloat(value) - parseFloat(tradedata?.firstbuyAmount);
      // nameu = "AveragingorderAmount";
      // formValue = { ...tradedata, ...{ [nameu]: valueu } };
      let fbuy = toFixed((value * 40) / 100, ExchangeIn?.priceDecimal);
      let avgbuy = toFixed((value * 60) / 100, ExchangeIn?.priceDecimal);

      let str = value.toString();
      let convertStr = str.split(".");

      let nameavgb = "AveragingorderAmount";
      formValue = { ...tradedata, ...{ [nameavgb]: avgbuy } };

      let namefb = "firstbuyAmount";
      formValue = { ...formValue, ...{ [namefb]: fbuy } };

      let nameaq = "margincallLimit";
      formValue = { ...formValue, ...{ [nameaq]: 2 } };

      let tppnl =
        toFixed(parseFloat(value) * parseFloat(formValue.takeprofitRatio)) /
        100;

      formValue = {
        ...formValue,
        ...{ tppnl: toFixedDown(tppnl, 2) },
      };

      let slpnl = (parseFloat(value) * parseFloat(formValue.stopLoss)) / 100;

      formValue = {
        ...formValue,
        ...{ slpnl: toFixedDown(slpnl, 2) },
      };
      let slvalue = toFixedDown(
        (parseFloat(value) / parseFloat(formValue.IniinvestmentAmount)) * 100,
        2
      );
      // console.log(slvalue, "999--5735");

      formValue = {
        ...formValue,
        ...{ slvalue: slvalue },
      };
    } else if (name == "takeprofitRatio") {
      let tppnl =
        (parseFloat(formValue.investmentAmount) * parseFloat(value)) / 100;

      formValue = {
        ...formValue,
        ...{ tppnl: toFixedDown(tppnl, 2) },
      };
    } else if (name == "stopLoss") {
      let slpnl = toFixed(
        (parseFloat(formValue.investmentAmount) * parseFloat(value)) / 100,
        2
      );

      formValue = {
        ...formValue,
        ...{ slpnl: slpnl },
      };
    } else if (name == "firstbuyAmount") {
      let inv = parseFloat(value) + parseFloat(tradedata.AveragingorderAmount);
      let sliderPer = (inv / parseFloat(tradedata.IniinvestmentAmount)) * 100;

      if (value > 0) {
        valueu =
          parseFloat(
            tradedata.AveragingorderAmount ? tradedata.AveragingorderAmount : 0
          ) + parseFloat(value);
        let avgbuy = (valueu * 60) / 100;

        let nameinv = "investmentAmount";
        formValue = {
          ...tradedata,
          ...{
            [nameinv]: valueu,
            slvalue: toFixed(sliderPer, 2),
          },
        };
      } else {
        let nameinv = "investmentAmount";
        formValue = {
          ...tradedata,
          ...{ [nameinv]: "", slvalue: parseFloat(0) },
        };
        formValue = {
          ...formValue,
          ...{ margincallLimit: 2, AveragingorderAmount: 0 },
        };
        //  formValue = { ...formValue, ...{ AveragingorderAmount: 0 } };
      }
    } else if (name == "AveragingorderAmount") {
      console.log(value, "***1464");
      if (isEmpty(value)) {
        value = 0;
      }
      let inv = parseFloat(tradedata.firstbuyAmount) + parseFloat(value);
      let sliderPer = (inv / parseFloat(tradedata.IniinvestmentAmount)) * 100;

      let nameinv = "investmentAmount";

      if (!isEmpty(value) && value != 0) {
        valueu = parseFloat(tradedata.firstbuyAmount) + parseFloat(value);
        formValue = { ...tradedata, ...{ [nameinv]: valueu } };
        let nameaq = "margincallLimit";
        formValue = {
          ...formValue,
          ...{ [nameaq]: 2, slvalue: toFixed(sliderPer, 2) },
        };
      } else {
        let nameaq = "margincallLimit";
        formValue = { ...formValue, ...{ [nameaq]: 0 } };

        formValue = {
          ...formValue,
          ...{
            [nameinv]: tradedata.firstbuyAmount,
            slvalue: toFixed(sliderPer, 2),
          },
        };
      }
    } else if (name == "stepMultiplier" && parseFloat(value) <= 0.99) {
      value = 1;
    } else if (name == "AmountMultiplier" && parseFloat(value) <= 0.99) {
      value = 1;
    }

    if (
      tradedata.investmentAmount &&
      tradedata.firstbuyAmount &&
      tradedata.investmentAmount == tradedata.firstbuyAmount
    ) {
      nameum = "margincallLimit";
      formValue = { ...formValue, ...{ [nameum]: 0 } };

      customAmountSetup(formValue);
    }
    if (
      name == "investmentAmount" ||
      name == "firstbuyAmount" ||
      name == "AveragingorderAmount"
    ) {
      let str = value.toString();
      let convertStr = str.split(".");

      if (convertStr[1] && convertStr[1].length > ExchangeIn?.priceDecimal) {
        return;
      }
    }

    let newTd = { ...formValue, ...{ [name]: value } };
    setTradeData({ ...formValue, ...{ [name]: value } });
    // console.log("newTd", newTd);
    customAmountSetup(newTd);
    if (value) {
      setValidErr({});
      setLoader(false);
    }
  };

  const customAmountSetup = (tradedata) => {
    let customAmountSetup = [];

    if (
      tradedata.AveragingorderAmount <= 0 ||
      isEmpty(tradedata.margincallLimit) ||
      tradedata.margincallLimit == 0
    ) {
      setInputFields(customAmountSetup);
      onChange({
        ...tradedata,
        customAmountSetup,
      });
    } else {
      let prev_xvalue = 1;
      let xvalue = 1;
      for (let x = 1; x < tradedata.margincallLimit; x++) {
        prev_xvalue = prev_xvalue * tradedata.AmountMultiplier;
        xvalue = xvalue + prev_xvalue;
      }

      let Buyamount = tradedata.AveragingorderAmount / xvalue;
      // console.log(xvalue, "---xvalue", tradedata.AveragingorderAmount);
      let Ratio = tradedata.AvgorderStep;
      customAmountSetup.push({
        marginCall: 1,
        Ratio: Ratio,
        Buyamount: Buyamount,
      });

      let prev_amount = Buyamount;
      let prev_ratio = tradedata.AvgorderStep;

      for (let i = 1; i < tradedata.margincallLimit; i++) {
        prev_amount = prev_amount * tradedata.AmountMultiplier;
        prev_ratio = prev_ratio * tradedata.stepMultiplier;

        customAmountSetup.push({
          marginCall: 1,
          Ratio: prev_ratio,
          Buyamount: prev_amount,
        });
      }
      // console.log(customAmountSetup, "customAmountSetup");
      setInputFields(customAmountSetup);
      onChange({
        ...tradedata,
        customAmountSetup,
      });
    }
  };

  const handleIncDec = (name, sign, step) => {
    let formValue = tradedata;

    if (sign == "+" && name == "margincallLimit") {
      margincallLimit = parseFloat(margincallLimit) + parseFloat(step);

      if (margincallLimit >= 1) {
        formValue = { ...formValue, ...{ [name]: margincallLimit } };
        setTradeData({ ...formValue, ...{ [name]: margincallLimit } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(1) } });
        customAmountSetup(formValue);
      }
    } else if (sign == "-" && name == "margincallLimit") {
      margincallLimit = parseFloat(margincallLimit) - parseFloat(step);

      if (margincallLimit >= 1) {
        formValue = { ...formValue, ...{ [name]: margincallLimit } };
        setTradeData({ ...formValue, ...{ [name]: margincallLimit } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(1) } });
        customAmountSetup(formValue);
      }
    } else if (sign == "+" && name == "AvgorderStep") {
      AvgorderStep = parseFloat(AvgorderStep) + parseFloat(step);
      AvgorderStep = toFixed(AvgorderStep, 1);

      if (AvgorderStep >= 0.5) {
        formValue = { ...formValue, ...{ [name]: parseFloat(AvgorderStep) } };
        setTradeData({ ...formValue, ...{ [name]: parseFloat(AvgorderStep) } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.5) } });
        customAmountSetup(formValue);
      }
    } else if (sign == "-" && name == "AvgorderStep") {
      AvgorderStep = parseFloat(AvgorderStep) - parseFloat(step);
      AvgorderStep = toFixed(AvgorderStep, 1);

      if (AvgorderStep >= 0.5) {
        formValue = { ...formValue, ...{ [name]: parseFloat(AvgorderStep) } };
        setTradeData({ ...formValue, ...{ [name]: parseFloat(AvgorderStep) } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.5) } });
        customAmountSetup(formValue);
      }
    } else if (sign == "+" && name == "stopLoss") {
      stopLoss = parseFloat(stopLoss) + parseFloat(step);
      stopLoss = toFixed(stopLoss, 2);

      if (stopLoss > 0) {
        formValue = { ...formValue, ...{ [name]: parseFloat(stopLoss) } };
        let slpnl =
          (parseFloat(formValue.investmentAmount) * parseFloat(stopLoss)) / 100;

        formValue = {
          ...formValue,
          ...{ slpnl: toFixedDown(slpnl, 2) },
        };
        setTradeData({ ...formValue, ...{ [name]: parseFloat(stopLoss) } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.01) } });
        customAmountSetup({ ...formValue, ...{ [name]: parseFloat(0.01) } });
      }
    } else if (sign == "-" && name == "stopLoss") {
      stopLoss = parseFloat(stopLoss) - parseFloat(step);
      stopLoss = toFixed(stopLoss, 2);

      if (stopLoss > 0) {
        formValue = { ...formValue, ...{ [name]: parseFloat(stopLoss) } };
        let slpnl =
          (parseFloat(formValue.investmentAmount) * parseFloat(stopLoss)) / 100;
        formValue = {
          ...formValue,
          ...{ slpnl: toFixedDown(slpnl, 2) },
        };
        setTradeData({ ...formValue, ...{ [name]: parseFloat(stopLoss) } });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.01) } });
        customAmountSetup({ ...formValue, ...{ [name]: parseFloat(0.01) } });
      }
    } else if (sign == "+" && name == "takeprofitRatio") {
      takeprofitRatio = parseFloat(takeprofitRatio) + parseFloat(step);
      takeprofitRatio = toFixed(takeprofitRatio, 2);

      if (takeprofitRatio >= 0.3) {
        formValue = {
          ...formValue,
          ...{ [name]: parseFloat(takeprofitRatio) },
        };

        let tppnl =
          (parseFloat(formValue.investmentAmount) *
            parseFloat(takeprofitRatio)) /
          100;

        formValue = {
          ...formValue,
          ...{ tppnl: toFixedDown(tppnl, ExchangeIn?.priceDecimal) },
        };

        setTradeData({
          ...formValue,
          ...{ [name]: parseFloat(takeprofitRatio) },
        });

        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.3) } });
        customAmountSetup({ ...formValue, ...{ [name]: parseFloat(0.3) } });
      }
    } else if (sign == "-" && name == "takeprofitRatio") {
      takeprofitRatio = parseFloat(takeprofitRatio) - parseFloat(step);
      takeprofitRatio = toFixed(takeprofitRatio, 2);

      if (takeprofitRatio >= 0.3) {
        formValue = {
          ...formValue,
          ...{ [name]: parseFloat(takeprofitRatio) },
        };

        let tppnl =
          (parseFloat(formValue.investmentAmount) *
            parseFloat(takeprofitRatio)) /
          100;

        formValue = {
          ...formValue,
          ...{ tppnl: toFixedDown(tppnl, ExchangeIn?.priceDecimal) },
        };

        setTradeData({
          ...formValue,
          ...{ [name]: parseFloat(takeprofitRatio) },
        });
        customAmountSetup(formValue);
      } else {
        setTradeData({ ...formValue, ...{ [name]: parseFloat(0.3) } });
        customAmountSetup({ ...formValue, ...{ [name]: parseFloat(0.3) } });
      }
    }
  };

  const handlestopLoss = (e) => {
    let formValue = tradedata;
    let name = "isstoploss";
    // let slval = 0;
    // if (e.target.checked) {
    //   slval = 1;
    // }
    formValue = {
      ...formValue,
      ...{ [name]: e.target.checked },
    };

    // formValue = {
    //   ...formValue,
    //   ...{ stopLoss: slval },
    // };

    setTradeData({
      ...formValue,
      ...{ [name]: e.target.checked },
    });
    onChange({
      ...formValue,
      customAmountSetup: inputFields,
    });
  };

  const handlestopLossModify = (e) => {
    let formValue = tradedata;
    let name = "isstoploss";
    formValue = {
      ...formValue,
      ...{ [name]: e.target.checked },
    };
    setTradeData({
      ...formValue,
      ...{ [name]: e.target.checked },
    });
    setModifystoploss(e.target.checked);
  };

  const handleStartBot = async (btntype) => {
    try {
      let tradeArr = [];

      let customAmountSetup = [];
      let avgObj;
      // console.log(tradedata, "-----623");
      if (parseFloat(tradedata.margincallLimit) > 0) {
        avgObj = {
          AvgorderStep: tradedata.AvgorderStep,
          AmountMultiplier: tradedata.AmountMultiplier,
          stepMultiplier: tradedata.stepMultiplier,
        };
        let prev_xvalue = 1;
        let xvalue = 1;
        for (let x = 1; x < tradedata.margincallLimit; x++) {
          prev_xvalue = prev_xvalue * tradedata.AmountMultiplier;
          xvalue = xvalue + prev_xvalue;
        }

        let Buyamount = tradedata.AveragingorderAmount / xvalue;
        // console.log(xvalue, "---xvalue", tradedata.AveragingorderAmount);
        let Ratio = tradedata.AvgorderStep;
        customAmountSetup.push({
          marginCall: 1,
          Ratio: Ratio,
          Buyamount: Buyamount,
        });

        let prev_amount = Buyamount;
        let prev_ratio = tradedata.AvgorderStep;

        for (let i = 1; i < tradedata.margincallLimit; i++) {
          prev_amount = prev_amount * tradedata.AmountMultiplier;
          prev_ratio = prev_ratio * tradedata.stepMultiplier;

          customAmountSetup.push({
            marginCall: 1,
            Ratio: prev_ratio,
            Buyamount: prev_amount,
          });
        }
      } else {
        avgObj = {
          AvgorderStep: "",
          AmountMultiplier: "",
          stepMultiplier: "",
        };
      }

      tradeArr.push({
        ...{
          investmentAmount: tradedata.investmentAmount,
          firstbuyAmount: tradedata.firstbuyAmount,
          AveragingorderAmount: tradedata.AveragingorderAmount,
          margincallLimit: tradedata.margincallLimit,
          takeprofitRatio: tradedata.takeprofitRatio,
          AverageCallDrop: tradedata.AverageCallDrop,
          customAmountSetup: customAmountSetup,
          isstoploss: tradedata.isstoploss,
          stopLoss: tradedata.isstoploss ? tradedata.stopLoss : 0,
        },
        ...avgObj,
      });

      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pairName: pairName,
        exchange: params.exchange,
        BotType: "GENIE",
        RunStatus,
        tradesetting: tradeArr,
      };

      if (btntype == "continue") {
        const { message, error } = await dcabotValidation(reqData);

        if (!isEmpty(error)) {
          setValidErr(error);
          toastAlert("error", message, "error");
          if (error.order) {
            toastAlert("error", error.order, "error");
          }
        } else {
          setiscontinue("on");
          setmodifystate(false);
        }
      } else if (btntype == "start") {
        setnotifybotstatus("inprogress");
        setLoader(true);

        const { status, message, error, result } = await GenieOrderPlace(
          reqData
        );
        console.log(error, "-----error");
        if (status == "success") {
          setnotifybotstatus("success");

          setnotifybotstatus("");
          setmodnotifybotstatus("");

          dispatch(
            setBotId({
              positionId: result._id,
            })
          );

          dispatch(
            setBotStatus({
              status: true,
              type: "open",
              crtBot: false,
              id: result._id,
              manBuySell: "yes",
            })
          );

          setLoader(false);
          setistrade("on");
          setOpenorder(1);
          setRunStatus("Start");
          //fetchStatusTrade();
          setiscontinue("off");
          toastAlert("success", message, "save");

          updateChartData({
            pair: params.pair,
            exchange: params.exchange,
            botType: "GENIE",
            orderId: result._id,
            type: "open",
            bot: "dca",
          });
        } else {
          setLoader(false);
          setValidErr(error);
          toastAlert("error", message, "error");
          setnotifybotstatus("");

          if (error.order) {
            toastAlert("error", error.order, "error");
          }
        }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };

  const handleModifyBot = async () => {
    try {
      // console.log(botData.status, "--botData.status");

      setLoader(true);
      setbotAct(false);
      setmodifybutton(true);
      let tradeArr = [];
      let customAmountSetup = [];
      let avgObj;
      // console.log(tradedata, "-----623");
      if (parseFloat(tradedataModify.margincallLimit) > 0) {
        avgObj = {
          AvgorderStep: tradedataModify.AvgorderStep,
          AmountMultiplier: tradedataModify.AmountMultiplier,
          stepMultiplier: tradedataModify.stepMultiplier,
        };
        let prev_xvalue = 1;
        let xvalue = 1;
        for (let x = 1; x < tradedataModify.margincallLimit; x++) {
          prev_xvalue = prev_xvalue * tradedataModify.AmountMultiplier;
          xvalue = xvalue + prev_xvalue;
        }

        let Buyamount = tradedataModify.AveragingorderAmount / xvalue;
        // console.log(xvalue, "---xvalue", tradedataModify.AveragingorderAmount);
        let Ratio = tradedataModify.AvgorderStep;
        customAmountSetup.push({
          marginCall: 1,
          Ratio: Ratio,
          Buyamount: Buyamount,
        });

        let prev_amount = Buyamount;
        let prev_ratio = tradedataModify.AvgorderStep;

        for (let i = 1; i < tradedataModify.margincallLimit; i++) {
          prev_amount = prev_amount * tradedataModify.AmountMultiplier;
          prev_ratio = prev_ratio * tradedataModify.stepMultiplier;

          customAmountSetup.push({
            marginCall: 1,
            Ratio: prev_ratio,
            Buyamount: prev_amount,
          });
        }
      } else {
        avgObj = {
          AvgorderStep: "",
          AmountMultiplier: "",
          stepMultiplier: "",
        };
      }

      tradeArr.push({
        ...{
          investmentAmount: tradedataModify.investmentAmount,
          firstbuyAmount: tradedataModify.firstbuyAmount,
          AveragingorderAmount: tradedataModify.AveragingorderAmount,
          margincallLimit: tradedataModify.margincallLimit,
          takeprofitRatio: tradedata.takeprofitRatio,
          AverageCallDrop: tradedataModify.AverageCallDrop,
          customAmountSetup: customAmountSetup,
          isstoploss: tradedata.isstoploss,
          stopLoss: tradedata.isstoploss ? tradedata.stopLoss : 0,
        },
        ...avgObj,
      });

      let reqData = {
        pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
        positionId: botData.id,
        exchange: params.exchange,
        BotType: "GENIE",
        tradesetting: tradeArr,
      };

      const { status, loading, message, error, result } = await ModifyBot(
        reqData
      );
      setLoader(false);
      if (status == "success") {
        toastAlert("success", message, "save");
        setbotAct(true);
        setmodnotifybotstatus("success");
        await new Promise((resolve) => setTimeout(resolve, 6000));
        // console.log(botData.status, "xxd--botData.status");

        if (botData.status) {
          setnotifybotstatus("");
          setmodnotifybotstatus("");
          setmodifybutton(false);
          dispatch(
            setBotStatus({ status: true, type: "open", id: result._id })
          );
        } else {
          setnotifybotstatus("");
          setmodnotifybotstatus("");
          setmodifybutton(false);
          dispatch(setBotStatus({ status: false }));
        }

        updateChartData({
          pair: params.pair,
          exchange: params.exchange,
          botType: "GENIE",
          orderId: result._id,
          type: "open",
          bot: "dca",
        });

        setiscontinue("off");
      } else {
        setmodnotifybotstatus("");
        setValidErr(error);
        toastAlert("error", message, "error");
        setbotAct(true);
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const handlestopbot = async (status) => {
    if (!status) {
      dispatch(setBotStatus({ status: true }));
    } else {
      dispatch(setBotStatus({ status: false }));
    }
    setStopbot(status);

    if (status) {
      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pair: pairName,
        botType: "GENIE",
        exchange: params.exchange,
      };
      const { status, result } = await getTotQuant(reqData);
      if (status == "success") {
        let finalQty = 0;
        if (!isEmpty(result)) {
          for (let item of result?.filled) {
            finalQty += item.quantity;
          }
          setSellAmount(finalQty);
        } else {
          setStopbot(false);
        }
      }
    }
  };

  const handlemodifystate = async (status) => {
    setmodifystate(status);
    setmodifybutton(true);

    // dispatch(setBotStatus({ status: false }));
  };

  const handleSellSubmit = async () => {
    try {
      setSellLoader(true);

      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pair: pairName,
        botType: "GENIE",
        exchange: params.exchange,
      };
      const { status, result } = await getTotQuant(reqData);
      if (status == "success") {
        let finalQty = 0;
        for (let item of result.filled) {
          finalQty += item.quantity;
        }

        let reqDatasell = {
          pairName: pairName,
          amount: finalQty,
          exchange: params.exchange,
          BotType: "GENIE",
          formType: "sellGenie",
        };

        let validationError = validation(reqDatasell);
        if (!isEmpty(validationError)) {
          setValidateError(validationError);
          setSellLoader(false);
          return;
        }
        const { status, message } = await comManSellOrder(reqDatasell);
        if (status == "success") {
          setStopbot(false);
          setSellLoader(false);
          setistrade("off");
          toastAlert("success", message, "save");
          dispatch(
            setBotStatus({
              status: false,
              crtBot: false,
              manBuySell: "yes",
            })
          );
          updateChartData({
            pair: params.pair,
            exchange: params.exchange,
            botType: "GENIE",
            orderId: "",
            type: "open",
            bot: "dca",
          });
          fetchTradeSetting();
        } else {
          setSellLoader(false);
          toastAlert("error", message, "error");
        }
      }
    } catch (err) {
      console.log(err, "-------951");
    }
  };

  const fetchpairs = async (currency) => {
    let { result } = await getpairs({
      exchange: params.exchange,
      bot: params.bot == "dca" ? "Genie" : "Grid",
      pairName: splitData(params.pair),
      currency: currency,
    });
    var resp1 = [...result];
    var resp2 = [...result];

    var alphaSort = resp2?.sort((a, b) =>
      a.firstCurrencySymbol < b.firstCurrencySymbol
        ? -1
        : a.firstCurrencySymbol > b.firstCurrencySymbol
        ? 1
        : 0
    );

    if (params.bot == "dca") {
      var numbersort = resp1?.sort(
        (a, b) =>
          parseFloat(b.dcaProfit?.thirtyDays) -
          parseFloat(a.dcaProfit?.thirtyDays) // desc
      );
      setPairList([...numbersort]);
    }

    setPairListTrd([...alphaSort]);
  };

  const Balancesetup = async (res) => {
    try {
      let tData = res.data,
        dt = res.message;
      console.log(dt, "2173kkkk");
      console.log(balancefetch, "2184kkkk");

      let formValue = dt == "nodata" ? Datas : tData;

      let balanceinEx = balancefetch[0]?.USDT;
      let type = !EditbotForm ? "load" : balancefetch[2]?.type;
      const { result } = await getlockedAmt({
        exchange: params.exchange,
        bot: params.bot == "dca" ? "Genie" : "Grid",
      });

      let lockedAmt = result.lockedAmt;
      balanceinEx = parseFloat(balanceinEx) - parseFloat(lockedAmt);

      if (dt == "nodata") {
        if (type == "load" || type == "socket") {
          formValue = {
            ...formValue,
            ...{
              IniinvestmentAmount: toFixedDown(
                balanceinEx,
                ExchangeIn?.priceDecimal
              ),
            },
          };
          formValue = {
            ...formValue,
            ...{
              investmentAmount: toFixedDown(
                balanceinEx,
                ExchangeIn?.priceDecimal
              ),
            },
          };
          let tppnl = toFixed(
            (parseFloat(formValue.investmentAmount) *
              parseFloat(formValue.takeprofitRatio)) /
              100,
            2
          );

          formValue = {
            ...formValue,
            ...{ tppnl: tppnl },
          };

          let slpnl = toFixed(
            (parseFloat(formValue.investmentAmount) *
              parseFloat(formValue.stopLoss)) /
              100,
            2
          );

          formValue = {
            ...formValue,
            ...{ slpnl: slpnl },
          };

          formValue = {
            ...formValue,
            ...{ slvalue: 100 },
          };

          let fbuy = toFixed(
            (balanceinEx * 40) / 100,
            ExchangeIn?.priceDecimal
          );
          let avgbuy = toFixed(
            (balanceinEx * 60) / 100,
            ExchangeIn?.priceDecimal
          );

          formValue = { ...formValue, ...{ firstbuyAmount: fbuy } };
          formValue = { ...formValue, ...{ AveragingorderAmount: avgbuy } };
          customAmountSetup(formValue);
        } else {
          formValue = {
            ...formValue,
            ...{
              IniinvestmentAmount: toFixedDown(
                balanceinEx,
                ExchangeIn?.priceDecimal
              ),
            },
          };

          formValue = {
            ...formValue,
            ...{
              investmentAmount: toFixedDown(
                investmentAmount,
                ExchangeIn?.priceDecimal
              ),
            },
          };

          let tppnl = toFixed(
            (parseFloat(investmentAmount) * parseFloat(takeprofitRatio)) / 100,
            2
          );

          formValue = {
            ...formValue,
            ...{ tppnl: tppnl },
          };

          let slpnl = toFixed(
            (parseFloat(investmentAmount) * parseFloat(stopLoss)) / 100,
            2
          );

          formValue = {
            ...formValue,
            ...{ slpnl: slpnl },
          };

          formValue = {
            ...formValue,
            ...{ slvalue: parseFloat(slvalue) },
          };

          let fbuy = toFixed(
            (investmentAmount * 40) / 100,
            ExchangeIn?.priceDecimal
          );
          let avgbuy = toFixed(
            (investmentAmount * 60) / 100,
            ExchangeIn?.priceDecimal
          );

          formValue = { ...formValue, ...{ firstbuyAmount: fbuy } };
          formValue = { ...formValue, ...{ AveragingorderAmount: avgbuy } };
          customAmountSetup(formValue);
        }
      }
      setTradeData(formValue);
      let formValuemanualbuy;
      if (type == "load" || type == "socket") {
        formValuemanualbuy = {
          ...BSForm,
          ...{ buyAmount: balanceinEx, buyAmountIn: balanceinEx },
        };
      } else {
        formValuemanualbuy = {
          ...BSForm,
          ...{ buyAmount: buyAmount, buyAmountIn: balanceinEx },
        };
      }

      setBSForm(formValuemanualbuy);
    } catch (err) {
      console.log(err, "--------1611");
    }
  };

  useEffect(() => {
    fetchpairs("USDT");
    setStopbot(false);
  }, [params.pair, params.exchange, params.bot]);

  useEffect(() => {
    if (botData.status) {
      setmodifystate(false);
      setiscontinue("off");
    }
  }, [balancefetch, botData]);

  useEffect(() => {
    console.log(Botclose, "------2346");
    if (Botclose == "on") {
      console.log(Botclose, "------2352");
      setStopbot(false);
    }
  }, [Botclose]);

  useEffect(() => {
    if (modifystate) {
      let formValue = tradedata;
      let eTpRatio = formValue.takeprofitRatio;
      let eStploss = formValue.stopLoss;

      dispatch(
        setBotEdit({
          editBot: { eTpRatio: eTpRatio, eStploss: eStploss },
        })
      );
    }
  }, [takeprofitRatio, stopLoss]);

  useEffect(() => {
    socketContext.socket.on("runningOrder", (result) => {
      let pairName = params.pair.replace(/\-/g, "");

      let checkRun = result.data.find((el) => el.pairName == pairName);
      if (!checkRun) {
        setistrade("off");
        setiscontinue("off");
      }
    });
  }, [params.pair, params.exchange]);

  useEffect(() => {
    let socketTicker = "socketTicker" + params.exchange;
    if (ctab == "history" || ctab == "Dhistory") {
      socketContext[socketTicker].off("marketPrice");
    } else {
      if (botHistory.length > 0) {
        socketContext[socketTicker].on("marketPrice", (result) => {
          setPerformance((item) => {
            let performanceList = {};
            // console.log(item.pairId, "------pairId");
            // console.log(result.pairId, "------result.pairId");
            if (item.pairId == result.pairId) {
              let botResult = item.botHistory;

              let finalQty = 0,
                feeUsdt = 0,
                combuybnbUsdt = 0,
                totalOrgQty = 0,
                investmentPrice = 0,
                investmentPricewofee = 0;

              for (let item of botResult) {
                if (item.buyorsell == "buy") {
                  if (result.data.exchange == "BitMart") {
                    feeUsdt = parseFloat(item.comissionFee);
                  }
                  finalQty += item.quantity;
                  totalOrgQty += item.orgquantity;
                  investmentPrice += item.orgquantity * item.price + feeUsdt;
                  investmentPricewofee += item.orgquantity * item.price;

                  if (!isEmpty(item?.feeFilled) && item?.feeFilled.length > 0) {
                    for (let buyfeeItem of item?.feeFilled) {
                      if (
                        buyfeeItem?.commissionAsset == "BNB" &&
                        result.data.exchange == "Binance"
                      ) {
                        combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                      }
                    }
                  }
                }
              }
              let quantityDecimal = botResult[0]?.quantityDecimal;
              finalQty = toFixedDown(finalQty, quantityDecimal);
              let priceDecimal = botResult[0].priceDecimal;
              let takerCommission = botResult[0].takerCommission;
              let exovaluewfee = 0;
              // if (combuybnbUsdt > 0) {
              //   exovaluewfee = result.data.markPrice * totalOrgQty;
              // } else {
              //   exovaluewfee = result.data.markPrice * finalQty;
              // }
              exovaluewfee = result.data.markPrice * finalQty;
              //with fee
              exovaluewfee = exovaluewfee - combuybnbUsdt;
              let finalProfit = exovaluewfee - investmentPrice;
              let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
              finalProfit = toFixedDown(finalProfit, priceDecimal);

              //witout fee
              let exovaluewofee = result.data.markPrice * totalOrgQty;
              let finalProfit_wofee = exovaluewofee - investmentPricewofee;
              let finalProfitPercentagewo =
                (finalProfit_wofee / investmentPricewofee) * 100;
              finalProfit_wofee = toFixedDown(finalProfit_wofee, priceDecimal);

              console.log(exovaluewofee, "setsock11");
              console.log(totalOrgQty, "setsock22");
              console.log(finalProfit, "setsock33");
              console.log(finalProfit_wofee, "setsock44");
              console.log(totalOrgQty, "setsock55");
              console.log(result.data.markPrice, "setsock66");
              console.log(investmentPrice, "setsock77");
              console.log(combuybnbUsdt, "setsock88");
              console.log(finalProfitPercentage, "setsock99");
              console.log(finalProfitPercentagewo, "setsock10");

              // Buy sell fee
              let TakeprofitPriceOrdervalue_bs =
                exovaluewfee - exovaluewfee * takerCommission;
              let finalProfit_bs =
                TakeprofitPriceOrdervalue_bs - investmentPrice;
              let finalProfitPercentage_bs =
                (finalProfit_bs / investmentPrice) * 100;
              finalProfit_bs = toFixedDown(finalProfit_bs, priceDecimal);

              console.log(TakeprofitPriceOrdervalue_bs, "setsock111");
              console.log(finalProfit_bs, "setsock112");
              console.log(finalProfitPercentage_bs, "setsock113");
              console.log(takerCommission, "setsock114");

              let noofdays = dayOnly(
                botResult[0].createdAt,
                botResult[0].currentDate
              );

              let avgdailyProfit = toFixed(
                parseFloat(finalProfit) / noofdays,
                2
              );

              performanceList = {
                ...item,
                pnl_bs: finalProfit_bs,
                pnlval_bs: finalProfitPercentage_bs,
                pnl: finalProfit,
                pnlval: finalProfitPercentage,
                pnl_wofee: finalProfit_wofee,
                pnlval_wofee: finalProfitPercentagewo,
                orderBc: finalQty,
                orderQc: toFixedDown(
                  botResult[0].investmentAmount - investmentPrice,
                  4
                ),
                orderVal: toFixedDown(investmentPrice, 4),
                invBc: 0,
                invQc: botResult[0].investmentAmount,
                invVal: botResult[0].investmentAmount,
                txn: botResult?.length,
                createdAt: dateHours(botResult[0].createdAt),
                _id: botResult[0]._id,
                avgdailyProfit: avgdailyProfit,
                avgdailyProfitval: toFixed(
                  (avgdailyProfit / botResult[0].investmentAmount) * 100,
                  2
                ),
              };
            } else {
              // console.log("2189---", item);
              performanceList = item;
            }
            return performanceList;
          });
        });
      }
    }
  }, [params.pair, params.exchange, ctab, botHistory]);

  const [activeTab, setActiveTab] = useState("tab1");

  // Function to handle tab click
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [activeTab1, setActiveTab1] = useState("tab1");

  // Function to handle tab click
  const handleTabClick1 = (tabId) => {
    setActiveTab1(tabId);
  };

  const pairData = (data) => {
    let originalData = data.split("-");
    return originalData[0] + originalData[1];
  };

  const handleCloseicon = (type) => {
    if (type == "create") {
      //dispatch(setBotStatus({ status: false }));
      setClosecreate(true);
    } else if (type == "preview") {
      setclosepreview("on");
      setiscontinue("off");
    } else if (type == "details") {
      dispatch(setBotStatus({ status: false }));
    } else if (type == "modify") {
      setmodifystate(false);
      dispatch(setBotStatus({ status: true }));
    } else if (type == "stop") {
      setStopbot(false);
    }
  };

  // console.log(tradedata, "--------1754");
  return (
    <>
      <div className="trade_main_right trade_main_right_createbot">
        {/* Modify Bot Div */}
        {modifystate && (
          <div className="modify_bot_wrap">
            <div className="trade_main_settings cardheadbg">
              <div className="card trade_card">
                <div className="card-header">
                  <h2>
                    Modify DCA Bot{" "}
                    <i
                      class="bi bi-info-circle-fill ms-2"
                      data-tooltip-id="create_dca"
                    ></i>{" "}
                  </h2>
                  <ReactTooltip
                    id="create_dca"
                    place="bottom"
                    content={
                      <div>
                        <p className="mb-2">DCA Bot</p>
                        <p className="mb-2">
                          The Dollar-Cost Averaging bot multiplies earns and
                          reduces risks in&nbsp;volatile markets. Use
                          it&nbsp;on&nbsp;uptrends and downtrends.
                        </p>
                      </div>
                    }
                  />
                  <img
                    src={require("../../assets/images/close_icon.png")}
                    alt="Icon"
                    className="img-fluid cursor"
                    onClick={() => handleCloseicon("modify")}
                  />
                </div>
                <div className="card-body card-body-padding scroll createbot_card">
                  <ExchangeBalance params={params} updatePair={updatePair} />
                  <TradePairs
                    params={params}
                    pairsAllTrade={PairListTrd}
                    updatePair={updatePair}
                  />

                  <div className="createbot_box">
                    <p className="bot_preview_title pt-2 modify_disabled">
                      <img
                        src={require("../../assets/images/lock.png")}
                        alt="Icon"
                        className="img-fluid lock_icon me-1"
                      />
                      Strategy
                      <i
                        class="bi bi-info-circle-fill ms-2"
                        data-tooltip-id="my-tooltip-1"
                      ></i>
                    </p>
                    <ReactTooltip
                      id="my-tooltip-1"
                      place="bottom"
                      content={
                        <div>
                          <p className="mb-2">
                            Choose your bot trading strategy:
                          </p>
                          <p className="mb-2">
                            <b>Long</b>&nbsp;— the bot first buys the Base
                            currency and then profits from selling it&nbsp;when
                            its price rises.
                          </p>
                        </div>
                      }
                    />

                    <div className="t_box mb-4 modify_disabled">Long</div>
                    <div className="inputgrp_box mb-4 modify_disabled">
                      <InputGroup className="mb-3 w-100">
                        <label className="grey">
                          Investment, {splitOne(params.pair, "second")}
                        </label>
                        <Form.Control
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="investmentAmount"
                          value={toFixedDown(
                            tradedataModify?.investmentAmount <= 0
                              ? 0
                              : tradedataModify?.investmentAmount,
                            ExchangeIn?.priceDecimal
                          )}
                        />

                        <InputGroup.Text id="basic-addon1" className="grey">
                          ≈{" "}
                          {toFixedDown(
                            parseFloat(tradedataModify?.investmentAmount) *
                              parseFloat(usdprice),
                            2
                          )}
                          USD{" "}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="text-danger">
                        {validErr && validErr.investmentAmount}
                      </span>
                    </div>

                    {/* <div className="slider_box my-3 modify_disabled">
                      <Slider
                        value={tradedata.slvalue}
                        valueLabelDisplay="on"
                        valueLabelFormat={tradedata.slvalue + "%"}
                        aria-labelledby="range-slider"
                        name="slvalue"
                      />
                    </div> */}

                    <div className="accr_box">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="center">
                            <div className="blue"> Manual adjustment</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion>
                              <Accordion.Item className="mb-3" eventKey="1">
                                <Accordion.Header>
                                  {" "}
                                  <div> Bot settings</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="box mb-3">
                                    <div className="box_head">
                                      <i
                                        class="bi bi-info-circle-fill me-2"
                                        data-tooltip-id="my-tooltip-2"
                                      ></i>
                                      Bot start conditions
                                    </div>
                                    <ReactTooltip
                                      id="my-tooltip-2"
                                      place="bottom"
                                      content={
                                        <div>
                                          Your DCA bot starts to&nbsp;operate
                                          by&nbsp;placing the Base order. <br />
                                          Here you can configure its options:
                                          the amount invested,
                                          <br /> placement conditions, and type
                                          of&nbsp;Base order.
                                        </div>
                                      }
                                    />
                                    <div className="drpdwn_box">
                                      <Dropdown
                                        onSelect={handleSelect}
                                        className="w-100"
                                      >
                                        <Dropdown.Toggle id="dropdown-basic">
                                          <span className="exchange">
                                            Place base order
                                          </span>
                                          {selectedValue}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item eventKey="Immediately">
                                            Immediately
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item eventKey="By indicator signal">
                                      By indicator signal
                                    </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div className="inputgrp_box modify_disabled">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Base order amount,{" "}
                                          {splitOne(params.pair, "second")}
                                        </label>
                                        <Form.Control
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          name="firstbuyAmount"
                                          value={firstbuyAmount}
                                        />
                                      </InputGroup>
                                      <span className="text-danger">
                                        {validErr && validErr.firstbuyAmount}
                                      </span>
                                    </div>
                                    <div className="tab modify_disabled">
                                      {/* <Form.Check
                                  inline
                                  label="Limit"
                                  name="group1"
                                  type={"radio"}
                                  id={`inline-radio-1`}
                                /> */}
                                      <Form.Check
                                        inline
                                        label="Market"
                                        name="group1"
                                        type={"radio"}
                                        id={`inline-checkbox-2`}
                                      />
                                    </div>
                                  </div>

                                  <div className="box mb-3">
                                    <div className="box_head">
                                      <i
                                        class="bi bi-info-circle-fill me-2"
                                        data-tooltip-id="my-tooltip-3"
                                      ></i>
                                      Averaging orders
                                    </div>
                                    <ReactTooltip
                                      id="my-tooltip-3"
                                      place="bottom"
                                      content={
                                        <div>
                                          Averaging orders allow you
                                          to&nbsp;average the buy or&nbsp;sell
                                          price <br /> of&nbsp;the traded coin.
                                          Here you can configure their options:
                                          <br /> the amount invested, Averaging
                                          orders quantity, and step percentage.
                                        </div>
                                      }
                                    />

                                    <div className="inputgrp_box modify_disabled">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Averaging orders amount,{" "}
                                          {splitOne(params.pair, "second")}
                                        </label>
                                        <Form.Control
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          name="AveragingorderAmount"
                                          value={AveragingorderAmount}
                                        />
                                      </InputGroup>
                                      <span className="text-danger">
                                        {validErr &&
                                          validErr.AveragingorderAmount}
                                      </span>
                                    </div>
                                    <div className="inputgrp_box center modify_disabled">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Averaging orders quantity
                                        </label>
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/minus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>
                                        <Form.Control
                                          aria-label="Username"
                                          value={margincallLimit}
                                          name="margincallLimit"
                                          aria-describedby="basic-addon1"
                                        />
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/plus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <span className="text-danger">
                                        {validErr && validErr.margincallLimit}
                                      </span>
                                      <span className="text-danger">
                                        {validErr && validErr.Buyamount}
                                      </span>
                                    </div>
                                    <div className="inputgrp_box center modify_disabled">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Averaging orders step, %
                                        </label>
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/minus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>

                                        <Form.Control
                                          aria-label="Username"
                                          value={AvgorderStep}
                                          name="AvgorderStep"
                                          aria-describedby="basic-addon1"
                                        />
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/plus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <span className="text-danger">
                                        {validErr && validErr.AvgorderStep}
                                      </span>
                                    </div>
                                    {/* <div className="chk_box modify_disabled">
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexSwitchCheckDefault"
                                        >
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="my-tooltip-4"
                                          ></i>
                                          Active Orders Limit
                                          <img
                                            src={require("../../assets/images/lock.png")}
                                            alt="Icon"
                                            className="img-fluid lock_icon ms-1"
                                          />
                                        </label>
                                        <ReactTooltip
                                          id="my-tooltip-4"
                                          place="bottom"
                                          content={
                                            <div>
                                              Limit the number of simultaneously
                                              placed averaging orders.
                                            </div>
                                          }
                                        />
                                      </div>
                                    </div> */}
                                    <div className="inputgrp_box center modify_disabled">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Avg. orders limit
                                        </label>
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/minus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>
                                        <Form.Control
                                          aria-label="Username"
                                          value={"0"}
                                          aria-describedby="basic-addon1"
                                        />
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/plus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                          />
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                  </div>
                                  <div className="box mb-3 modify_disabled">
                                    <div className="box_head pb-2">
                                      <i
                                        class="bi bi-info-circle-fill me-2"
                                        data-tooltip-id="amount_multi"
                                      ></i>
                                      Amount multiplier
                                      <img
                                        src={require("../../assets/images/lock.png")}
                                        alt="Icon"
                                        className="img-fluid lock_icon ms-1"
                                      />
                                    </div>
                                    <ReactTooltip
                                      id="amount_multi"
                                      place="bottom"
                                      content={
                                        <div>
                                          {" "}
                                          <div>
                                            <span className="white">
                                              Amount multiplier
                                            </span>
                                          </div>
                                          <div>
                                            Set a coefficient to progressively
                                            increase the size of each <br />{" "}
                                            subsequent Averaging order. The
                                            bigger the coefficient,
                                            <br /> the larger your reward (but
                                            also the risk) can be.
                                          </div>
                                        </div>
                                      }
                                    />
                                    <div className="d-flex gap-3 align-items-center">
                                      <span>Off</span>
                                      <div className="slider_box mt-2 w-100">
                                        <Slider
                                          value={AmountMultiplier}
                                          name="AmountMultiplier"
                                          valueLabelDisplay="on"
                                          valueLabelFormat={
                                            AmountMultiplier < 1
                                              ? "Off"
                                              : "x" + AmountMultiplier
                                          }
                                          aria-labelledby="range-slider"
                                          defaultValue={1}
                                          step={0.01}
                                          min={0.99}
                                          max={2}
                                        />
                                      </div>
                                      <span>x2</span>
                                    </div>
                                    <span className="text-danger">
                                      {validErr && validErr.AmountMultiplier}
                                    </span>
                                  </div>
                                  <div className="box modify_disabled">
                                    <div className="box_head pb-2">
                                      <i
                                        class="bi bi-info-circle-fill me-2"
                                        data-tooltip-id="step_multi"
                                      ></i>
                                      Step multiplier
                                      <img
                                        src={require("../../assets/images/lock.png")}
                                        alt="Icon"
                                        className="img-fluid lock_icon ms-1"
                                      />
                                    </div>
                                    <ReactTooltip
                                      id="step_multi"
                                      place="bottom"
                                      content={
                                        <div>
                                          {" "}
                                          <div>
                                            <sapn className="white">
                                              Step multiplier
                                            </sapn>
                                          </div>
                                          <div>
                                            Set a&nbsp;coefficient
                                            to&nbsp;progressively expand the
                                            grid <br />
                                            of&nbsp;Averaging orders. The bigger
                                            the coefficient, the fewer <br />{" "}
                                            trades will be&nbsp;executed.
                                          </div>
                                        </div>
                                      }
                                    />
                                    <div className="d-flex gap-3 align-items-center">
                                      <span>Off</span>
                                      <div className="slider_box mt-2 w-100">
                                        <Slider
                                          value={stepMultiplier}
                                          name="stepMultiplier"
                                          valueLabelDisplay="on"
                                          valueLabelFormat={
                                            stepMultiplier < 1
                                              ? "Off"
                                              : "x" + stepMultiplier
                                          }
                                          aria-labelledby="range-slider"
                                          defaultValue={1}
                                          step={0.01}
                                          min={0.99}
                                          max={2}
                                        />
                                      </div>
                                      <span>x2</span>
                                    </div>
                                    <span className="text-danger">
                                      {validErr && validErr.stepMultiplier}
                                    </span>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                  <div>Position TP & SL</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="box mb-3">
                                    <div className="box_head">
                                      <i
                                        class="bi bi-info-circle-fill me-2"
                                        data-tooltip-id="my-tooltip-5"
                                      ></i>
                                      Take Profit
                                    </div>
                                    <ReactTooltip
                                      id="my-tooltip-5"
                                      place="bottom"
                                      content={
                                        <div>
                                          Configure the options to&nbsp;close
                                          a&nbsp;position when it’s profitable.{" "}
                                          <br />
                                          You can set the percentage, execution
                                          conditions, and type of&nbsp;Taking
                                          Profit order.
                                        </div>
                                      }
                                    />
                                    <div className="tab modify_disabled">
                                      <Form.Check
                                        inline
                                        label="Regular"
                                        name="group3"
                                        type={"radio"}
                                        id={`inline-radio-1`}
                                        checked
                                      />
                                      {/* <Form.Check
                                  inline
                                  label="Trailing"
                                  name="group3"
                                  type={"radio"}
                                  id={`inline-checkbox-2`}
                                /> */}
                                    </div>
                                    <div className="inputgrp_box center">
                                      <InputGroup className="w-100">
                                        <label className="grey">
                                          Price change, %
                                        </label>
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/minus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                            onClick={() =>
                                              handleIncDec(
                                                "takeprofitRatio",
                                                "-",
                                                0.25
                                              )
                                            }
                                          />
                                        </InputGroup.Text>
                                        <Form.Control
                                          aria-label="Username"
                                          value={takeprofitRatio}
                                          name="takeprofitRatio"
                                          onChange={handletradeChange}
                                          aria-describedby="basic-addon1"
                                        />
                                        <InputGroup.Text id="basic-addon1">
                                          <img
                                            src={require("../../assets/images/plus.png")}
                                            alt="Icon"
                                            className="img-fluid cursor"
                                            onClick={() =>
                                              handleIncDec(
                                                "takeprofitRatio",
                                                "+",
                                                0.25
                                              )
                                            }
                                          />
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <span className="text-danger">
                                        {validErr && validErr.takeprofitRatio}
                                      </span>
                                    </div>
                                    <div className="drpdwn_box">
                                      <Dropdown
                                        onSelect={handleSelect1}
                                        className="w-100"
                                      >
                                        <Dropdown.Toggle id="dropdown-basic">
                                          <span className="exchange">
                                            Percentage of
                                          </span>
                                          {selectedValue1}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item eventKey="Average Price">
                                            Average Price
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item eventKey="Average Price + Indicators">
                                            Average Price + Indicators
                                          </Dropdown.Item>
                                          <Dropdown.Item eventKey="Base Order Price">
                                            Base Order Price
                                          </Dropdown.Item>
                                          <Dropdown.Item eventKey="Base Order Price + Indicators">
                                            Base Order Price + Indicators
                                          </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div className="tab modify_disabled">
                                      {/* <Form.Check
                                  inline
                                  label="Limit"
                                  name="group2"
                                  type={"radio"}
                                  id={`inline-radio-1`}
                                  checked
                                /> */}
                                      <Form.Check
                                        inline
                                        label="Market"
                                        name="group2"
                                        type={"radio"}
                                        id={`inline-checkbox-2`}
                                      />
                                    </div>
                                    <div className="value">
                                      <div>PNL</div>
                                      <div className="bdr"></div>
                                      <div>
                                        ≈{" "}
                                        <span className="green">
                                          {tradedata.tppnl}
                                        </span>{" "}
                                        USDT
                                      </div>
                                    </div>
                                  </div>
                                  <div className="box">
                                    <div className="chk_box">
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                          onChange={handlestopLoss}
                                          checked={isstoploss}
                                          value={isstoploss}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexSwitchCheckDefault"
                                        >
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="my-tooltip-4"
                                          ></i>
                                          Stop Loss
                                        </label>
                                        <ReactTooltip
                                          id="my-tooltip-4"
                                          place="bottom"
                                          content={
                                            <div>
                                              Set the percentage of price change
                                              to close a position <br /> if the
                                              price continues to move in a
                                              negative direction after
                                              <br /> all Averaging orders have
                                              been executed.
                                            </div>
                                          }
                                        />
                                      </div>
                                    </div>
                                    {isstoploss > 0 && (
                                      <>
                                        <div className="inputgrp_box center">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Price change, %
                                            </label>
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/minus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "stopLoss",
                                                    "-",
                                                    1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                            <Form.Control
                                              aria-label="Username"
                                              value={stopLoss}
                                              name="stopLoss"
                                              onChange={handletradeChange}
                                              aria-describedby="basic-addon1"
                                            />
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/plus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "stopLoss",
                                                    "+",
                                                    1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr && validErr.stopLoss}
                                          </span>
                                        </div>

                                        <div className="value">
                                          <div>PNL</div>
                                          <div className="bdr"></div>
                                          <div>
                                            ≈{" "}
                                            <span className="redText">
                                              -{tradedata.slpnl}
                                            </span>{" "}
                                            USDT
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div className="bgcolor">
                                      <button
                                        onClick={() => handleContinue()}
                                        className="continue"
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                  <div className="">
                    <button
                      onClick={() => handleCloseicon("modify")}
                      className="backtest"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/* </Scrollbars> */}
              </div>
            </div>
          </div>
        )}

        {/* Stop DCA Bot */}
        {Stopbot && (
          <div className="stop_bot_wrap">
            <div className="trade_main_settings cardheadbg">
              <div className="card trade_card">
                <div className="card-header">
                  <h2>Stop DCA Bot </h2>
                  <img
                    src={require("../../assets/images/close_icon.png")}
                    alt="Icon"
                    className="img-fluid cursor"
                    onClick={() => handleCloseicon("stop")}
                  />
                </div>
                <div className="card-body card-body-padding">
                  <p className="greyTxt">Select stop bot conditions</p>
                  <div className="stop_bot_panel">
                    {/* <div className="stop_bot_single">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Cancel all orders and keep PEPE
                        </label>
                      </div>
                      <p>
                        Open orders will be closed and the current amount in
                        trade currency will be held in your balance.
                      </p>
                      <div class="bot_preview_box">
                        <div>
                          <span>Bot profit</span>
                          <span class="border_line"></span>
                          <span>
                            2 550.99
                            <span className="greyTxt">USDT</span> /{" "}
                            <span className="greenTxt">55.44%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="stop_bot_single">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />
                        <label class="form-check-label" for="exampleRadios2">
                          Sell at limit price
                        </label>
                      </div>
                      <p>
                        Place a Limit order and then move the bot to history.
                      </p>
                      <div className="createbot_box">
                        <div class="inputgrp_box mb-4">
                          <div class="mb-3 w-100 input-group">
                            <label class="grey">Limit price level</label>
                            <input
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="investmentAmount"
                              class="form-control"
                              value=""
                            />
                          </div>
                          <span class="text-danger"></span>
                        </div>
                      </div>
                      <div class="bot_preview_box">
                        <div>
                          <span>Break-even price</span>
                          <span class="border_line"></span>
                          <span className="blueText">0.00000157 USDT</span>
                        </div>
                        <div>
                          <span>Estimated PNL</span>
                          <span class="border_line"></span>
                          <span>
                            ≈ 2 550.99
                            <span className="greyTxt">USDT</span> /{" "}
                            <span className="greenTxt">55.44%</span>
                          </span>
                        </div>
                      </div>
                    </div> */}
                    <div className="stop_bot_single">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios3"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios3">
                          Sell at market price
                        </label>
                      </div>
                      <p>
                        Sell {SellAmount} {splitOne(params.pair, "first")} for
                        {splitOne(params.pair, "second")} at market price and
                        move the bot to history.
                      </p>
                      <div class="bot_preview_box">
                        <div>
                          <span>
                            Total PNL<small>(Buy&Sell Fee)</small>
                          </span>
                          <span class="border_line"></span>
                          <span>
                            {toFixedDown(peformanceDetails.pnl_bs, 2)}
                            <span className="greyTxt">USDT</span> /{" "}
                            <span
                              className={
                                peformanceDetails.pnlval_bs > 0
                                  ? "greenTxt"
                                  : "redText"
                              }
                            >
                              {" "}
                              {toFixedDown(peformanceDetails.pnlval_bs, 2)}%
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex_btns mt-3">
                    <button
                      className="continue"
                      onClick={() => handleSellSubmit()}
                      disabled={buyLoader}
                    >
                      {sellLoader && <div className="loader"></div>}
                      {!sellLoader && "Confirm"}
                    </button>

                    <button
                      className="primary_boder_btn"
                      onClick={() => handlestopbot(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isEmpty(notifybotstatus) && (
          <div className="trade_main_settings">
            <div className="card trade_card">
              <div className="card-header">
                <h2>Create DCA Bot</h2>
              </div>
              <div className="card-body">
                {/* Inprogress Message */}
                {notifybotstatus == "inprogress" && (
                  <div className="create_bot_img_wrap">
                    <div className="create_bot_img">
                      <img
                        src={require("../../assets/images/power.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Starting up</h5>
                    <p>Loading…</p>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Basic example"
                        style={{ width: "25%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                )}

                {/* Success Message */}
                {notifybotstatus == "success" && (
                  <div className="create_bot_img_wrap">
                    <div className="create_bot_img">
                      <img
                        src={require("../../assets/images/checked.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Success</h5>
                    <p>Your bot has been started</p>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Basic example"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                )}

                {/* Error Message */}
                {/* <div className="create_bot_img_wrap">
              <div className="create_bot_img">
                <img
                  src={require("../../assets/images/warning.png")}
                  alt="Icon"
                  className="img-fluid"
                />
              </div>
              <h5>Error</h5>
              <p>Server Error</p>
            </div> */}
              </div>
            </div>
          </div>
        )}

        {!isEmpty(modnotifybotstatus) && (
          <div className="trade_main_settings">
            <div className="card trade_card">
              <div className="card-header">
                <h2>Modify DCA Bot</h2>
              </div>
              <div className="card-body">
                {/* Inprogress Message */}
                {modnotifybotstatus == "inprogress" && (
                  <div className="create_bot_img_wrap">
                    <div className="create_bot_img">
                      <img
                        src={require("../../assets/images/power.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Starting up</h5>
                    <p>Loading…</p>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Basic example"
                        style={{ width: "25%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                )}

                {/* Success Message */}
                {modnotifybotstatus == "success" && (
                  <div className="create_bot_img_wrap">
                    <div className="create_bot_img">
                      <img
                        src={require("../../assets/images/checked.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Success</h5>
                    <p>Your bot has been Modified</p>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Basic example"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                )}

                {/* Error Message */}
                {/* <div className="create_bot_img_wrap">
              <div className="create_bot_img">
                <img
                  src={require("../../assets/images/warning.png")}
                  alt="Icon"
                  className="img-fluid"
                />
              </div>
              <h5>Error</h5>
              <p>Server Error</p>
            </div> */}
              </div>
            </div>
          </div>
        )}

        {/*Create DCA Bot  */}

        {params &&
          params?.bot == "dca" &&
          Istrade == "off" &&
          IsContinue == "off" &&
          isEmpty(modnotifybotstatus) &&
          botData?.crtBot &&
          !botData?.status &&
          ctab != "Dhistory" &&
          ctab != "Dopen" &&
          !Closecreate && (
            <div className="trade_main_settings cardheadbg">
              {!Botstloader ? (
                <div className="card trade_card">
                  <div className="card-header">
                    <h2>
                      Create DCA Bot{" "}
                      <i
                        class="bi bi-info-circle-fill ms-2"
                        data-tooltip-id="create_dca"
                      ></i>{" "}
                    </h2>
                    <ReactTooltip
                      id="create_dca"
                      place="bottom"
                      content={
                        <div>
                          <p className="mb-2">DCA Bot</p>
                          <p className="mb-2">
                            The Dollar-Cost Averaging bot multiplies earns and
                            reduces risks in&nbsp;volatile markets. Use
                            it&nbsp;on&nbsp;uptrends and downtrends.
                          </p>
                        </div>
                      }
                    />
                    <img
                      src={require("../../assets/images/close_icon.png")}
                      alt="Icon"
                      className="img-fluid cursor"
                      onClick={() => handleCloseicon("create")}
                    />
                  </div>
                  <div className="card-body card-body-padding scroll createbot_card">
                    {!isEmpty(multiplebot) && (
                      <span className="text-danger">
                        Configuration Error : <div> {multiplebot}</div>
                      </span>
                    )}
                    <ExchangeBalance params={params} updatePair={updatePair} />
                    <TradePairs
                      params={params}
                      pairsAllTrade={PairListTrd}
                      updatePair={updatePair}
                    />
                    {Istrade == "off" && (
                      <div className="createbot_box">
                        <p className="bot_preview_title pt-2">
                          Strategy
                          <i
                            class="bi bi-info-circle-fill ms-2"
                            data-tooltip-id="my-tooltip-1"
                          ></i>
                        </p>
                        <ReactTooltip
                          id="my-tooltip-1"
                          place="bottom"
                          content={
                            <div>
                              <p className="mb-2">
                                Choose your bot trading strategy:
                              </p>
                              <p className="mb-2">
                                <b>Long</b>&nbsp;— the bot first buys the Base
                                currency and then profits from selling
                                it&nbsp;when its price rises.
                              </p>
                            </div>
                          }
                        />

                        <div className="t_box mb-4">Long</div>
                        <div className="inputgrp_box mb-4">
                          <InputGroup className="mb-3 w-100">
                            <label className="grey">
                              Investment, {splitOne(params.pair, "second")}
                            </label>
                            <Form.Control
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="investmentAmount"
                              value={
                                investmentAmount <= 0 ? 0 : investmentAmount
                              }
                              onChange={handletradeChange}
                            />

                            <InputGroup.Text id="basic-addon1" className="grey">
                              ≈{" "}
                              {toFixedDown(
                                parseFloat(investmentAmount) *
                                  parseFloat(usdprice),
                                2
                              )}
                              USD{" "}
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="text-danger">
                            {validErr && validErr.investmentAmount}
                          </span>
                          {!isEmpty(IniinvestmentAmount) &&
                            !isEmpty(investmentAmount) &&
                            parseFloat(IniinvestmentAmount) <
                              parseFloat(investmentAmount) && (
                              <span className="text-danger">
                                You don't have enough USDT balance
                              </span>
                            )}
                        </div>

                        <div className="slider_box my-3">
                          <Slider
                            value={tradedata.slvalue}
                            onChange={handletradeChange}
                            valueLabelDisplay="on"
                            valueLabelFormat={
                              parseFloat(tradedata.slvalue) > 100
                                ? "100%"
                                : !isEmpty(tradedata.slvalue) &&
                                  parseFloat(tradedata.slvalue) + "%"
                            }
                            aria-labelledby="range-slider"
                            name="slvalue"
                          />
                        </div>

                        <div className="accr_box">
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header className="center">
                                <div className="blue"> Manual adjustment</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion>
                                  <Accordion.Item className="mb-3" eventKey="1">
                                    <Accordion.Header>
                                      {" "}
                                      <div> Bot settings</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="box mb-3">
                                        <div className="box_head">
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="my-tooltip-2"
                                          ></i>
                                          Bot start conditions
                                        </div>
                                        <ReactTooltip
                                          id="my-tooltip-2"
                                          place="bottom"
                                          content={
                                            <div>
                                              Your DCA bot starts
                                              to&nbsp;operate by&nbsp;placing
                                              the Base order. <br />
                                              Here you can configure its
                                              options: the amount invested,
                                              <br /> placement conditions, and
                                              type of&nbsp;Base order.
                                            </div>
                                          }
                                        />
                                        <div className="drpdwn_box">
                                          <Dropdown
                                            onSelect={handleSelect}
                                            className="w-100"
                                          >
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <span className="exchange">
                                                Place base order
                                              </span>
                                              {selectedValue}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item eventKey="Immediately">
                                                Immediately
                                              </Dropdown.Item>
                                              {/* <Dropdown.Item eventKey="By indicator signal">
                                      By indicator signal
                                    </Dropdown.Item> */}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <div className="inputgrp_box">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Base order amount,{" "}
                                              {splitOne(params.pair, "second")}
                                            </label>
                                            <Form.Control
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                              name="firstbuyAmount"
                                              value={firstbuyAmount}
                                              onChange={handletradeChange}
                                            />
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr &&
                                              validErr.firstbuyAmount}
                                          </span>
                                        </div>
                                        <div className="tab">
                                          {/* <Form.Check
                                  inline
                                  label="Limit"
                                  name="group1"
                                  type={"radio"}
                                  id={`inline-radio-1`}
                                /> */}
                                          <Form.Check
                                            inline
                                            label="Market"
                                            name="group1"
                                            type={"radio"}
                                            id={`inline-checkbox-2`}
                                          />
                                        </div>
                                      </div>

                                      <div className="box mb-3">
                                        <div className="box_head">
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="my-tooltip-3"
                                          ></i>
                                          Averaging orders
                                        </div>
                                        <ReactTooltip
                                          id="my-tooltip-3"
                                          place="bottom"
                                          content={
                                            <div>
                                              Averaging orders allow you
                                              to&nbsp;average the buy
                                              or&nbsp;sell price <br />{" "}
                                              of&nbsp;the traded coin. Here you
                                              can configure their options:
                                              <br /> the amount invested,
                                              Averaging orders quantity, and
                                              step percentage.
                                            </div>
                                          }
                                        />

                                        <div className="inputgrp_box">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Averaging orders amount,{" "}
                                              {splitOne(params.pair, "second")}
                                            </label>
                                            <Form.Control
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                              name="AveragingorderAmount"
                                              value={AveragingorderAmount}
                                              onChange={handletradeChange}
                                            />
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr &&
                                              validErr.AveragingorderAmount}
                                          </span>
                                          <span className="text-danger">
                                            {validErr && validErr.Buyamount}
                                          </span>
                                        </div>
                                        <div className="inputgrp_box center">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Averaging orders quantity
                                            </label>
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/minus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "margincallLimit",
                                                    "-",
                                                    1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                            <Form.Control
                                              aria-label="Username"
                                              value={margincallLimit}
                                              name="margincallLimit"
                                              onChange={handletradeChange}
                                              aria-describedby="basic-addon1"
                                            />
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/plus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "margincallLimit",
                                                    "+",
                                                    1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr &&
                                              validErr.margincallLimit}
                                          </span>
                                        </div>
                                        <div className="inputgrp_box center">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Averaging orders step, %
                                            </label>
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/minus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "AvgorderStep",
                                                    "-",
                                                    0.1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>

                                            <Form.Control
                                              aria-label="Username"
                                              value={AvgorderStep}
                                              name="AvgorderStep"
                                              onChange={handletradeChange}
                                              aria-describedby="basic-addon1"
                                            />
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/plus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "AvgorderStep",
                                                    "+",
                                                    0.1
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr && validErr.AvgorderStep}
                                          </span>
                                        </div>
                                        {/* <div className="chk_box">
                                        <div class="form-check form-switch">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexSwitchCheckDefault"
                                          >
                                            <i
                                              class="bi bi-info-circle-fill me-2"
                                              data-tooltip-id="my-tooltip-4"
                                            ></i>
                                            Active Orders Limit
                                          </label>
                                          <ReactTooltip
                                            id="my-tooltip-4"
                                            place="bottom"
                                            content={
                                              <div>
                                                Limit the number of
                                                simultaneously placed averaging
                                                orders.
                                              </div>
                                            }
                                          />
                                        </div>
                                      </div> 
                                      <div className="inputgrp_box center">
                                        <InputGroup className="w-100">
                                          <label className="grey">
                                            Avg. orders limit
                                          </label>
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src={require("../../assets/images/minus.png")}
                                              alt="Icon"
                                              className="img-fluid cursor"
                                            />
                                          </InputGroup.Text>
                                          <Form.Control
                                            aria-label="Username"
                                            value={"0"}
                                            aria-describedby="basic-addon1"
                                          />
                                          <InputGroup.Text id="basic-addon1">
                                            <img
                                              src={require("../../assets/images/plus.png")}
                                              alt="Icon"
                                              className="img-fluid cursor"
                                            />
                                          </InputGroup.Text>
                                        </InputGroup>
                                      </div>*/}
                                      </div>
                                      <div className="box mb-3">
                                        <div className="box_head pb-2">
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="amount_multi"
                                          ></i>
                                          Amount multiplier
                                        </div>
                                        <ReactTooltip
                                          id="amount_multi"
                                          place="bottom"
                                          content={
                                            <div>
                                              {" "}
                                              <div>
                                                <span className="white">
                                                  Amount multiplier
                                                </span>
                                              </div>
                                              <div>
                                                Set a coefficient to
                                                progressively increase the size
                                                of each <br /> subsequent
                                                Averaging order. The bigger the
                                                coefficient,
                                                <br /> the larger your reward
                                                (but also the risk) can be.
                                              </div>
                                            </div>
                                          }
                                        />
                                        <div className="d-flex gap-3 align-items-center">
                                          <span>Off</span>
                                          <div className="slider_box mt-2 w-100">
                                            <Slider
                                              value={AmountMultiplier}
                                              name="AmountMultiplier"
                                              onChange={handletradeChange}
                                              valueLabelDisplay="on"
                                              valueLabelFormat={
                                                AmountMultiplier < 1
                                                  ? "Off"
                                                  : "x" + AmountMultiplier
                                              }
                                              aria-labelledby="range-slider"
                                              defaultValue={1}
                                              step={0.01}
                                              min={0.99}
                                              max={2}
                                            />
                                          </div>
                                          <span>x2</span>
                                        </div>
                                        <span className="text-danger">
                                          {validErr &&
                                            validErr.AmountMultiplier}
                                        </span>
                                      </div>
                                      <div className="box">
                                        <div className="box_head pb-2">
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="step_multi"
                                          ></i>
                                          Step multiplier
                                        </div>
                                        <ReactTooltip
                                          id="step_multi"
                                          place="bottom"
                                          content={
                                            <div>
                                              {" "}
                                              <div>
                                                <sapn className="white">
                                                  Step multiplier
                                                </sapn>
                                              </div>
                                              <div>
                                                Set a&nbsp;coefficient
                                                to&nbsp;progressively expand the
                                                grid <br />
                                                of&nbsp;Averaging orders. The
                                                bigger the coefficient, the
                                                fewer <br /> trades will
                                                be&nbsp;executed.
                                              </div>
                                            </div>
                                          }
                                        />
                                        <div className="d-flex gap-3 align-items-center">
                                          <span>Off</span>
                                          <div className="slider_box mt-2 w-100">
                                            <Slider
                                              value={stepMultiplier}
                                              name="stepMultiplier"
                                              onChange={handletradeChange}
                                              valueLabelDisplay="on"
                                              valueLabelFormat={
                                                stepMultiplier < 1
                                                  ? "Off"
                                                  : "x" + stepMultiplier
                                              }
                                              aria-labelledby="range-slider"
                                              defaultValue={1}
                                              step={0.01}
                                              min={0.99}
                                              max={2}
                                            />
                                          </div>
                                          <span>x2</span>
                                        </div>
                                        <span className="text-danger">
                                          {validErr && validErr.stepMultiplier}
                                        </span>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                      <div>Position TP & SL</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="box mb-3">
                                        <div className="box_head">
                                          <i
                                            class="bi bi-info-circle-fill me-2"
                                            data-tooltip-id="my-tooltip-5"
                                          ></i>
                                          Take Profit
                                        </div>
                                        <ReactTooltip
                                          id="my-tooltip-5"
                                          place="bottom"
                                          content={
                                            <div>
                                              Configure the options
                                              to&nbsp;close a&nbsp;position when
                                              it’s profitable. <br />
                                              You can set the percentage,
                                              execution conditions, and type
                                              of&nbsp;Taking Profit order.
                                            </div>
                                          }
                                        />
                                        <div className="tab">
                                          <Form.Check
                                            inline
                                            label="Regular"
                                            name="group3"
                                            type={"radio"}
                                            id={`inline-radio-1`}
                                            checked
                                          />
                                          {/* <Form.Check
                                  inline
                                  label="Trailing"
                                  name="group3"
                                  type={"radio"}
                                  id={`inline-checkbox-2`}
                                /> */}
                                        </div>
                                        <div className="inputgrp_box center">
                                          <InputGroup className="w-100">
                                            <label className="grey">
                                              Price change, %
                                            </label>
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/minus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "takeprofitRatio",
                                                    "-",
                                                    0.25
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                            <Form.Control
                                              aria-label="Username"
                                              value={takeprofitRatio}
                                              name="takeprofitRatio"
                                              onChange={handletradeChange}
                                              aria-describedby="basic-addon1"
                                            />
                                            <InputGroup.Text id="basic-addon1">
                                              <img
                                                src={require("../../assets/images/plus.png")}
                                                alt="Icon"
                                                className="img-fluid cursor"
                                                onClick={() =>
                                                  handleIncDec(
                                                    "takeprofitRatio",
                                                    "+",
                                                    0.25
                                                  )
                                                }
                                              />
                                            </InputGroup.Text>
                                          </InputGroup>
                                          <span className="text-danger">
                                            {validErr &&
                                              validErr.takeprofitRatio}
                                          </span>
                                        </div>
                                        <div className="drpdwn_box">
                                          <Dropdown
                                            onSelect={handleSelect1}
                                            className="w-100"
                                          >
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <span className="exchange">
                                                Percentage of
                                              </span>
                                              {selectedValue1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item eventKey="Average Price">
                                                Average Price
                                              </Dropdown.Item>
                                              {/* <Dropdown.Item eventKey="Average Price + Indicators">
                                              Average Price + Indicators
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Base Order Price">
                                              Base Order Price
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Base Order Price + Indicators">
                                              Base Order Price + Indicators
                                            </Dropdown.Item> */}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <div className="tab">
                                          {/* <Form.Check
                                  inline
                                  label="Limit"
                                  name="group2"
                                  type={"radio"}
                                  id={`inline-radio-1`}
                                  checked
                                /> */}
                                          <Form.Check
                                            inline
                                            label="Market"
                                            name="group2"
                                            type={"radio"}
                                            id={`inline-checkbox-2`}
                                          />
                                        </div>
                                        <div className="value">
                                          <div>PNL</div>
                                          <div className="bdr"></div>
                                          <div>
                                            ≈{" "}
                                            <span className="green">
                                              {tradedata.tppnl}
                                            </span>{" "}
                                            USDT
                                          </div>
                                        </div>
                                      </div>
                                      <div className="box">
                                        <div className="chk_box">
                                          <div class="form-check form-switch">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id="flexSwitchCheckDefault"
                                              onChange={handlestopLoss}
                                              checked={isstoploss}
                                              value={isstoploss}
                                              name="isstoploss"
                                            />
                                            <label
                                              class="form-check-label"
                                              for="flexSwitchCheckDefault"
                                            >
                                              <i
                                                class="bi bi-info-circle-fill me-2"
                                                data-tooltip-id="my-tooltip-4"
                                              ></i>
                                              Stop Loss
                                            </label>
                                            <ReactTooltip
                                              id="my-tooltip-4"
                                              place="bottom"
                                              content={
                                                <div>
                                                  Set the percentage of price
                                                  change to close a position{" "}
                                                  <br /> if the price continues
                                                  to move in a negative
                                                  direction after
                                                  <br /> all Averaging orders
                                                  have been executed.
                                                </div>
                                              }
                                            />
                                          </div>
                                        </div>
                                        {isstoploss && (
                                          <>
                                            <div className="inputgrp_box center">
                                              <InputGroup className="w-100">
                                                <label className="grey">
                                                  Price change, %
                                                </label>
                                                <InputGroup.Text id="basic-addon1">
                                                  <img
                                                    src={require("../../assets/images/minus.png")}
                                                    alt="Icon"
                                                    className="img-fluid cursor"
                                                    onClick={() =>
                                                      handleIncDec(
                                                        "stopLoss",
                                                        "-",
                                                        1
                                                      )
                                                    }
                                                  />
                                                </InputGroup.Text>
                                                <Form.Control
                                                  aria-label="Username"
                                                  value={stopLoss}
                                                  name="stopLoss"
                                                  onChange={handletradeChange}
                                                  aria-describedby="basic-addon1"
                                                />
                                                <InputGroup.Text id="basic-addon1">
                                                  <img
                                                    src={require("../../assets/images/plus.png")}
                                                    alt="Icon"
                                                    className="img-fluid cursor"
                                                    onClick={() =>
                                                      handleIncDec(
                                                        "stopLoss",
                                                        "+",
                                                        1
                                                      )
                                                    }
                                                  />
                                                </InputGroup.Text>
                                              </InputGroup>
                                              <span className="text-danger">
                                                {validErr && validErr.stopLoss}
                                              </span>
                                            </div>

                                            <div className="value">
                                              <div>PNL</div>
                                              <div className="bdr"></div>
                                              <div>
                                                ≈{" "}
                                                <span className="redText">
                                                  -{tradedata.slpnl}
                                                </span>{" "}
                                                USDT
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <div className="box flex-row justify-content-between align-items-center mt-3">
                            <p className="bot_preview_title mb-0">
                              <i
                                class="bi bi-info-circle-fill me-2"
                                data-tooltip-id="my-tooltip-6"
                              ></i>
                              Available for bot use
                            </p>
                            <p className="mb-0 font">
                              {toFixedDown(tradedata.IniinvestmentAmount, 2)}{" "}
                              <span className="grey"> USDT</span>
                            </p>
                            <ReactTooltip
                              id="my-tooltip-6"
                              place="bottom"
                              content={
                                <div>
                                  <p class="mb-2 white">
                                    Available for bot use
                                  </p>
                                  Available balance to&nbsp;run the bot, apart
                                  from funds reserved for possible use
                                  in&nbsp;other automations.
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* </Scrollbars> */}
                  {Istrade == "off" && (
                    <div className="bgcolor">
                      <button
                        className="backtest mb-2"
                        onClick={() => startBacktest()}
                        disabled={btLoader}
                      >
                        {btLoader && <span>Loading...</span>}
                        {!btLoader && <span>Backtest</span>}
                      </button>
                      <button
                        onClick={() => handleStartBot("continue")}
                        className="continue"
                        disabled={
                          !isEmpty(multiplebot)
                            ? true
                            : IniinvestmentAmount &&
                              investmentAmount &&
                              parseFloat(IniinvestmentAmount) <
                                parseFloat(investmentAmount)
                            ? true
                            : false
                        }
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="card trade_card">
                  <div className="card-header">Loading...</div>
                </div>
              )}
            </div>
          )}

        {/* Dcabot detail  */}
        {params &&
          params?.bot == "dca" &&
          (ctab == "open" || ctab == "history") &&
          botData?.status &&
          !modifybutton && (
            <div className="trade_main_settings cardheadbg">
              <div className="card trade_card">
                <div className="card-header">
                  <h2>DCA Bot Details</h2>
                  <p>
                    <img
                      src={require("../../assets/images/close_icon.png")}
                      alt="Icon"
                      className="img-fluid cursor"
                      onClick={() => handleCloseicon("details")}
                    />
                  </p>
                </div>

                <div className="card-body">
                  <div className="card_body_padding p-0">
                    <div className="bot_preview_box_wrapper">
                      {/* <Scrollbars
                  style={{ height: 400 }}
                  renderTrackVertical={({ style, ...props }) => (
                    <div
                      className="track_vertical"
                      {...props}
                      style={{
                        ...style,
                        right: "2px",
                        bottom: "2px",
                        top: "2px",
                        borderRadius: "10px",
                        width: "3px",
                      }}
                    />
                  )}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      className="thumb_vertical"
                      {...props}
                      style={{
                        ...style,
                        width: "3px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                      }}
                    />
                  )}
                > */}
                      <div className="botdetail_height">
                        <div className="p-2">
                          <div className="tab_btn mb-3">
                            <button
                              onClick={() => handleTabClick("tab1")}
                              className={activeTab === "tab1" ? "active" : ""}
                            >
                              Performance
                            </button>
                            <button
                              onClick={() => handleTabClick("tab2")}
                              className={activeTab === "tab2" ? "active" : ""}
                            >
                              Settings
                            </button>
                          </div>

                          {activeTab === "tab1" && (
                            <div>
                              <p className="greenTxt">
                                <img
                                  src={require("../../assets/images/tic.png")}
                                  alt="Icon"
                                  width="16"
                                  height="16"
                                  className="img-fluid me-2"
                                />
                                <span>Long</span>
                              </p>

                              <div className="pair_flx">
                                <img
                                  src={require("../../assets/images/" +
                                    params.exchange.toLowerCase() +
                                    ".png")}
                                  alt="Icon"
                                  width="20"
                                  className="img-fluid me-2"
                                />
                                <span> {splitOne(params.pair, "first")} /</span>
                                <span className="greyTxt ms-1">
                                  {splitOne(params.pair, "second")}
                                </span>
                                <span className="dark_bage ms-1">DCA</span>
                              </div>

                              <span className="light_bage greenTxt">Long</span>
                              {/* 
                          <div className="tab_btn right_tab  mb-3">
                            <button
                              onClick={() => handleTabClick1("tab1")}
                              className={activeTab1 === "tab1" ? "active" : ""}
                            >
                              USDT
                            </button>
                            <button
                              onClick={() => handleTabClick1("tab2")}
                              className={activeTab1 === "tab2" ? "active" : ""}
                            >
                              USD
                            </button>
                          </div> */}

                              {activeTab1 === "tab1" && (
                                <div>
                                  <div className="flx_box mt-3 mb-2">
                                    <div className="box">
                                      <div className="flx">
                                        <span>Bot profit (PNL with Fee)</span>
                                        <i
                                          class="bi bi-info-circle-fill ms-12"
                                          data-tooltip-id="botprofit"
                                        ></i>
                                        <ReactTooltip
                                          id="botprofit"
                                          place="bottom"
                                          content={
                                            <div>
                                              <span className="mb-4 d-block">
                                                Bot profit (PNL with Fee)
                                              </span>
                                              <span className="mb-4">
                                                Realized PNL
                                                of&nbsp;the&nbsp;bot. The
                                                current profit that the bot has
                                                made from its work.
                                              </span>
                                            </div>
                                          }
                                        />
                                      </div>

                                      <p
                                        className={
                                          peformanceDetails?.pnlval > 0
                                            ? "greenTxt"
                                            : "redText"
                                        }
                                      >
                                        {" "}
                                        {toFixedDown(
                                          peformanceDetails?.pnlval,
                                          2
                                        )}
                                        %
                                      </p>
                                      <h6>
                                        {toFixedDown(peformanceDetails?.pnl, 2)}
                                      </h6>
                                      <span>USDT</span>
                                    </div>

                                    <div className="box">
                                      <div className="flx">
                                        <span>
                                          Bot profit (PNL without Fee )
                                        </span>
                                        <i
                                          class="bi bi-info-circle-fill ms-6"
                                          data-tooltip-id="botprofitwo"
                                        ></i>
                                        <ReactTooltip
                                          id="botprofitwo"
                                          place="bottom"
                                          content={
                                            <div>
                                              <span className="mb-4 d-block">
                                                Bot profit (PNL without Fee)
                                              </span>
                                              <span className="mb-4">
                                                Realized PNL
                                                of&nbsp;the&nbsp;bot. The
                                                current profit that the bot has
                                                made from its work.
                                              </span>
                                            </div>
                                          }
                                        />
                                      </div>

                                      <p
                                        className={
                                          peformanceDetails?.pnlval_wofee > 0
                                            ? "greenTxt"
                                            : "redText"
                                        }
                                      >
                                        {" "}
                                        {toFixedDown(
                                          peformanceDetails.pnlval_wofee,
                                          2
                                        )}
                                        %
                                      </p>
                                      <h6>
                                        {toFixedDown(
                                          peformanceDetails?.pnl_wofee,
                                          2
                                        )}
                                      </h6>
                                      <span>USDT</span>
                                    </div>

                                    {/* 
                                    <div className="box">
                                      <div className="flx">
                                        <span>Avg. daily profit </span>
                                        <i
                                          class="bi bi-info-circle-fill ms-6"
                                          data-tooltip-id="botprofit"
                                        ></i>
                                        <ReactTooltip
                                          id="botprofit"
                                          place="bottom"
                                          content={
                                            <div>
                                              <span className="mb-4 d-block">
                                                Avg. daily profit
                                              </span>
                                              <span className="mb-4">
                                                Average daily profit of the bot
                                                for the entire time of its work.
                                              </span>
                                            </div>
                                          }
                                        />
                                      </div>
                                      <h6>
                                        {peformanceDetails.avgdailyProfit}
                                      </h6>
                                      <span>USDT</span>
                                      <p
                                        className={
                                          peformanceDetails.avgdailyProfit > 0
                                            ? "greenTxt"
                                            : "redText"
                                        }
                                      >
                                        {" "}
                                        {peformanceDetails.avgdailyProfitval}%
                                      </p>
                                    </div> */}
                                  </div>
                                  <div className="bot_preview_box mb-2">
                                    <div>
                                      <span>Unrealized</span>
                                      <span className="border_line"></span>
                                      <span>
                                        {toFixedDown(peformanceDetails.pnl, 2)}{" "}
                                        <span className="greyTxt">
                                          {splitOne(params.pair, "second")}
                                        </span>{" "}
                                        /{" "}
                                        <span className="greyTxt">
                                          {" "}
                                          {toFixedDown(
                                            peformanceDetails.pnlval,
                                            2
                                          )}
                                          %
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <h3 className="bot_preview_title">
                                      In Orders
                                    </h3>
                                    <div className="bot_preview_box">
                                      <div>
                                        <span>Base currency</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {toFixedDown(
                                            peformanceDetails.orderBc,
                                            peformanceDetails.priceDecimal
                                          )}{" "}
                                          <span className="greyTxt">
                                            {splitOne(params.pair, "first")}
                                          </span>{" "}
                                        </span>
                                      </div>
                                      {/* <div>
                                        <span>Quote currency</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {peformanceDetails.orderQc}{" "}
                                          <span className="greyTxt">
                                            {splitOne(params.pair, "second")}
                                          </span>{" "}
                                        </span>
                                      </div> */}
                                      <div>
                                        <span>Value</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {peformanceDetails.orderVal}{" "}
                                          <span className="greyTxt">
                                            {splitOne(params.pair, "second")}
                                          </span>{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <h3 className="bot_preview_title">
                                      Invested funds
                                    </h3>
                                    <div className="bot_preview_box">
                                      <div>
                                        <span>Base currency</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {toFixedDown(
                                            peformanceDetails.invBc,
                                            peformanceDetails.priceDecimal
                                          )}{" "}
                                          <span className="greyTxt">
                                            {" "}
                                            {splitOne(params.pair, "first")}
                                          </span>{" "}
                                        </span>
                                      </div>
                                      <div>
                                        <span>Quote currency</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {peformanceDetails.invQc}{" "}
                                          <span className="greyTxt">
                                            {" "}
                                            {splitOne(params.pair, "second")}
                                          </span>{" "}
                                        </span>
                                      </div>
                                      <div>
                                        <span>Value</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {peformanceDetails.invVal}{" "}
                                          <span className="greyTxt">
                                            {" "}
                                            {splitOne(params.pair, "second")}
                                          </span>{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <h3 className="bot_preview_title">
                                      General info
                                    </h3>
                                    <div className="bot_preview_box">
                                      <div>
                                        <span>Transactions</span>
                                        <span className="border_line"></span>
                                        <span> {peformanceDetails.txn}</span>
                                      </div>
                                      <div>
                                        <span>Trading time</span>
                                        <span className="border_line"></span>
                                        <span>
                                          {peformanceDetails?.createdAt}
                                        </span>
                                      </div>
                                      <div>
                                        <span>Bot ID</span>
                                        <span className="border_line"></span>
                                        <span className="d-flex align-items-center">
                                          <span className="half_view">
                                            {" "}
                                            {peformanceDetails._id}
                                          </span>
                                          <CopyToClipboard
                                            text={peformanceDetails._id}
                                            onCopy={() => {
                                              toastAlert(
                                                "success",
                                                "Copied!",
                                                "twoFa"
                                              );
                                            }}
                                          >
                                            <img
                                              src={require("../../assets/images/copy-icon.png")}
                                              alt="Icon"
                                              className="img-fluid cursor"
                                              width="12"
                                            />
                                          </CopyToClipboard>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {botData?.status && (
                                <button
                                  className="primary_btn primary_black_btn m-0"
                                  onClick={() => BotorderMoal()}
                                >
                                  Bot orders
                                </button>
                              )}
                            </div>
                          )}

                          {activeTab === "tab2" && (
                            <div>
                              <div>
                                <h3 className="bot_preview_title">
                                  General settings
                                </h3>
                                <div className="bot_preview_box">
                                  <div>
                                    <span>Exchange</span>
                                    <span className="border_line"></span>
                                    <span>{params.exchange}</span>
                                  </div>
                                  <div>
                                    <span>Pair</span>
                                    <span className="border_line"></span>
                                    <span>{params.pair}</span>
                                  </div>
                                  <div>
                                    <span>Strategy</span>
                                    <span className="border_line"></span>
                                    <span>Long</span>
                                  </div>
                                  <div>
                                    <span>Investment</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.investmentAmount} USDT
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="bot_preview_title mt-2">
                                  Bot settings
                                </h3>
                                <div className="bot_preview_box">
                                  <div>
                                    <span>Base order</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.firstbuyAmount > 0
                                        ? tradedata.firstbuyAmount
                                        : 0}{" "}
                                      USDT
                                    </span>
                                  </div>
                                  <div>
                                    <span>Base order type</span>
                                    <span className="border_line"></span>
                                    <span>Market</span>
                                  </div>
                                  <div>
                                    <span>Place base order</span>
                                    <span className="border_line"></span>
                                    <span>Immediately</span>
                                  </div>
                                  <div>
                                    <span>Avg. orders</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.AveragingorderAmount > 0
                                        ? tradedata.AveragingorderAmount
                                        : 0}{" "}
                                      USDT
                                    </span>
                                  </div>
                                  <div>
                                    <span>Avg. orders qty</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.margincallLimit > 0
                                        ? tradedata.margincallLimit
                                        : 0}
                                    </span>
                                  </div>
                                  <div>
                                    <span>Avg. orders step</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.margincallLimit > 0
                                        ? tradedata.AvgorderStep
                                        : 0}
                                      %
                                    </span>
                                  </div>
                                  <div>
                                    <span>Amount multiplier</span>
                                    <span className="border_line"></span>
                                    <span>
                                      x
                                      {tradedata.margincallLimit > 0
                                        ? tradedata.AmountMultiplier
                                        : 0}
                                    </span>
                                  </div>
                                  <div>
                                    <span>Step multiplier</span>
                                    <span className="border_line"></span>
                                    <span>
                                      x
                                      {tradedata.margincallLimit > 0
                                        ? tradedata.stepMultiplier
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="bot_preview_title mt-2">
                                  Position TP & SL
                                </h3>
                                <div className="bot_preview_box">
                                  <div>
                                    <span>TP price change</span>
                                    <span className="border_line"></span>
                                    <span>
                                      {tradedata.takeprofitRatio > 0
                                        ? tradedata.takeprofitRatio
                                        : 0}
                                      %
                                    </span>
                                  </div>
                                  <div>
                                    <span>TP percentage of</span>
                                    <span className="border_line"></span>
                                    <span>Average price</span>
                                  </div>
                                  <div>
                                    <span>TP order type</span>
                                    <span className="border_line"></span>
                                    <span>Market</span>
                                  </div>
                                  {tradedata.stopLoss > 0 &&
                                    tradedata.isstoploss && (
                                      <div>
                                        <span>SL price change</span>
                                        <span className="border_line"></span>
                                        <span>{tradedata.stopLoss}%</span>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* </Scrollbars> */}
                    </div>

                    <div className="column_btngrp px-2">
                      {botData.type == "open" && (
                        <button
                          className="primary_btn primary_blue_btn m-0 "
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasTop1"
                          aria-controls="offcanvasTop"
                        >
                          Bot actions
                          <i class="bi bi-caret-down-fill"></i>
                        </button>
                      )}
                      {/* </>
                )} */}
                    </div>

                    {botAct == true && (
                      <div className="bot_actions_card pb-4">
                        <div
                          class="offcanvas offcanvas-top"
                          tabindex="-1"
                          id="offcanvasTop1"
                          aria-labelledby="offcanvasTopLabel"
                          data-bs-scroll="true"
                        >
                          <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasTopLabel">
                              Bot actions
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="offcanvas-body">
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#manual_pos_modal"
                              data-bs-dismiss="offcanvas"
                              onClick={() => handlemanpos()}
                            >
                              <h6>Manual position averaging</h6>
                              <p>
                                Average the price of a position by manually
                                adding funds according to the current market
                                price.
                              </p>
                            </div>

                            {/* <div
                              data-bs-toggle="modal"
                              data-bs-target="#close_position_modal"
                              data-bs-dismiss="offcanvas"
                            >
                              <h6>Close position by market</h6>
                              <p>
                                Immediately close the bot's position at the
                                current market price.
                              </p>
                            </div> */}

                            <div
                              data-bs-dismiss="offcanvas"
                              onClick={() => handlemodifystate(true)}
                            >
                              <h6>Modify Bot</h6>
                              <p>Modify the current bot settings.</p>
                            </div>

                            <div
                              data-bs-dismiss="offcanvas"
                              onClick={() => handlestopbot(true)}
                            >
                              <h6>Stop Bot</h6>
                              <p>Stop the bot's operations completely.</p>
                            </div>

                            {!isEmpty(RunStatus) && RunStatus == "Start" && (
                              <div
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleStatusChange("Pause")}
                              >
                                <h6>Deactivate Bot</h6>
                                <p>Deactive the bot's operations completely.</p>
                              </div>
                            )}

                            {!isEmpty(RunStatus) && RunStatus == "Pause" && (
                              <div
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleStatusChange("Start")}
                              >
                                <h6>Activate Bot</h6>
                                <p>Activate the bot's operations completely.</p>
                              </div>
                            )}
                            <button
                              className="primary_boder_btn"
                              data-bs-dismiss="offcanvas"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        {/*Create GRID Bot*/}
        {params && params?.bot == "grid" && isGridtrade == "off" && (
          <GridBot
            data={params}
            onChange={changeGrid}
            setIsGridTrade={setIsGridTrade}
            fetchTrade={fetchTrade}
          />
        )}
        {IsContinue == "on" &&
          isEmpty(notifybotstatus) &&
          isEmpty(modnotifybotstatus) && (
            <div className="trade_main_settings cardheadbg">
              <div className="card trade_card">
                <div className="card-header">
                  <h2>Preview DCA Bot</h2>
                  <p>
                    <img
                      src={require("../../assets/images/close_icon.png")}
                      alt="Icon"
                      className="img-fluid cursor"
                      onClick={() => handleCloseicon("preview")}
                    />
                  </p>
                </div>

                <div className="card-body card-body-pb">
                  <div className="card_body_padding">
                    <div className="bot_preview_box_wrapper">
                      {/* <Scrollbars
                      style={{ height: 400 }}
                      renderTrackVertical={({ style, ...props }) => (
                        <div
                          className="track_vertical"
                          {...props}
                          style={{
                            ...style,
                            right: "2px",
                            bottom: "2px",
                            top: "2px",
                            borderRadius: "10px",
                            width: "3px",
                          }}
                        />
                      )}
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          className="thumb_vertical"
                          {...props}
                          style={{
                            ...style,
                            width: "3px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                          }}
                        />
                      )}
                    > */}
                      <div>
                        <h3 className="bot_preview_title">General settings</h3>
                        <div className="bot_preview_box">
                          <div>
                            <span>Exchange</span>
                            <span className="border_line"></span>
                            <span>{params.exchange}</span>
                          </div>
                          <div>
                            <span>Pair</span>
                            <span className="border_line"></span>
                            <span>{params.pair}</span>
                          </div>
                          <div>
                            <span>Strategy</span>
                            <span className="border_line"></span>
                            <span>Long</span>
                          </div>
                          <div>
                            <span>Investment</span>
                            <span className="border_line"></span>
                            <span>{tradedata.investmentAmount} USDT</span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3 className="bot_preview_title mt-2">Bot settings</h3>
                        <div className="bot_preview_box">
                          <div>
                            <span>Base order</span>
                            <span className="border_line"></span>
                            <span>
                              {tradedata.firstbuyAmount > 0
                                ? tradedata.firstbuyAmount
                                : ""}{" "}
                              USDT
                            </span>
                          </div>
                          <div>
                            <span>Base order type</span>
                            <span className="border_line"></span>
                            <span>Market</span>
                          </div>
                          <div>
                            <span>Place base order</span>
                            <span className="border_line"></span>
                            <span>Immediately</span>
                          </div>
                          <div>
                            <span>Avg. orders</span>
                            <span className="border_line"></span>
                            <span>
                              {tradedata.AveragingorderAmount > 0
                                ? tradedata.AveragingorderAmount
                                : 0}{" "}
                              USDT
                            </span>
                          </div>
                          <div>
                            <span>Avg. orders qty</span>
                            <span className="border_line"></span>
                            <span>
                              {tradedata.margincallLimit > 0
                                ? tradedata.margincallLimit
                                : 0}
                            </span>
                          </div>
                          <div>
                            <span>Avg. orders step</span>
                            <span className="border_line"></span>
                            <span>
                              {tradedata.margincallLimit > 0
                                ? tradedata.AvgorderStep
                                : 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>Amount multiplier</span>
                            <span className="border_line"></span>
                            <span>
                              x
                              {tradedata.margincallLimit > 0
                                ? tradedata.AmountMultiplier
                                : 0}
                            </span>
                          </div>
                          <div>
                            <span>Step multiplier</span>
                            <span className="border_line"></span>
                            <span>
                              x
                              {tradedata.margincallLimit > 0
                                ? tradedata.stepMultiplier
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3 className="bot_preview_title mt-2">
                          Position TP & SL
                        </h3>
                        <div className="bot_preview_box">
                          <div>
                            <span>TP price change</span>
                            <span className="border_line"></span>
                            <span>
                              {tradedata.takeprofitRatio > 0
                                ? tradedata.takeprofitRatio
                                : 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>TP percentage of</span>
                            <span className="border_line"></span>
                            <span>Average price</span>
                          </div>
                          <div>
                            <span>TP order type</span>
                            <span className="border_line"></span>
                            <span>Market</span>
                          </div>
                          {tradedata.stopLoss > 0 && tradedata.isstoploss && (
                            <div>
                              <span>SL price change</span>
                              <span className="border_line"></span>
                              <span>{tradedata.stopLoss}%</span>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* </Scrollbars> */}
                    </div>
                  </div>
                  {Istrade == "off" && (
                    <div className="bgcolor d-flex align-items-center gap-2">
                      <button
                        className="backtest w-25"
                        onClick={() => handleBack()}
                      >
                        <img
                          src={require("../../assets/images/right_arw.png")}
                          alt="Icon"
                          className="img-fluid cursor"
                        />
                      </button>
                      <button
                        disabled={loader}
                        onClick={() => handleStartBot("start")}
                        className="continue"
                      >
                        {loader && <span>Loading...</span>}
                        {!loader && <span>Start Bot</span>}
                      </button>
                    </div>
                  )}

                  {Istrade != "off" && (
                    <div className="bgcolor d-flex align-items-center gap-2">
                      <button
                        className="backtest w-25"
                        onClick={() => handleBackmodify()}
                      >
                        <img
                          src={require("../../assets/images/right_arw.png")}
                          alt="Icon"
                          className="img-fluid cursor"
                        />
                      </button>
                      <button
                        disabled={loader}
                        onClick={() => handleModifyBot()}
                        className="continue"
                      >
                        {loader && <span>Loading...</span>}
                        {!loader && <span>Confirm</span>}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        {PairList.length > 0 && (
          <TradePairProfit
            params={params}
            pairsAll={PairList}
            updatePair={updatePair}
            balancefetchAll={balancefetchAll}
            TotBal={TotBal}
            TotLocked={TotLocked}
            visible={false}
          />
        )}
      </div>

      {/* Manual position averaging Modal */}
      <div
        className="modal fade primary_modal"
        id="manual_pos_modal"
        // data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="manual_pos_modal_label"
        aria-hidden="true"
        ref={modModalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modify_modal_label">
                Manual position averaging
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-center">
                Average the price of a position by manually adding funds
                according to the current market price.
              </p>
              <div class="createbot_box">
                <div class="inputgrp_box mb-4">
                  <div class="mb-3 w-100 input-group">
                    <label class="grey">Investment, USDT</label>
                    <input
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={handleBSChange}
                      value={
                        buyAmount <= 0
                          ? 0
                          : toFixedDown(buyAmount, ExchangeIn?.priceDecimal)
                      }
                      name="buyAmount"
                      class="form-control"
                      autoComplete="off"
                    />
                    <span class="grey input-group-text" id="basic-addon1">
                      ≈{" "}
                      {parseFloat(buyAmount) <= 0
                        ? 0
                        : parseFloat(buyAmount) * usdprice}{" "}
                      USD{" "}
                    </span>
                  </div>
                  <span class="text-danger"></span>
                </div>
                <div className="slider_box my-3">
                  <Slider
                    value={slvaluemanual}
                    onChange={handleslideremanual}
                    valueLabelDisplay="on"
                    valueLabelFormat={
                      slvaluemanual > 100
                        ? "100%"
                        : !isEmpty(slvaluemanual) && slvaluemanual + "%"
                    }
                    aria-labelledby="range-slider"
                    name="slvaluemanual"
                  />
                </div>
              </div>
              <div class="bot_preview_box">
                <div>
                  <span>Available balance</span>
                  <span class="border_line"></span>
                  <span>
                    {toFixedDown(BSForm.buyAmountIn, ExchangeIn?.priceDecimal)}
                    <span class="greyTxt"> USDT</span>{" "}
                  </span>
                </div>
                <div>
                  <span>Current position size</span>
                  <span class="border_line"></span>
                  <span>
                    {peformanceDetails.orderBc}{" "}
                    <span class="greyTxt">
                      {" "}
                      {splitOne(params.pair, "first")}
                    </span>{" "}
                  </span>
                </div>
                {/* <div>
                  <span>Estimated position size</span>
                  <span class="border_line"></span>
                  <span>
                    ≈ {peformanceDetails.orderBc}9{" "}
                    <span class="greyTxt">
                      {" "}
                      {splitOne(params.pair, "first")}
                    </span>{" "}
                  </span>
                </div> */}
                <div>
                  <span>DCA price</span>
                  <span class="border_line"></span>
                  <span>{peformanceDetails.dcaPrice}</span>
                </div>
                {/* <div>
                  <span>Estimated DCA price</span>
                  <span class="border_line"></span>
                  <span>≈ 0.00000143</span>
                </div> */}
              </div>
              <div class="flex_btns mt-4">
                <button class="primary_boder_btn" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="primary_btn primary_blue_btn mt-0"
                  onClick={handlemanualBuy}
                  disabled={buyLoader}
                >
                  {buyLoader && <div className="loader"></div>}
                  {!buyLoader && "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Close position by market Modal */}
      <div
        className="modal fade primary_modal"
        id="close_position_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="close_position_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modify_modal_label">
                Close position by market
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-center">
                Immediately close the bot’s position at the current market
                price.
              </p>
              <div class="bot_preview_box">
                <div>
                  <span>Position size</span>
                  <span class="border_line"></span>
                  <span>
                    2 942 009 825 <span class="greyTxt">PEPE</span>{" "}
                  </span>
                </div>
                <div>
                  <span>DCA price</span>
                  <span class="border_line"></span>
                  <span>0.00000156</span>
                </div>
                <div>
                  <span>Current price</span>
                  <span class="border_line"></span>
                  <span>0.0000013</span>
                </div>
                <div>
                  <span>PNL</span>
                  <span class="border_line"></span>
                  <span>
                    ≈ <span class="redText">-771.78</span>{" "}
                    <span class="greyTxt">PEPE</span> <span>/</span>{" "}
                    <span class="redText">-17.43%</span>
                  </span>
                </div>
              </div>
              <div class="flex_btns mt-4">
                <button class="primary_boder_btn" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button class="primary_btn primary_blue_btn mt-0">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {botMod && (
        <Modal
          show={botMod}
          onHide={onModHide}
          centered
          size="xl"
          className="modal fade primary_modal primary_modal_extra_large"
        >
          <Modal.Header closeButton>
            <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
              History
            </h1>
          </Modal.Header>
          <Modal.Body>
            <div className="primary_table">
              <DataTable columns={columns} data={botHistory} />
            </div>
            <div>
              <button
                className="continue w-auto mt-3 mx-auto"
                onClick={handleDownload}
              >
                Download CSV
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {params && params?.bot == "dca" && backstbtn && (
        <BacktestModal
          bTModal={bTModal}
          toggleBtModal={toggleBtModal}
          params={params}
          data={btData}
          tradedata={tradedata}
          updateBTest={updateBTest}
          pData={pData}
        />
      )}
    </>
  );
}
