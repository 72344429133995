import React, { Fragment, useEffect, useState, useContext } from "react";
import $ from "jquery";
import "react-tooltip/dist/react-tooltip.css";

// import component
import BotHistory from "./BotHistory";

// import action
import { closeBot } from "../../actions/gridbot";

// import lib
import { toastAlert } from "../../lib/toastAlert";

export default function GridTradeHistory(props) {
  const {
    botType,
    pair,
    exchange,
    markPrice,
    markResult,
    FeeandDecimal,
    updatePair,
    updateParams,
    updateId,
  } = props;

  // state
  const [loader, setLoader] = useState(false);
  const [OrderData, setOrderData] = useState();

  useEffect(() => {
    $(".nav-item #home-tab").addClass("active");
    $(".tab-content div[class=tab-pane]").addClass("active");

    $(".nav-item #profile-tab").removeClass("active");
    $(".tab-content #profile").removeClass("active");
  }, [botType, pair, exchange]);

  const handleCloseBot = async (e, type) => {
    e.preventDefault();
    try {
      let reqData = {
        exchange: exchange,
        orderId: OrderData._id,
        type: type,
      };
      setLoader(true);
      const { status, message } = await closeBot(reqData);
      setLoader(false);

      if (status == "success") {
        toastAlert("success", message, "save");
        window.location.reload();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {}
  };

  return (
    <div className="trade_main_left_bottom">
      <BotHistory
        pair={pair}
        setOrderData={setOrderData}
        markPrice={markPrice}
        markResult={markResult}
        exchange={exchange}
        FeeandDecimal={FeeandDecimal}
        updatePair={updatePair}
        updateParams={updateParams}
        updateId={updateId}
      />

      {/* Close Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="sell_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="sell_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="sell_modal_label">
                Are you sure to close this order?
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleCloseBot(e, "cancel")}
                  disabled={loader}
                >
                  {loader && <div class="loader"></div>}
                  {!loader && "Cancel"}
                </button>
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleCloseBot(e, "market")}
                  disabled={loader}
                >
                  {loader && <div class="loader"></div>}
                  {!loader && "Market"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
