// import lib
import isEmpty from "./isEmpty";

Number.prototype.toFixedNoRounding = function (n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  const a = this.toString().match(reg)[0];
  const dot = a.indexOf(".");
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? a + "0".repeat(b) : a;
};

export const toFixed = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item);
      return item.toFixed(type);
    }
    return "";
  } catch (err) {
    return "";
  }
};
export const toPercentage = (price, percentage = 0, type = "+") => {
  try {
    if (!isEmpty(price) && !isNaN(price) && !isEmpty(type)) {
      price = parseFloat(price);
      let perPrice = (percentage * price) / 100;
      return type == "+" ? price + perPrice : price - perPrice;
    }
    return 0;
  } catch (err) {
    return 0;
  }
};

export const currencyFormat = (item) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = item.toString();
      let splitValue = item.split(".");
      return splitValue[1]
        ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${
            splitValue[1]
          }`
        : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const randomstring = (x) => {
  try {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < charactersLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } catch (err) {
    return "";
  }
};

export const longNumbers = (x, n) => {
  try {
    if (x < 0.000001) {
      x = 0.0;
    } else if (x > 100) {
      x = x.toFixedNoRounding(2);
      return x;
    }
    return x.toFixedNoRounding(n);
  } catch (err) {
    return "";
  }
};
export const toFixedDown = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item);
      item = convert(item);

      // let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
      let decReg = new RegExp("(-?\\d+\\.\\d{" + type + "})(\\d)"),
        m = item.toString().match(decReg);
      m = m ? parseFloat(m[1]) : item.valueOf();
      m = convert(m);
      return parseFloat(m);
    }
    return "";
  } catch (err) {
    return "";
  }
};
export const calPrice = (priceData, type) => {
  try {
    let total = 0,
      totalQty = 0,
      totalPrice = 0;
    if (type == "price") {
      for (let data of priceData) {
        total = total + data.price;
      }
      if (total > 0) {
        return total;
      }
    }
    if (type == "quantity") {
      for (let data of priceData) {
        total = total + data.quantity;
      }
      if (total > 0) {
        return parseFloat(total); // .toFixed(6)
      }
    }
    if (type == "checksell") {
      for (let data of priceData) {
        total = total + data.quantity * data.price;
        totalQty = totalQty + data.quantity;
      }
      if (total > 0) {
        return total / totalQty;
      }
    }

    if (type == "Totalprice") {
      for (let data of priceData) {
        totalPrice = totalPrice + data.orgquantity * data.price;
      }
      return totalPrice;
    }

    if (type == "orgquantity") {
      for (let data of priceData) {
        total = total + data.orgquantity;
      }
      if (total > 0) {
        return parseFloat(total); // .toFixed(6)
      }
    }
    if (type == "feeQty") {
      for (let data of priceData) {
        total = total + data.feeQuantity;
      }
      if (total > 0) {
        return parseFloat(total);
      }
    }

    if (type == "avgorgqty") {
      for (let data of priceData) {
        total = total + data.orgquantity * data.price;
        totalQty = totalQty + data.orgquantity;
      }
      if (total > 0) {
        return total / totalQty;
      }
    }

    return 0;
  } catch (err) {
    console.log(err, "----3333");

    return 0;
  }
};

export function convert(n) {
  try {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  } catch (err) {
    return 0;
  }
}
