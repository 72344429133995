import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Overview from "../components/portfolio/overview.js";
import Positions from "../components/portfolio/position.js";
import Hisotry from "../components/portfolio/history.js";
import OpenPosition from "../components/portfolio/openPosition.js";
import TradeRelate from "../components/Notification/TradeRelated.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Portfolio() {
  const [active, setactive] = useState(1);
  const [value, setValue] = useState();
  const [selectedValue, setSelectedValue] = useState("All exchanges"); // Initialize with a default value
  const queryParams = new URLSearchParams(window.location.search);
  const ctab = queryParams.get("tb");

  const handletab = (tabid) => {
    setactive(tabid);
  };

  useEffect(() => {
    if (ctab == "notification") {
      setactive(6);
    }
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content mt-5 pt-5">
        <div className="container">
          <div className="port_folio">
            <h2 className="head_h2 mb-4">Portfolio</h2>

            <div className="h_flx">
              <p
                className={active == 1 ? "active" : ""}
                onClick={() => handletab(1)}
              >
                Overview
              </p>
              {/* <p className={active == 2 ? "active" : ""} onClick={() => handletab(2)} >Open orders</p> */}
              <p
                className={active == 3 ? "active" : ""}
                onClick={() => handletab(3)}
              >
                Positions
              </p>
              <p
                className={active == 4 ? "active" : ""}
                onClick={() => handletab(4)}
              >
                History
              </p>
              <p
                className={active == 5 ? "active" : ""}
                onClick={() => handletab(5)}
              >
                Open Order
              </p>
              <p
                className={active == 6 ? "active" : ""}
                onClick={() => handletab(6)}
              >
                Notifications
              </p>
            </div>

            {active == 1 && <Overview />}

            {active == 3 && <Positions />}

            {active == 4 && <Hisotry />}
            {active == 5 && <OpenPosition />}
            {active == 6 && <TradeRelate />}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
